import React from 'react'

export const FAQIcon = (
  <svg viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_222_1150)'>
      <path
        d='M50.875 39.3124C50.8743 42.9595 50.052 46.5596 48.4692 49.8453C46.8864 53.1311 44.5838 56.0181 41.7324 58.2919C38.8809 60.5657 35.5538 62.168 31.9982 62.9798C28.4426 63.7916 24.7498 63.7919 21.1941 62.9809L10.4063 68.2187V57.4078C7.38727 54.7075 5.09174 51.2949 3.7287 47.4808C2.36565 43.6666 1.9784 39.5721 2.6022 35.57C3.226 31.5679 4.84104 27.7855 7.30025 24.5671C9.75946 21.3488 12.9847 18.7967 16.6823 17.1433C20.3799 15.49 24.4323 14.7879 28.4706 15.101C32.5088 15.414 36.4046 16.7323 39.8032 18.9358C43.2018 21.1393 45.9952 24.158 47.9289 27.7169C49.8627 31.2759 50.8755 35.2621 50.875 39.3124Z'
        fill='#C1FFDA'
      />
      <path
        d='M47.4067 3.4687C43.5738 3.45396 39.7837 4.27373 36.2995 5.87107C32.8153 7.46842 29.7205 9.80505 27.2302 12.7187C3.24951 12.0828 -9.24955 41.6249 8.0942 58.4137V68.2187C8.09437 68.6101 8.1939 68.9951 8.38346 69.3376C8.57302 69.68 8.84641 69.9688 9.17802 70.1767C9.50962 70.3847 9.8886 70.5051 10.2794 70.5267C10.6703 70.5482 11.0602 70.4702 11.4126 70.2999L21.4836 65.409C26.0943 66.3251 30.8658 65.9971 35.3079 64.4589C39.75 62.9206 43.7024 60.2275 46.7592 56.6562C48.698 56.7152 50.6372 56.5562 52.5405 56.1821C63.3861 61.443 62.8542 61.2812 63.5942 61.2812C64.2076 61.2812 64.7957 61.0375 65.2294 60.6039C65.6631 60.1702 65.9067 59.582 65.9067 58.9687V49.1637C83.0308 32.6062 71.1677 3.4687 47.4067 3.4687ZM26.5942 61.2812C18.3039 61.2812 24.9986 58.564 12.7192 64.5302C12.7192 57.3037 12.9967 56.6215 11.9445 55.6849C8.11767 52.2611 5.61105 47.605 4.86009 42.5253C4.10912 37.4456 5.16126 32.2634 7.83374 27.8787C10.5062 23.4941 14.6302 20.1841 19.4892 18.5238C24.3483 16.8635 29.6355 16.9577 34.4323 18.7901C39.2291 20.6225 43.2325 24.0773 45.747 28.5544C48.2616 33.0315 49.1284 38.2479 48.1969 43.2976C47.2654 48.3473 44.5945 52.9112 40.6481 56.1965C36.7017 59.4817 31.7291 61.2808 26.5942 61.2812ZM62.0564 46.4349C61.0158 47.3599 61.2817 47.7415 61.2817 55.2802C51.1299 50.3431 54.3442 51.349 50.043 51.8115C51.9311 48.2388 52.9825 44.2837 53.1176 40.2451C53.2528 36.2065 52.4683 32.1899 50.8233 28.499C49.1783 24.8081 46.7159 21.5393 43.6222 18.9398C40.5284 16.3402 36.8843 14.4778 32.9652 13.4934C46.9211 1.27183 69.3755 11.0653 69.3755 30.0624C69.3757 33.1527 68.7239 36.2083 67.4627 39.0295C66.2015 41.8508 64.3593 44.3742 62.0564 46.4349Z'
        fill='black'
      />
      <path
        d='M26.5938 56.6562C27.8709 56.6562 28.9062 55.6209 28.9062 54.3438C28.9062 53.0666 27.8709 52.0312 26.5938 52.0312C25.3166 52.0312 24.2812 53.0666 24.2812 54.3438C24.2812 55.6209 25.3166 56.6562 26.5938 56.6562Z'
        fill='black'
      />
      <path
        d='M25.6121 21.9687C23.8102 21.9367 22.0308 22.3733 20.4483 23.2357C18.8658 24.0981 17.5343 25.3567 16.5844 26.8883C15.6345 28.4198 15.0986 30.1719 15.0292 31.9728C14.9598 33.7737 15.3594 35.5617 16.1887 37.1618C16.3145 37.4535 16.4992 37.7161 16.731 37.9333C16.9629 38.1505 17.237 38.3176 17.5363 38.4242C17.8356 38.5308 18.1536 38.5745 18.4706 38.5527C18.7875 38.5309 19.0966 38.4441 19.3785 38.2975C19.6604 38.151 19.909 37.948 20.109 37.7011C20.309 37.4542 20.4559 37.1688 20.5407 36.8626C20.6254 36.5564 20.6462 36.2361 20.6016 35.9215C20.5571 35.6069 20.4482 35.3049 20.2818 35.0343C19.82 34.1434 19.5979 33.1476 19.6376 32.1449C19.6773 31.1422 19.9773 30.1671 20.5081 29.3155C21.0389 28.4639 21.7822 27.7651 22.665 27.2879C23.5477 26.8106 24.5395 26.5714 25.5427 26.5937C31.0118 26.6862 33.4631 34.0399 28.6068 37.2081C27.3027 38.0282 26.2241 39.1611 25.469 40.5039C24.7138 41.8466 24.3059 43.3567 24.2824 44.8971C24.2824 45.5104 24.5261 46.0986 24.9597 46.5323C25.3934 46.966 25.9816 47.2096 26.5949 47.2096C27.2082 47.2096 27.7964 46.966 28.2301 46.5323C28.6638 46.0986 28.9074 45.5104 28.9074 44.8971C28.9313 44.1254 29.1481 43.3719 29.5379 42.7055C29.9277 42.039 30.4781 41.4807 31.139 41.0815C39.8109 35.4159 35.6137 22.1421 25.6121 21.9687Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_222_1150'>
        <rect width='74' height='74' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
