export const mockReferralNumber = 'sssr96756500'

export const mockLocations = [
  {
    id: 1,
    name: 'Williamsburg Community Center',
    address: '123 Washington St NY, New York 11125',
    distance: 1.17,
    phoneNumber: '(555) 555-1245 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
  {
    id: 2,
    name: 'ASAP at LaGuardia',
    address: '123 Washington St NY, New York 11125',
    distance: 1.36,
    phoneNumber: '(555) 555-2356 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
  {
    id: 3,
    name: 'Brooklyn Legal Services',
    address: '123 Washington St NY, New York 11125',
    distance: 2.0,
    phoneNumber: '(555) 555-1245 Ext 4587',
    languages: 'English, Espanol, Arabic',
  },
]
