import React from 'react'
import {
  StyledCategoryFilterItem,
  StyledCategoryFilterItemLabel,
} from 'Components/LocalResources/Filtering/CategoryFilterItem/style'
import Icon from 'Components/Shared/Icon/Icon'
import categoryIconPathsMap from 'Shared/Icons/CategoryIcons'
import { withTheme } from 'styled-components'

const Category = (props) => {
  const { className, id, name, isIconOnly } = props
  const iconPath = categoryIconPathsMap[id]

  return (
    <StyledCategoryFilterItem margin={'10px 0'} width={props.width}>
      <Icon
        key={id}
        className={className}
        id={id}
        radius={'7px'}
        scale={'3.2rem'}
        padding={'4px'}
        backgroundColor={'#fff'}
        border={'0'}
        margin={'0 10px 0 0'}
        boxShadow={'0px 3px 6px #1D283117'}
        fill={'#000'}
        screenReaderLabel={name}
      >
        {iconPath}
      </Icon>
      {!isIconOnly && (
        //ToDo: FInd Help API: previously we split name on whitespace ans showed index of 0
        <StyledCategoryFilterItemLabel htmlFor={name}>
          {name}
        </StyledCategoryFilterItemLabel>
      )}
    </StyledCategoryFilterItem>
  )
}

export default withTheme(Category)
