import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Table } from '@material-ui/core'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { ITableDisplay } from '../..'

import EmptyDataBox from './EmptyDataBox'
import ReviewSectionTableBody from './ReviewSectionTableBody'
import ReviewSectionTableHead from './ReviewSectionTableHead'

const ReviewSectionTable = ({
  labels,
  rows,
}: Pick<ITableDisplay, 'labels' | 'rows'>) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <>
      {isMobile && (
        <div>
          {rows.length <= 0 ? (
            <EmptyDataBox />
          ) : (
            <ReviewSectionTableBody labels={labels} rows={rows} />
          )}
        </div>
      )}
      {!isMobile && (
        <>
          {rows.length <= 0 ? (
            <EmptyDataBox />
          ) : (
            <Table>
              <ReviewSectionTableHead labels={labels} />
              <ReviewSectionTableBody labels={labels} rows={rows} />
            </Table>
          )}
        </>
      )}
    </>
  )
}

export default ReviewSectionTable
