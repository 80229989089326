import React from 'react'
import { connect } from 'react-redux'
import FieldArrayContainer from 'Components/Shared/ReduxForm/FieldArrayContainer'
import { screenerSelector } from 'Selectors'
import { RootState } from 'Store'
import { v4 as uuidv4 } from 'uuid'

import FirstAndLastNameRenderField from './FirstAndLastNameRenderField'
import { useFields } from './hooks'

interface FirstAndLastNameProps {
  name: string
}
const FirstAndLastName = (props: FirstAndLastNameProps) => {
  useFields(props, {
    uuid: uuidv4(),
  })

  return (
    <FieldArrayContainer
      {...props}
      fieldArrayComponent={FirstAndLastNameRenderField}
    />
  )
}

const stateToProps = (state: RootState, ownProps: FirstAndLastNameProps) => {
  return {
    backButtonClicked: state.screener.returnStep,
    fieldValues: screenerSelector(state, ownProps.name),
  }
}

export default connect(stateToProps)(FirstAndLastName)
