import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { compose } from '@reduxjs/toolkit'
import { getBenefit } from 'Actions/eligibility'
import ScreeningPairSvg from 'Components/PreScreener/screeningPair.svg'
import { StyledScreeningPairSvg } from 'Components/Screener/style'
import {
  StyledBookmarkSpan,
  StyledButtonFilled,
  StyledHeadingMain,
  StyledIconWrapper,
  StyledParagraph,
} from 'Pages/Results/style'

import NextStepsDocumentationList from './NextStepsDocumentationList'

const NextStepsApplyOnline = ({ nextSteps, getBenefit }) => {
  const { params: id } = useParams
  const { name, link } = nextSteps

  useEffect(() => {
    if (!name || !link) {
      getBenefit(id)
    }
  }, [])

  return (
    <>
      <StyledHeadingMain id='mainContent' tabIndex='-1'>
        let's prepare to apply online
      </StyledHeadingMain>

      <StyledIconWrapper>
        <StyledScreeningPairSvg alt='' src={ScreeningPairSvg} />
      </StyledIconWrapper>

      <StyledParagraph>
        We understand applying for benefits can be a difficult process.
      </StyledParagraph>

      <StyledParagraph>
        Before your first visit here is a checklist of common documents you will
        need.
      </StyledParagraph>

      <StyledParagraph>
        For best assistance, all household members should provide documentation.
      </StyledParagraph>

      <StyledParagraph>
        Be sure to check with your Single Stop Preferred Partner for their
        specific documentation requirements.
      </StyledParagraph>

      <NextStepsDocumentationList />

      <StyledParagraph>Are you missing some documentation?</StyledParagraph>

      <StyledParagraph>
        No worries, expand any document requirement above to learn how to obtain
        it.
      </StyledParagraph>

      <StyledHeadingMain>We're always here for you</StyledHeadingMain>

      <StyledParagraph>
        Remember to{' '}
        <StyledBookmarkSpan>
          bookmark your Single Stop Dashboard
        </StyledBookmarkSpan>
        . It's your one stop for resources and help.
      </StyledParagraph>

      <StyledParagraph>
        Be sure to check back regularly for updates as we continue to regularly
        add new features and resources.
      </StyledParagraph>

      <StyledHeadingMain>let's send you over</StyledHeadingMain>

      <StyledParagraph>
        By applying online you are leaving the Single Stop App and will be
        directed to the {name} Website.
      </StyledParagraph>

      <StyledButtonFilled
        variant='outlined'
        href={link}
        target='_blank'
        rel={'noopener noreferrer'}
        style={{ marginBottom: '2.5rem' }}
      >
        apply online
      </StyledButtonFilled>
    </>
  )
}

const mapStateToProps = (state) => {
  const { nextSteps } = state

  return {
    nextSteps,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getBenefit: (id) => dispatch(getBenefit(id)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NextStepsApplyOnline
)
