import React from 'react'
import { renderList } from 'Shared/helpers'

import { StyledWarningLabel } from './style'

interface ModalErrorProps {
  message: string | string[]
  type?: 'error' | 'warning'
}
const ModalError = ({ message }: ModalErrorProps) => {
  const messageContent = Array.isArray(message)
    ? renderList(message, 'ul', 'li')
    : message
  return <StyledWarningLabel>{messageContent}</StyledWarningLabel>
}

export default ModalError
