import React from 'react'
import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const PillBase = styled.div`
  border-radius: 0.25rem;
  padding: 0.875rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 0;
  font-weight: 500;
  height: 20px;
  color: ${theme.palette.text.darken};
`

const DefaultPill = styled(PillBase)`
  background-color: ${theme.palette.primary.lighten20};
`

const WarningPill = styled(PillBase)`
  background-color: #ffe9b1;
  > span {
    font-family: 'Roboto', sans-serif;
  }
`

type PillProps = {
  warning?: boolean
  children: React.ReactNode
}

const Pill = ({ warning, children }: PillProps) => {
  return warning ? (
    <WarningPill>{children}</WarningPill>
  ) : (
    <DefaultPill>{children}</DefaultPill>
  )
}

export default Pill
