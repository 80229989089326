import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
`
export const dFlex = css`
  display: flex;
`
export const textPrimary = css`
  color: ${theme.palette.primary.dark};
`
