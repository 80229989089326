import React from 'react'
import { StyledOutcomeButton } from 'Components/Outcome/style'

export const OutcomeButton = ({
  type,
  label,
  onClick,
  navButton,
}: {
  type: string
  label: string | React.ReactNode
  onClick: () => void
}) => (
  <StyledOutcomeButton
    $navButton={navButton}
    type='button'
    $primary={type === 'primary'}
    $secondary={type === 'secondary'}
    $tertiary={type === 'tertiary'}
    $back={type === 'back'}
    onClick={onClick}
  >
    {label}
  </StyledOutcomeButton>
)

export const BackButton = ({ onClick }: { onClick: () => void }) => (
  <OutcomeButton
    navButton={true}
    key='back'
    type='back'
    label={'Back'}
    onClick={onClick}
  />
)
