import React from 'react'
import useBranding from 'Components/LandingPage/Hooks/useBranding'
import Logo from 'Components/Shared/Logo/Logo'
import SocialsBar from 'Components/Shared/PageFooter/Components/SocialsBar'
import { StyledBrandingLogo } from 'Components/Shared/TabletAndDesktopHeader/style'

import { StyledParagraph, StyledWrapper } from '../Styles/FooterMission'

const FooterMission = () => {
  const { footer: brandingFooter, logo: brandingLogoUrl } = useBranding()

  return (
    <>
      <StyledWrapper>
        <Logo />
        <StyledParagraph>
          Our mission is to build pathways out of poverty by leveraging
          partnerships and technology to connect people to existing resources,
          all through a unique one-stop shop.
        </StyledParagraph>
        <SocialsBar />
      </StyledWrapper>
      <StyledWrapper>
        {brandingLogoUrl && (
          <StyledBrandingLogo footer={true} src={brandingLogoUrl} />
        )}
        {brandingFooter && <StyledParagraph>{brandingFooter}</StyledParagraph>}
      </StyledWrapper>
    </>
  )
}

export default FooterMission
