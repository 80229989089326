import React from 'react'
import { BrandStyle } from 'Components/Shared/Header/style'
import { HeaderContainer, HeaderFlex } from 'Components/Shared/PageHeader/style'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import Surface from 'Components/Shared/Surface/Surface'
import { StyledBrandingLogo } from 'Components/Shared/TabletAndDesktopHeader/style'
import { StyledNoPrint } from 'Pages/NextSteps/style'

const SimpleHeaderWithoutNav = ({ logo }: { logo?: string | undefined }) => {
  return (
    <Surface
      elevation={5}
      backgroundColor
      display={'flex'}
      boxShadow={'0px 2px 5px #0000000A'}
      padding={'0 2rem'}
    >
      <HeaderContainer>
        <HeaderFlex>
          <BrandStyle>
            <SingleStopLogo />
          </BrandStyle>
        </HeaderFlex>
        <StyledNoPrint>
          <HeaderFlex>
            {logo && (
              <StyledBrandingLogo data-testid='partner-logo' src={logo} />
            )}
          </HeaderFlex>
        </StyledNoPrint>
      </HeaderContainer>
    </Surface>
  )
}

export default SimpleHeaderWithoutNav
