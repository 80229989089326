import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ME } from 'Components/Auth/Hooks/gql'
import useMenuItemsData from 'Components/Shared/Hooks/useMenuItemsData'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import { noop } from 'Shared/constants'

import { StyledListItem } from './style'

const ClientAuthHeader = ({ client }) => {
  const { accountData, logoutData } = useMenuItemsData()

  const { data: userHasInfo, loading } = useQuery(ME, {
    fetchPolicy: 'network-only',
  })
  if (loading) {
    return null
  }

  const items = client?.authenticated || userHasInfo ? logoutData : accountData

  return (
    <>
      {items.map(({ onClick, path, primary, text }, index) => (
        <StyledListItem key={index}>
          <Button
            as={Link}
            key={index}
            margin='0 0 0 0.625rem'
            onClick={() => (onClick ? onClick() : noop())}
            primary={primary}
            to={path}
            style={loading ? { visibility: 'hidden' } : {}}
          >
            {text}
          </Button>
        </StyledListItem>
      ))}
    </>
  )
}

const mapStateToProps = (state) => {
  const { client } = state
  return { client }
}

export default connect(mapStateToProps)(ClientAuthHeader)
