import styled from 'styled-components'

export const StyledWarningContainer = styled.div<{
  background: string
}>`
  color: white;
  margin-top: 0.3125rem;
  background: ${(props) => props.background};
  font-size: 0.875rem;
  padding: 0.7rem 1.5rem;
  display: flex;
  align-items: center;
`

export const StyledWarningLabel = styled.div`
  position: relative;
  margin: 0.3125rem 0;
  color: #e41a0c;
  font-size: 0.75rem;
  font-weight: 400;
`
