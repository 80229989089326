/**
 *
 * @param {Array} fields - Array of custom field objects
 * @returns {Array} - Array of custom field objects with a computed `name` property (for HTML form element). __typename is removed
 */
export const mapCustomFields = (fields) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fields.map(({ __typename, ...rest }) => ({
    ...rest,
    name: `custom_field_${rest.id}`,
  }))

/**
 *
 * @param {Array} responses - Array of custom field response objects
 * @returns {Object} - An Object of name to value pairs. Used to populate final-form initial values
 */
export const reduceResponses = (responses) =>
  responses.reduce(
    (acc, curr) => ({
      ...acc,
      [`custom_field_${curr.customField.id}`]: curr.value,
    }),
    {}
  )

/**
 *
 * @param {Object} formData - the form data parameter passed by Final Form to the onSubmit handler
 * @returns {{customFieldId: *, responseValue: *}[]} an array of CustomFieldResponseInput objects to pass to the mutation
 */
export const mapResponses = (formData) => {
  return Object.keys(formData).map((key) => ({
    customFieldId: key.split('custom_field_')[1],
    responseValue: formData[key],
  }))
}
