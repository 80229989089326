import React from 'react'
import MediaQuery from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { useClientPortalSections } from 'Components/ClientPortal/hooks'
import ClientPortalSection from 'Components/ClientPortal/MyProfile/ClientPortalSection'
import ClientPortalTemplate from 'Components/ClientPortal/Shared/ClientPortalPageTemplate'
import useInitializeForms from 'Components/Shared/Hooks/useInitializeForms'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import useClientPortalSectionRefetch from 'Pages/ClientPortal/hooks/useClientPortalSectionRefetch'
import {
  CustomDesktopAndTabletMediaQuery,
  MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY,
} from 'Shared/constants'

const MyProfile = () => {
  const { sections, refetch, legacy, loading, error } =
    useClientPortalSections()
  useInitializeForms()
  useClientPortalSectionRefetch(refetch)
  const location = useLocation()
  const pathParts = location.pathname.split('/')
  const pathId = pathParts[pathParts.length - 1]
  if (loading) {
    return <LoadingIndicator show={true} />
  }
  if (!loading && !error) {
    return (
      <ClientPortalTemplate>
        <>
          <MediaQuery query={CustomDesktopAndTabletMediaQuery}>
            {sections.map((section, i) => (
              <ClientPortalSection
                key={i}
                index={i}
                legacy={legacy}
                {...section}
              />
            ))}
          </MediaQuery>
          <MediaQuery query={MOBILE_LANDSCAPE_AND_PORTRAIT_MEDIA_QUERY}>
            {sections.map((section, i) => {
              const isEdit = location.pathname.includes('/account/profile/edit')
              const isVisible = i === parseInt(pathId) // Determine if the section should be visible

              return (
                <div
                  style={{ display: isVisible || !isEdit ? 'block' : 'none' }}
                  key={i}
                >
                  <ClientPortalSection index={i} legacy={legacy} {...section} />
                </div>
              )
            })}
          </MediaQuery>
        </>
      </ClientPortalTemplate>
    )
  }
}

export default MyProfile
