import { NavLink } from 'react-router-dom'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

const styledMenuItemCss = css`
  padding: 1.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => (props.color ? props.color : '#000')};
  ${({ withoutstyle }) =>
    withoutstyle &&
    css`
      font-size: inherit;
      display: inherit;
      align-items: inherit;
      text-decoration: inherit;
      color: inherit;
    `}
`

const styledMenuItemLabel = css`
  padding: 0.5rem 0;
  width: 100%;
  color: ${(props) => props.color};
  ${({ withOUtStyle }) =>
    withOUtStyle &&
    css`
      border-bottom: none;
      ${(props) =>
        props.activelink === 'active'
          ? `background: ${theme.palette.primary.lighten5}; color: ${theme.palette.color.white}`
          : ''};
      margin: 0;
      padding: 0;
      width: 100%;
    `};
`

export const StyledMenuItem = styled(NavLink)`
  ${styledMenuItemCss};
  @media only screen and (max-width: 1024px) {
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => props.fontWeight};
  }
`

export const StyledMenuItemExternalLink = styled.a`
  ${styledMenuItemCss};
`

export const StyledMenuItemExternalLinkLabel = styled.div`
  ${styledMenuItemLabel}
`

export const StyledMenuItemLabel = styled.div`
  ${styledMenuItemLabel};
  ${(props) =>
    props.activelink === 'active'
      ? `color: ${theme.palette.primary.dark}`
      : ''};
`

const navLinkStyling = css`
  display: flex;
  flex-direction: column;
  background: inherit;
  text-decoration: none;
  padding: 1em;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  color: ${(props) => (props.color ? props.color : theme.palette.text.default)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '0.9rem')};
`

const navLinkHoverStyling = css`
  &:hover {
    color: ${theme.palette.primary.dark};
    cursor: pointer;
  }
`

export const StyledDesktopAndTabletTopNavMenuItem = styled(NavLink)`
  ${navLinkStyling};
  ${(props) =>
    props.activelink === 'active'
      ? `color: ${theme.palette.primary.dark};`
      : ''};
  ${navLinkHoverStyling};
`

export const StyledDesktopAndTabletExternalLink = styled.a`
  ${navLinkStyling}
  ${navLinkHoverStyling}
`

export const StyledDesktopAndTabletTopNavMenuItemButton = styled.button`
  background: inherit;
  border-color: rgb(155, 195, 103);
  border: none;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || 0};
  ${(props) =>
    props.activelink === 'active' ? `border-bottom: 2px solid #2A2A2A;` : ''};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`

export const StyledTopNavMenuItemButton = styled.button`
  background: inherit;
  border-color: rgb(155, 195, 103);
  border: none;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.padding || 0};
  ${(props) =>
    props.activelink === 'active' ? `border-bottom: 2px solid #2A2A2A;` : ''};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  @media ${MOBILE_MEDIA_QUERY} {
    flex-direction: row;
    align-items: center;
    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
`

export const StyledDesktopAndTabletTopNavMenuItemLabel = styled.div`
  color: ${(props) => (props.color ? props.color : 'rgba(0, 0, 0, 0.65)')};
  margin: 0rem 1.6rem;
  padding: 0 0 1.6rem;
  &:hover {
    cursor: pointer;
  }
`

export const StyledTopNavMenuItemLabel = styled.div`
  color: rgba(0, 0, 0, 0.65);
  margin: 0rem 1.6rem;
  padding: 0 0 1.6rem;
  &:hover {
    cursor: pointer;
  }
  @media ${MOBILE_MEDIA_QUERY} {
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-left: 0.3rem;
  }
`

export const StyledNextStepsButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  top: 0.2rem;
`

StyledMenuItem.displayName = 'StyledMenuItem'
StyledMenuItemLabel.displayName = 'StyledMenuItemLabel'
StyledDesktopAndTabletTopNavMenuItem.displayName =
  'StyledDesktopAndTabletTopNavMenuItem'
StyledDesktopAndTabletTopNavMenuItemLabel.displayName =
  'StyledDesktopAndTabletTopNavMenuItemLabel'
