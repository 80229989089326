import React from 'react'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'

import BenefitGuides from './NextStepsBenefitsGuides'

const DocumentsChecklist = () => {
  return (
    <HeaderWithContainer>
      <BenefitGuides />
    </HeaderWithContainer>
  )
}

export default DocumentsChecklist
