import { useLocation } from 'react-router-dom'

const useActiveLink = () => {
  const location = useLocation()
  const isActiveLink = ({ path }: { path: string }) =>
    location.pathname.includes(path)
  return {
    isActiveLink,
  }
}

export default useActiveLink
