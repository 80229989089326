import styled from 'styled-components'

export const StyledBenefitGuides = styled.div`
  display: table-row;
  margin-bottom: 1rem;
  margin-top: 2rem;

  h3 {
    padding: 0 0 0 2rem;
    text-transform: capitalize;
    display: table-cell;
    font-weight: 500;
  }

  ul {
    list-style-type: none;
    display: table-cell;
  }

  li {
    margin-bottom: 1rem;
    color: #0d8762;
  }

  a {
    color: #0d8762;
  }

  .table {
    display: table;
  }

  .icon {
    display: table-cell;
    position: relative;
    top: 2rem;
  }
`
export const StyledGuides = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;
  background-color: white;
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 8px;
  @media (max-width: 480px) {
    flex-direction: column;
    padding: 12px;
    margin-bottom: 3rem;
  }
  h3 {
    text-transform: capitalize;
    display: table-cell;
    font-weight: 600;
    font-size: 18px;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  ul {
    list-style-type: none;
    display: table-cell;
    width: 100%;
  }

  li {
    font-weight: 400;
    margin-bottom: 1rem;
    color: hsla(0, 0%, 8%, 1);
    border: 1px solid hsla(0, 0%, 94%, 1);
    padding: 12px 12px 12px 16px;
    border-radius: 12px;
    @media (max-width: 480px) {
      padding: 8px 8px 8px 12px;
      font-size: 14px;
    }
  }

  p {
    color: hsla(0, 0%, 8%, 1);
  }

  .table {
    display: table;
  }

  .icon {
    display: table-cell;
    position: relative;
    top: 2rem;
  }
`

export const StyledLink = styled.a`
  text-decoration: none;
`
export const Buttonwrraper = styled.div`
  display: flex;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: flex-end;
  }
  button:nth-child(1) {
    background-color: transparent;
    border: none;
    color: hsla(0, 0%, 8%, 1);
  }
  button:nth-child(2) {
    background-color: transparent;
    border: 1px solid hsla(0, 0%, 94%, 1);
    border-radius: 56px;
    padding: 8px 16px;
  }
  a {
    color: hsla(0, 0%, 8%, 1);
    font-weight: 400;
  }
`
export const ListItemwrraper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: start;
  }

  h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Noto Sans HK';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    @media (max-width: 480px) {
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 12px;
    }
  }
`
export const Liststyle = styled.div`
  display: flex;
  gap: 16px;
`
export const ButtonWrapperBackground = styled.div`
  display: none;
  position: unset;
  @media (max-width: 480px) {
    display: block;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    height: 90px;
    overflow-x: hidden;
    border-top: solid hsla(0, 0%, 94%, 1);
    z-index: 1000;
  }
`
StyledBenefitGuides.displayName = 'StyledBenefitGuides'
