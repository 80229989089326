import {
  CLEAR_GEOLOCATION,
  GEOLOCATION_ERROR,
  GEOLOCATION_STATE,
  UPDATE_COORDINATES,
  UPDATE_RESOURCES_ZIPCODE,
  UPDATE_ZIPCODE,
} from 'Actions/geolocation'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { geolocation } from './initialState'

export default (state = geolocation, action) => {
  switch (action.type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.geolocation,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_COORDINATES: {
      const { coordinates } = action
      return { ...state, coordinates }
    }

    case UPDATE_ZIPCODE: {
      const { zipCode } = action
      return { ...state, zipCode, geolocationError: false }
    }

    case UPDATE_RESOURCES_ZIPCODE: {
      const { resourcesZipCode } = action
      return { ...state, resourcesZipCode, geolocationError: false }
    }

    case GEOLOCATION_ERROR:
      return { ...state, zipCode: null, geolocationError: true }

    case GEOLOCATION_STATE:
      const { geolocationState } = action
      return { ...state, geolocationState }

    case CLEAR_GEOLOCATION:
      return geolocation

    default:
      return state
  }
}
