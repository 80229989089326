import React from 'react'
import { StyledTableCell } from 'Components/Review/ReviewSection/style'

const ReviewSectionTableCell = ({ column }: { column: string | string[] }) => {
  return (
    <StyledTableCell>
      {Array.isArray(column)
        ? column?.map((item, i) => {
            return (
              <div style={{ margin: '0.625rem 0px 1.25rem' }} key={i}>
                {item}
              </div>
            )
          })
        : column}
    </StyledTableCell>
  )
}
export default ReviewSectionTableCell
