import { MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from 'Shared/constants'
import styled from 'styled-components'

interface StyledMenuToggleProps {
  isOpen: boolean
}
export const StyledCloseButton = styled.button<StyledMenuToggleProps>`
  color: ${(props) => props.theme.text.default};
  font-weight: 500;
  display: flex;
  align-items: center;
  background: inherit;
  border: inherit;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
  }
  @media ${MOBILE_MEDIA_QUERY} {
    justify-content: flex-start;
  }
  @media ${TABLET_MEDIA_QUERY} {
    justify-content: center;
  }
`

export const StyledMobileMenuButton = styled.button`
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
`

StyledCloseButton.displayName = 'StyledCloseButton'
