import styled, { css } from 'styled-components'

import { StyledHeader } from '../Header/style'

export const StyledTabletAndDesktopHeader = styled(StyledHeader)`
  position: relative;
  flex: 0 0;
  flex-direction: row;
  padding: 0 2.4rem;
  justify-content: space-between;
`
export const StyledTabletAndDesktopActionBar = styled.div`
  background-color: ${(props) => props.theme.background.secondary};
  padding: 1.89rem 5rem;
`

export const StyledSkipToMainContentLink = styled.a`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  opacity: 0;
  text-decoration: none;
  font-size: 0.1rem;
  background: black;
  color: white;
  width: 0.1rem;
  height: 0.1rem;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    opacity: 1;
    width: auto;
    height: auto;
    font-size: 1.4rem;
    padding: 1.5rem;
  }
`

export const StyledBrandingLogo = styled.img`
  max-height: 2.75rem;
  ${({ footer }) =>
    footer &&
    css`
      max-width: 100%;
    `}
`

StyledBrandingLogo.displayName = 'StyledBrandingLogo'
StyledTabletAndDesktopHeader.displayName = 'StyledTabletAndDesktopHeader'
StyledSkipToMainContentLink.displayName = 'StyledSkipToMainContentLink'
