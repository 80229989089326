import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormError from 'Components/Shared/FormError/FormError'
import {
  StyledCheckBoxWrapper,
  StyledCheckBoxWrapperListItem,
  StyledCircle,
  StyledFormControlLabel,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'

import { StyledFormControl, StyledLabel } from '../ListInput/style'

export type MultiSelectInputProps = {
  input: {
    onChange: (value: string) => void
    value: string
  }
  meta: {
    touched: boolean
    error: string
    submitError: string
  }
  name: string
  options: string[]
  label: string
}

const MultiSelectInput = ({
  input: { onChange, value },
  meta: { touched, error, submitError },
  name,
  options,
  label,
}: MultiSelectInputProps) => {
  const handleCheckboxChange = (option: string) => {
    const currentValueArray = value ? value.split(', ') : []
    const newValueArray = currentValueArray.includes(option)
      ? currentValueArray.filter((item) => item !== option)
      : [...currentValueArray, option]
    onChange(newValueArray.join(', '))
  }

  return (
    <StyledFormControl
      variant='standard'
      fullWidth
      error={touched && (!!error || !!submitError)}
    >
      <StyledLabel>{label}</StyledLabel>
      {options.map((option, index) => {
        const isChecked = value.split(', ').includes(option)
        return (
          <StyledCheckBoxWrapperListItem key={index}>
            <StyledCheckBoxWrapper>
              <StyledCircle>{option.charAt(0)}</StyledCircle>
              <StyledFormControlLabel
                label={option}
                data-testid={name}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={() => handleCheckboxChange(option)}
                    color='primary'
                    inputProps={{
                      'aria-label': `Checkbox ${option}`,
                    }}
                    id={`checkbox-${name}-${option}`}
                  />
                }
                labelPlacement='start'
                htmlFor={`checkbox-${name}-${option}`}
              />
            </StyledCheckBoxWrapper>
          </StyledCheckBoxWrapperListItem>
        )
      })}
      <FormError touched={touched} error={error ?? submitError} />
    </StyledFormControl>
  )
}

export default MultiSelectInput
