import { primaryButtonProps } from 'Components/Shared/Uielements/styles/buttons.style'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  font-size: 10px;
  color: #29283e;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  max-width: 1140px;

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 990px) {
    max-width: 720px;
    font-size: 8.3px;
  }

  @media (max-width: 767px) {
    font-size: 7.5px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-direction: column;
    align-items: center;
  }
`

export const StyledSvg = styled.img`
  width: 47.8em;

  @media (max-width: 1200px) {
    width: 40.3em;
  }

  @media (max-width: 990px) {
    max-width: 31.9em;
  }
`

export const StyledHeadingMain = styled.h1`
  font-size: 4.5em;
`

export const StyledInfoContainer = styled.div`
  flex: 0 0 58%;
  @media (max-width: 767px) {
    order: 2;
    text-align: center;
  }
`

export const StyledHeadingSecondary = styled.h2`
  font-size: 3em;
  font-weight: 300;
`

export const StyledParagraph = styled.p`
  font-size: 1.8em;
`

export const StyledButtonsContainer = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

export const StyledButton = styled.button`
  background: transparent;
  color: ${theme.palette.text.default};
  min-width: 64px;
  text-align: center;
  text-transform: capitalize;
  padding: 0.7em 1.8em 0.8em;
  border: none;
  border-radius: 7px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  margin: 0 1em 0 0;
  width: auto;
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 210px;
  padding: 0.9em 1.4em 1em;
  font-size: 1.8em;
  ${({ primary }) => primary && primaryButtonProps};
  ${({ secondary }) =>
    secondary &&
    css`
      background: ${theme.palette.secondary.default};
      color: ${theme.palette.text.default};
      &:hover {
        background: ${theme.palette.secondary.darken10};
        color: ${theme.palette.text.default};
      }
    `};

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 1em;
  }
`
