import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import { validate } from 'Shared/helpers'
import { ssTheme } from 'Shared/Theme/ssTheme'

import BooleanInput from './Inputs/BooleanInput/BooleanInput'
import {
  INPUT_TYPE_BOOLEAN,
  INPUT_TYPE_DATE,
  INPUT_TYPE_LIST,
  INPUT_TYPE_MULTI_SELECT,
  INPUT_TYPE_STRING,
} from './Inputs/constants'
import DateInput from './Inputs/DateInput/DateInput'
import ListInput from './Inputs/ListInput/ListInput'
import { StyledLabel } from './Inputs/ListInput/style'
import MultiSelectInput from './Inputs/MultiSelectInput/MultiSelectInput'
import StringInput from './Inputs/StringInput/StringInput'
import { StyledQuestionContainer, StyledSecondaryHeading } from './style'

type CustomQuestionFieldProps = {
  className?: string
  format?: (value: string) => void
  header?: string
  name: string
  dataType: string
  label?: string
  options?: string[]
  required?: boolean
  validator?: (value: string) => string | undefined
  maxLength?: number
  inputTypePhone?: boolean
  parse?: (value: string) => void
  disabledYesNoQuestion?: boolean
  booleanInputComponent?: () => React.JSX.Element
}

/**
 * Field used to render each custom question. Automatically handles the input
 * type.
 */

const CustomQuestionField = ({
  className,
  format,
  header,
  name,
  dataType,
  label,
  options,
  parse,
  required,
  validator,
  maxLength,
  inputTypePhone,
  disabledYesNoQuestion,
  booleanInputComponent: BooleanInputComponent = BooleanInput,
}: CustomQuestionFieldProps) => {
  let validateWithValidator

  if (dataType === INPUT_TYPE_LIST && typeof options !== 'undefined') {
    const inputListValidator = (value: string) =>
      !options.includes(value) ? 'Required' : undefined
    validateWithValidator = required
      ? inputListValidator
      : validator
        ? validator
        : undefined
  } else {
    validateWithValidator = required
      ? validate
      : validator
        ? validator
        : undefined
  }

  return (
    <MuiThemeProvider theme={ssTheme}>
      <StyledQuestionContainer className={`${className || ''}`}>
        {header && (
          <StyledSecondaryHeading
            id='mainContent'
            tabIndex='-1'
            $isOverWrap={true}
          >
            {header}
          </StyledSecondaryHeading>
        )}
        <Field
          format={format}
          name={name}
          options={options}
          data-testid={name}
          validate={validateWithValidator}
          parse={parse}
        >
          {(props: {
            input: { value: string; onChange: (value: string) => void }
            meta: { touched: boolean; error: string }
            label: string
            dataTestId: string
            name: string
          }) => {
            switch (dataType) {
              case INPUT_TYPE_STRING:
                return (
                  <>
                    <StyledLabel htmlFor={`${name}-label`}>{label}</StyledLabel>
                    <StringInput
                      inputTypePhone={inputTypePhone}
                      maxLength={maxLength}
                      {...props}
                    />
                  </>
                )
              case INPUT_TYPE_DATE:
                return (
                  <DateInput
                    label={label}
                    {...props}
                    parse={parse}
                    format={format}
                  />
                )
              case INPUT_TYPE_LIST:
                return (
                  <ListInput
                    {...props}
                    label={label}
                    dataTestId={name}
                    name={name}
                  />
                )
              case INPUT_TYPE_BOOLEAN:
                return (
                  <BooleanInputComponent
                    {...props}
                    label={label}
                    dataTestId={name}
                    disabled={disabledYesNoQuestion}
                  />
                )
              case INPUT_TYPE_MULTI_SELECT:
                return (
                  <MultiSelectInput
                    {...props}
                    label={label}
                    dataTestId={name}
                    name={name}
                  />
                )
              default:
                throw new Error(`Unsupported Data Type: ${dataType}`)
            }
          }}
        </Field>
      </StyledQuestionContainer>
    </MuiThemeProvider>
  )
}

export default CustomQuestionField
