import { FormControlLabel } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import ErrorIcon from '@material-ui/icons/Error'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

interface StyledHouseholdMembersListProps extends CSSProperties {
  textalign?: string
}

export const StyledHouseholdMembersList = styled.div<StyledHouseholdMembersListProps>`
  ${u.zeroPadding}
  display: ${(props) => props.display};
  text-align: ${(props) => props.textalign};
`

interface StyledHouseholdMembersListItemProps extends CSSProperties {
  birthDate?: string | undefined
  birthFluid?: boolean
  income?: boolean
  justifycontent?: string
  houseHold?: boolean
}

export const StyledHouseholdMembersListItem = styled.div<StyledHouseholdMembersListItemProps>`
  width: ${({ width, birthDate }) =>
    width ? width : birthDate ? '33.3333%' : '100%'};
  ${({ display, hidden }) =>
    (display || hidden) &&
    css`
      display: ${hidden ? 'none' : display};
    `}
  margin: ${(props) => (props.margin ? props.margin : '1.6rem 0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  ${({ birthDate }) =>
    birthDate &&
    css`
      ${u.dInlineFlex}
      padding: 0 1rem;
    `};
  ${({ birthFluid }) =>
    birthFluid &&
    css`
      ${u.wd100}
      ${u.dFlex}
    `}
  ${({ income }) =>
    income &&
    css<StyledHouseholdMembersListItemProps>`
      justify-content: ${(props) =>
        props.justifycontent}; //justifycontent does not exist on ThemeProps. Does it even work?
      ${u.pRelative};
      margin: 0;
      background-color: #f9f9f9;
      height: auto;
      padding: 0 1rem;
      display: flex;
      > p {
        &:first-child {
          width: 50%;
        }
        &:last-child {
          text-align: right;
          width: 50%;
        }
      }
    `};
  ${(
    { houseHold } //typo (household is one word so should not be camelCased
  ) =>
    houseHold &&
    css<StyledHouseholdMembersListItemProps>`
      justify-content: ${(props) =>
        props.justifycontent}; //justifycontent does not exist on ThemeProps. Does it even work?
      ${u.pRelative};
      margin: 0;
      background-color: #f9f9f9;
      height: auto;
      padding: 0 1rem;
    `}
`

interface StyledMuiTextFieldWrapperProps extends CSSProperties {
  $mbottom?: string
  mtop?: string
  textalign?: string
  justCont?: string
  alignitems?: string
  shadow?: string
  radius?: string
  defaultInput?: boolean
}

export const StyledMuiTextFieldWrapper = styled.div<StyledMuiTextFieldWrapperProps>`
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  margin-bottom: ${(props) => (props.$mbottom ? props.$mbottom : '2rem')};
  margin-top: ${(props) => props.mtop};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  text-align: ${(props) => props.textalign};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justCont};
  align-items: ${(props) => props.alignitems};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  box-shadow: ${(props) => props.shadow};
  border-radius: ${(props) => props.radius};
  background-color: ${(props) => props.background};
  ${media.smaller`
    justify-content: ${(props) =>
      props.justCont}; //justCont does not exist on ThemeProps. Does it even work?
    padding: ${(props) =>
      props.mobilePadd ? props.mobilePadd : props.padding};
      ${({ $categories }) =>
        $categories &&
        css`
          width: auto;
          margin-right: 1rem;
        `}
  `};
  ${media.sm`
   padding: ${(props) => props.tabPadd};
  `};
  ${media.tablet`
    padding: ${(props) =>
      props.tabPadd
        ? props.tabPadd
        : props.padding}; //tabPad does not exist on ThemeProps. Does it even work?
  `};
  ${media.desktop`
    padding: ${(props) =>
      props.padding}; //padding does not exist on ThemeProps. Does it even work?
  `};
  ${({ defaultInput }) =>
    defaultInput &&
    css`
      button {
        outline: unset;
        border: 1px solid rgba(0, 0, 0, 0.23);
        &:hover {
          outline: unset;
          border: 1px solid #000;
        }
        &:focus {
          outline: unset;
          border: 2px solid #0d8762;
        }
      }
    `}
  label.Mui-error {
    color: #e41a0c;
  }
`

export const StyledCheckboxLabelField = withStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
    '& svg': {
      '@media (max-width: 576px)': {
        fontSize: '2.2rem',
      },
    },
  },
  label: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
})(FormControlLabel)

export const StyledTextField = withStyles({
  root: {
    '& ~ div': {
      margin: 0,
    },
  },
})(TextField)

export const StyledErrorSpan = styled.span`
  position: relative;
  top: 0;
  line-height: 1rem;
  color: #e41a0c;
`

export const StyledFormHelperText = withStyles({
  root: {
    color: '#E41A0C',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '0.35rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
})(FormHelperText)

export const StyledErrorIcon = withStyles({
  root: {
    fontSize: '1.25rem',
    color: '#E41A0C',
  },
})(ErrorIcon)

export const FieldErrorIcon = styled(StyledErrorIcon)`
  margin-right: 5px;
`

export const StyledFieldset = styled.fieldset`
  padding: 0;
  border: unset;
  legend {
    text-align: left;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: initial;
  }
`

StyledHouseholdMembersList.displayName = 'StyledHouseholdMembersList'
StyledHouseholdMembersListItem.displayName = 'StyledHouseholdMembersListItem'
