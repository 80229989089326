import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Button } from '@material-ui/core'
import { InfoIcon } from 'Components/Review/assets'
import { StyledTableCell } from 'Components/Review/ReviewSection/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

const ReviewSectionTableInfoCell = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  return (
    <>
      {isMobile && (
        <Button style={{ padding: '0' }} startIcon={InfoIcon}></Button>
      )}
      {!isMobile && (
        <StyledTableCell data-testid={'info-button'}>
          <Button style={{ padding: '0' }} startIcon={InfoIcon}></Button>
        </StyledTableCell>
      )}
    </>
  )
}
export default ReviewSectionTableInfoCell
