import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledAuthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledAuthButton = styled(NavLink)`
  text-decoration: none;
`

export const StyledListItem = styled.li``

StyledAuthHeader.displayName = 'StyledAuthHeader'
StyledAuthButton.displayName = 'StyledAuthButton'
StyledListItem.displayName = 'StyledListItem'
