import * as u from 'Shared/Theme/utilities.styles'
import styled from 'styled-components'

export const StyledSocialsList = styled.ul`
  ${u.zeroPadding}
  ${u.zeroMargin}
  list-style: none;
  ${u.dFlex}
  @media only screen and (max-width: 480px) {
    margin: 0 0 1rem;
  }
`
export const StyledSocialItem = styled.li`
  + li {
    margin-left: 5px;
  }
  a {
    padding: 8px;
    border-radius: 50%;
    box-sizing: content-box;
    opacity: 0.7;
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 1;
    }
  }
`

export const ElemIcon = styled.img``
