import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import ReviewNavigationButtons from './ReviewNavigationButtons'
import ReviewNavigationContinueButton from './ReviewNavigationContinueButton'

const StyledGrid = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
})(Grid)

/**
 * Navigation tabs for the Review UI
 *
 * @param {Array} sections The sections to render tabs for
 */

const ReviewNavigation = ({
  sections,
  onSectionClick,
  onContinueClick,
  legacy,
}) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <StyledGrid container wrap='nowrap'>
      <ReviewNavigationButtons
        sections={sections}
        onSectionClick={onSectionClick}
      />
      {!isMobile && !legacy && (
        <ReviewNavigationContinueButton onContinueClick={onContinueClick} />
      )}
    </StyledGrid>
  )
}

export default ReviewNavigation
