import React, { useMemo } from 'react'
import { createIdFromLabel } from 'Shared/helpers'

import { screenReaderContext, StyledIcon, StyledIconProps } from './style'

interface IconProps {
  children: React.ReactNode
  screenReaderLabel: string
  viewBox?: string
  width?: string
  fill?: string
  stroke?: string
  opacity?: number
  style?: React.CSSProperties
  'data-testid'?: string
  className?: string
}
const Icon = (props: IconProps & StyledIconProps) => {
  const {
    children,
    screenReaderLabel,
    viewBox,
    width,
    fill,
    stroke,
    opacity,
    alignSelf,
    backgroundColor,
    border,
    borderColor,
    boxShadow,
    cursorOnHover,
    display,
    height,
    left,
    margin,
    padding,
    position,
    radius,
    right,
    scale,
    top,
    zIndex,
    style,
    'data-testid': dataTestId,
    rotate,
    className,
  } = props
  const defaultAriaLabelledBy = useMemo(
    () => `titleID_${createIdFromLabel(screenReaderLabel)}`,
    [screenReaderLabel]
  )

  const ariaLabelledBy = props['aria-labelledby'] || defaultAriaLabelledBy

  return (
    <StyledIcon
      alignSelf={alignSelf}
      backgroundColor={backgroundColor}
      border={border}
      borderColor={borderColor}
      boxShadow={boxShadow}
      cursorOnHover={cursorOnHover}
      display={display}
      fill={fill}
      stroke={stroke}
      height={height}
      left={left}
      margin={margin}
      padding={padding}
      position={position}
      radius={radius}
      right={right}
      scale={scale}
      top={top}
      width={width}
      zIndex={zIndex}
      data-testid={dataTestId}
      rotate={rotate}
      className={className}
    >
      <svg
        viewBox={viewBox || '0 0 120 120'}
        style={{
          display: 'flex',
          width: `${width ? width : ''}`,
          fill: `${fill ? fill : ''}`,
          height: '100%',
          enableBackground: 'new 0 0 24 24',
          opacity: `${opacity ? opacity : 1}`,
          ...style,
        }}
        role='img'
        aria-labelledby={ariaLabelledBy}
        aria-hidden='true'
      >
        <title id={ariaLabelledBy} style={screenReaderContext}>
          {screenReaderLabel}
        </title>
        {children}
      </svg>
    </StyledIcon>
  )
}

export default Icon
