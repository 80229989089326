import React from 'react'
import { GetCasesQuery } from '__generated__/graphql'
import { ApolloError } from '@apollo/client'
import TabContext from '@material-ui/lab/TabContext'
import { parseBenefits } from 'Components/ClientPortal/MyBenefits/helpers'
import MyBenefitsTabContent from 'Components/ClientPortal/MyBenefits/MainContent/MyBenefitsTabContent'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'

type MyBenefitsContentProps = {
  data: GetCasesQuery | undefined
  loading: boolean
  error: ApolloError | undefined
}

const MainContent = ({ data, loading, error }: MyBenefitsContentProps) => {
  if (loading) {
    return <LoadingIndicator show={true} />
  } else if (error) {
    return <div>ERROR_GENERIC_MESSAGE</div>
  } else if (data) {
    const { allCases } = parseBenefits(data)
    return (
      <>
        <TabContext value={'ALL'}>
          <MyBenefitsTabContent tabValue={'ALL'} cases={allCases} />
        </TabContext>
      </>
    )
  }
}

export default MainContent
