import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import useHandlePatchClient from 'Components/Shared/Hooks/useHandlePatchClient'
import {
  ALTERNATE_CONTACT_MUTATION,
  PREFERENCES_CONTACT_MUTATION,
  QUERY_GET_RESPONSES,
} from 'Pages/ClientPortal/gql'
import { isJestTest } from 'Shared/helpers'

import { getClientPortalSections } from '../helpers'

/**
 * Provides formatted review section data
 */
export const useClientPortalSections = () => {
  const { data, refetch, loading, error } = useQuery(QUERY_GET_RESPONSES, {
    variables: { id: useSelector((state) => state.client.id) },
    // we should use no-cache option when testing graphQL as it has errors while mocking with cache options
    fetchPolicy: isJestTest() ? 'no-cache' : 'cache-and-network',
  })
  const handlePatchClient = useHandlePatchClient()
  const [saveAlternateContactInfo] = useMutation(ALTERNATE_CONTACT_MUTATION)
  const [savePreferenceContactInfo] = useMutation(PREFERENCES_CONTACT_MUTATION)
  return {
    sections: getClientPortalSections(
      data,
      handlePatchClient,
      saveAlternateContactInfo,
      savePreferenceContactInfo
    ),
    refetch,
    legacy: data?.client?.screening?.legacy,
    loading,
    error,
  }
}
