const toNumber = (value: string) => Number(value.replace(/,/g, ''))

const formatters = {
  toCurrencyString: (value: number) =>
    value
      ? String(value)
          .replace(/,/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : '',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toBoolean: (value: any) => (value ? value === 'true' : ''),
  toNumberString: (value: string) => (value ? value.replace(/,/g, '') : ''),
  toNumber,
  ageToNumber: (value: string) => (value ? toNumber(value) : ''),
  lowercase: (value: string) => (value ? value.toLowerCase() : ''),
  toPhoneNumber: (value: string) => {
    const numbers = value.replace(/\D/g, '')
    const char = { 0: '(', 3: ')', 6: '-' } as Record<number, string>
    let formattedValue = ''
    for (let i = 0; i < numbers.length; i++) {
      formattedValue += (char[i] || '') + numbers[i]
    }
    return formattedValue
  },
}
export default formatters
