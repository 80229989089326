import React from 'react'

import { StyledCustomSurface, StyledSurface } from './style'

interface SurfaceProps {
  children: React.ReactNode
  isCustomSurface?: boolean
  [key: string]: unknown
}

const Surface = (props: SurfaceProps) => {
  const { children, isCustomSurface } = props
  if (isCustomSurface) {
    return <StyledCustomSurface {...props}>{children}</StyledCustomSurface>
  } else {
    return <StyledSurface {...props}>{children}</StyledSurface>
  }
}

export default Surface
