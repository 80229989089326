import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import PieChartIcon from '@material-ui/icons/PieChart'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import {
  Activites,
  Cases,
  Drawdown,
  HouseHold,
  Individual,
  KPIOver,
  Notes,
} from 'Components/CaseManagement/Reporting/ReportingSidebar/sideIcons'
import {
  REPORTING_ANALYTICS_ROUTES,
  REPORTING_DEMOGRAPHICS_ROUTES,
  REPORTING_KPI_ROUTES,
} from 'Shared/constants'

const sidebarMenuArray = [
  {
    header: 'Demographics',
    Icon: GroupOutlinedIcon,
    subMenus: [
      {
        name: 'Overview',
        link: REPORTING_DEMOGRAPHICS_ROUTES.OVERVIEW,
        icon: KPIOver,
      },
      {
        name: 'Household',
        link: REPORTING_DEMOGRAPHICS_ROUTES.HOUSEHOLD,
        icon: HouseHold,
      },
      {
        name: 'Individual',
        link: REPORTING_DEMOGRAPHICS_ROUTES.INDIVIDUAL,
        icon: Individual,
      },
    ],
  },
  {
    header: 'Program Analytics',
    Icon: PieChartIcon,
    subMenus: [
      {
        name: 'Overview',
        link: REPORTING_ANALYTICS_ROUTES.OVERVIEW,
        icon: KPIOver,
      },
      {
        name: 'Cases',
        link: REPORTING_ANALYTICS_ROUTES.CASES,
        icon: Cases,
      },
      {
        name: 'Activities',
        link: REPORTING_ANALYTICS_ROUTES.ACTIVITIES,
        icon: Activites,
      },
      {
        name: 'Notes',
        link: REPORTING_ANALYTICS_ROUTES.NOTES,
        icon: Notes,
      },
    ],
  },
  {
    header: 'KPI',
    Icon: PollOutlinedIcon,
    subMenus: [
      {
        name: 'Overview',
        link: REPORTING_KPI_ROUTES.OVERVIEW,
        icon: KPIOver,
      },
      {
        name: 'Drawdown By Case',
        link: REPORTING_KPI_ROUTES.DRAWDOWN,
        icon: Drawdown,
      },
    ],
  },
]

export default sidebarMenuArray
