import styled, { css } from 'styled-components'

export type styledWrapperProps = {
  cols?: string
  isTable?: boolean
}

export const StyledLoadingWrapper = styled.div`
  position: relative;
  grid-column: ${(props: styledWrapperProps) => props.cols};
  ${({ isTable }: styledWrapperProps) =>
    isTable &&
    css`
      min-height: 15rem;
    `}
`
StyledLoadingWrapper.displayName = 'StyledLoadingWrapper'
