import React from 'react'
import { useSelector } from 'react-redux'
import useBranding from 'Components/LandingPage/Hooks/useBranding'
import ClientAuthHeader from 'Components/Shared/ClientAuthHeader/ClientAuthHeader'
import { BrandStyle } from 'Components/Shared/Header/style'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import useMenuItemsData, {
  getClientSummaryRoute,
} from 'Components/Shared/Hooks/useMenuItemsData'
import MenuItems from 'Components/Shared/MenuItems/MenuItems'
import {
  StyledList,
  StyledListItem,
  StyledNavBarWrapper,
} from 'Components/Shared/NavBar/NavBar.style'
import { HeaderContainer, HeaderFlex } from 'Components/Shared/PageHeader/style'
import SingleStopLogo from 'Components/Shared/SingleStopLogo/SingleStopLogo'
import { StyledBrandingLogo } from 'Components/Shared/TabletAndDesktopHeader/style'
import TranslationWidget from 'Components/Shared/TranslationWidget/TranslationWidget'
import { StyledNoPrint } from 'Pages/NextSteps/style'
import {
  clientLocationId,
  getCaseManagerId,
  getClientId,
  isCaseManagementPage,
  isLandingPage,
  isNextSteps,
  isScreener,
} from 'Shared/helpers'
import { Container } from 'styled-bootstrap-grid'

import Surface from '../Surface/Surface'

import { showMenu } from './helpers'

const TabletAndDesktopHeader = () => {
  const {
    tabletTopNavMenuData,
    menuItemsLoggedInAsCM,
    clientPortalData,
    caseManagementTopNavMenuData,
  } = useMenuItemsData()
  const { logo: brandingLogoUrl } = useBranding()

  const isFeatureToggleOn = useIsFeatureToggleOn()
  const isClientPortal = isFeatureToggleOn('client_portal')
  const isScreenerCompleted = useSelector(
    (state) => state.client.screenerCompleted
  )

  const menuItemsToUse = () => {
    let menuItemsData = menuItemsLoggedInAsCM
    if (!getCaseManagerId()) menuItemsData = tabletTopNavMenuData
    if (
      !getCaseManagerId() &&
      getClientId() &&
      isScreenerCompleted &&
      isClientPortal
    )
      menuItemsData = [...clientPortalData, ...tabletTopNavMenuData]
    if (getClientId() && getCaseManagerId()) {
      menuItemsData = [
        getClientSummaryRoute(clientLocationId()),
        ...caseManagementTopNavMenuData,
      ]
    } else if (
      getCaseManagerId() &&
      !getClientId() &&
      !isCaseManagementPage()
    ) {
      menuItemsData = [...caseManagementTopNavMenuData]
    }

    return menuItemsData
  }

  return (
    <Surface
      elevation={5}
      display={'flex'}
      position={isLandingPage() ? 'sticky' : ''}
      top={isLandingPage() ? '0' : ''}
      width={'100%'}
      boxShadow={isLandingPage() ? 'none' : '0px 2px 5px #0000000A'}
      padding={'0'}
      style={{ zIndex: '10000' }}
    >
      <Container>
        <HeaderContainer>
          <HeaderFlex>
            <BrandStyle data={'cypress-header-logo'}>
              <SingleStopLogo />
              {brandingLogoUrl && (
                <StyledBrandingLogo
                  data-testid='partner-logo'
                  src={brandingLogoUrl}
                />
              )}
            </BrandStyle>
            {showMenu({
              hasCaseManagerId: getCaseManagerId(),
              isNextSteps: isNextSteps(),
              isScreener: isScreener(),
            }) && (
              <StyledNoPrint>
                <StyledNavBarWrapper>
                  <h2 className='hidden-title-for-nav'>Header Menu</h2>
                  <StyledList>
                    <MenuItems items={menuItemsToUse()} fontSize='0.9rem' />
                  </StyledList>
                </StyledNavBarWrapper>
              </StyledNoPrint>
            )}
          </HeaderFlex>
          <StyledNoPrint>
            <HeaderFlex width='auto'>
              {!isCaseManagementPage() && (
                <StyledList $isTabletDesktopHeader='true'>
                  <StyledListItem>
                    <TranslationWidget />
                  </StyledListItem>
                  <ClientAuthHeader />
                </StyledList>
              )}
            </HeaderFlex>
          </StyledNoPrint>
        </HeaderContainer>
      </Container>
    </Surface>
  )
}

export default TabletAndDesktopHeader
