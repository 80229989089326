import React from 'react'
import {
  StyledLocationService,
  StyledLocationServices,
} from 'Components/Shared/LocationDetails/style'

import { CustomStyledBorderBottom, CustomStyledH3 } from './style'

const CategoriesComponent = ({
  renderedTitle,
  renderedCategories,
}: {
  renderedTitle: string
  renderedCategories: string[]
}) => {
  return (
    <>
      {renderedCategories && renderedCategories?.length > 0 && (
        <CustomStyledBorderBottom>
          <CustomStyledH3>{renderedTitle}</CustomStyledH3>
          <StyledLocationServices>
            {Array.from(new Set(renderedCategories))
              .sort((a, b) => a.length - b.length)
              .map((item, i) => (
                <StyledLocationService key={i}>
                  {item.replace('&amp;', '&')}
                </StyledLocationService>
              ))}
          </StyledLocationServices>
        </CustomStyledBorderBottom>
      )}
    </>
  )
}

export default CategoriesComponent
