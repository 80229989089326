import React, { useContext } from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import { TableContext } from 'Components/Shared/ReduxForm/CheckboxList'
import {
  StyledCheckBoxWrapper,
  StyledCheckBoxWrapperListItem,
  StyledCircle,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'

import ToolTip from '../../Screener/ToolTip'

import CheckboxRenderField from './CheckboxRenderField'
import FieldError from './FieldError'

export const ErrorField = (meta) =>
  meta &&
  meta.error &&
  meta.submitFailed && (
    <FieldError meta={{ ...meta, error: 'A selection is required.' }} />
  )

const CheckboxGroup = (props) => {
  const { options, text, toolTip, meta, fields } = props
  const [table] = useContext(TableContext)

  const CheckboxInputs = () =>
    options.map((option, index) => {
      return (
        <StyledCheckBoxWrapperListItem
          key={index}
          matchedButtonFocusId={option.name === table.focusInput}
        >
          <StyledCheckBoxWrapper>
            {fields?.name.includes('utility_expenses.utility_expenses') ||
            fields?.name.includes(
              'household.additional_income_sources.household.additional_income_sources'
            ) ||
            fields?.name.includes(
              'additional_income_sources.additional_income_sources'
            ) ? (
              <Icon
                backgroundColor={'#FFE9B1'}
                padding={'0.3rem'}
                radius={'50%'}
                scale={'2.5rem'}
                margin={'0 1rem 0 0'}
                viewBox={'0 0 22.977 18.062'}
              >
                <g transform='translate(0 -54.547)'>
                  <path
                    d='M22.854,61.615l-4.035-5.742a.676.676,0,0,0-1.106,0l-1.536,2.185A9.03,9.03,0,1,0,15.2,70.177l-.042.676a.676.676,0,0,0,.674.717H20.7a.676.676,0,0,0,.674-.717l-.5-8.174H22.3A.676.676,0,0,0,22.854,61.615ZM9.031,71.258a7.68,7.68,0,1,1,6.326-12.033l-1.68,2.39a.676.676,0,0,0,.553,1.064H15.66l-.327,5.287a7.666,7.666,0,0,1-6.3,3.292Zm11.122-9.93a.676.676,0,0,0-.674.717l.5,8.174H16.548l.505-8.174a.676.676,0,0,0-.674-.717h-.847l2.734-3.891L21,61.327Z'
                    transform='translate(0 0)'
                  />
                  <path
                    d='M136.427,143.554h-1.706a.856.856,0,0,1,0-1.712h1.646a1.236,1.236,0,0,1,1.014.573l.009.014a.676.676,0,0,0,1.14-.726l-.009-.014a2.573,2.573,0,0,0-2.27-1.2v-.811a.676.676,0,1,0-1.352,0v.811a2.235,2.235,0,0,0-1.733.641,2.208,2.208,0,0,0,1.555,3.775h1.706a.856.856,0,1,1-.011,1.712h-1.783a1.223,1.223,0,0,1-.924-.451.676.676,0,1,0-1.038.865,2.619,2.619,0,0,0,2.228.938v.811a.676.676,0,1,0,1.352,0v-.811a2.211,2.211,0,1,0,.177-4.415Z'
                    transform='translate(-126.543 -80.652)'
                  />
                </g>
              </Icon>
            ) : (
              <StyledCircle>{option.label?.charAt(0)}</StyledCircle>
            )}
            <CheckboxRenderField {...option} />
          </StyledCheckBoxWrapper>
        </StyledCheckBoxWrapperListItem>
      )
    })

  return (
    <>
      {text && <h1>{text}</h1>}
      {toolTip && <ToolTip text={toolTip} />}
      {CheckboxInputs()}
      {ErrorField(meta) && <div>{ErrorField(meta)}</div>}
    </>
  )
}

export default CheckboxGroup
