import React from 'react'
import Radio from '@material-ui/core/Radio'
import {
  StyledFormControl,
  StyledFormControlLabel,
  StyledLabel,
  StyledRadioGroup,
} from 'Components/CustomQuestions/CustomQuestionField/Inputs/BooleanInput/style'
import FormError from 'Components/Shared/FormError/FormError'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const BooleanInput = ({
  id,
  input: { onChange, name, ...rest },
  label,
  meta: { touched, error, submitError },
  dataTestId,
  disabled,
}) => (
  <Container>
    <Row alignItems='center' justifyContent='center'>
      <StyledFormControl
        component='fieldset'
        error={touched && (!!error || !!submitError)}
      >
        <StyledLabel>{label}</StyledLabel>
        <StyledRadioGroup
          {...rest}
          aria-label={label}
          data-testid={dataTestId}
          id={id}
          onChange={({ target: { value } }) => onChange(value)}
        >
          <Col md='6'>
            <StyledFormControlLabel
              value='true'
              control={<Radio name={name} />}
              label='Yes'
              disabled={disabled}
            />
          </Col>
          <Col md='6'>
            <StyledFormControlLabel
              value='false'
              control={<Radio name={name} />}
              label='No'
              disabled={disabled}
              $isBtnNo={true}
            />
          </Col>
        </StyledRadioGroup>
        <FormError error={error ?? submitError} touched={touched} />
      </StyledFormControl>
    </Row>
  </Container>
)

export default BooleanInput
