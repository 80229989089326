import React, { ReactElement } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { StyledDialogContent } from 'Components/Shared/BenefitTooltipDialog/style'
import { DialogTitle } from 'Components/Shared/Dialog/Dialog'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import { StyledButtonFilled } from 'Shared/Buttons/style'
import theme from 'Shared/Theme/ssTheme'

export type BenefitTooltipDialogProps = {
  isTabletAndMobile: boolean
  handleClose: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void
  open: boolean
  name: string
  category: string
  categoryIcon: ReactElement
  toolkitUrl?: string
  description: string
}

const BenefitTooltipDialog = ({
  isTabletAndMobile,
  handleClose,
  open,
  name,
  category,
  categoryIcon,
  toolkitUrl,
  description,
}: BenefitTooltipDialogProps) => (
  <Dialog
    fullScreen={isTabletAndMobile}
    style={{
      top: isTabletAndMobile ? 'auto' : 0,
      bottom: isTabletAndMobile ? '-1.5rem' : 0,
    }}
    className='dialogInDesktop'
    onClose={handleClose}
    open={open}
  >
    <DialogTitle
      id={name}
      onClose={handleClose}
      typostyle={{
        fontSize: '1.6875rem',
        color: '#29283E',
        fontFamily: 'inherit',
        letterSpacing: 'normal',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'inline-flex',
        }}
      >
        {categoryIcon}
      </div>
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
        }}
      >
        <StyledMuiTextFieldWrapper
          style={{
            fontSize: '0.875rem',
            fontWeight: '500',
          }}
          mbottom='0'
        >
          {category}
        </StyledMuiTextFieldWrapper>
        <StyledMuiTextFieldWrapper
          style={{ fontSize: '1.5rem', fontWeight: '500' }}
          mbottom='0'
        >
          {name}
        </StyledMuiTextFieldWrapper>
      </div>
    </DialogTitle>
    {(description || toolkitUrl) && (
      <StyledDialogContent>
        <Typography
          style={{
            fontSize: '0.9375rem',
            fontWeight: '400',
            color: '#737377',
            fontFamily: 'inherit',
            marginBottom: '1rem',
            lineHeight: '2',
            textAlign: 'left',
          }}
        >
          {description}
        </Typography>

        {toolkitUrl && (
          <StyledButtonFilled
            href={toolkitUrl}
            data-testid='benefit-guide-link'
            target='_blank'
            style={{
              color: theme.palette.primary.dark,
              fontSize: '0.875rem',
              fontWeight: '400 !important',
              border: 'unset',
              textDecoration: 'underline',
            }}
            aria-label='View Benefit Guide'
          >
            View Benefit Guide (PDF)
          </StyledButtonFilled>
        )}
      </StyledDialogContent>
    )}
  </Dialog>
)

export default BenefitTooltipDialog
