import React from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import authClient from 'ApolloClient/authClient'
import { StyledCategoriesWrapper } from 'Components/PreScreener/style'
import {
  StyledDynamicCtaButton,
  StyledScreenerBackButton,
} from 'Components/Screener/style'
import { StyledForm, StyledHeadingMain } from 'Components/Screener/style'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'
import Icon from 'Components/Shared/Icon/Icon'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import InputRenderField from 'Components/Shared/ReduxForm/InputRenderField'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { loginSelector } from 'Selectors/login'
import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'
import { validate } from 'Shared/helpers'
import backIconPath from 'Shared/Icons/backIconPath'
import { singleStopBlack } from 'Shared/Theme'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { VALIDATE_UNVERIFIED_CLIENT } from './gql'
import { submitHandler as submitHandlerThunk } from './helpers'

const ZipCodeVerification = ({
  error,
  handleSubmit,
  submitting,
}: InjectedFormProps) => {
  const emailOrPhone = loginSelector(
    useSelector((state) => state),
    'emailOrPhone'
  )

  const navigate = useNavigate()

  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  const [validateUnverifiedClient] = useMutation(VALIDATE_UNVERIFIED_CLIENT, {
    client: authClient,
  })

  const submitHandler = submitHandlerThunk({
    emailOrPhone,
    validateUnverifiedClient,
    navigate,
  })

  return (
    <HeaderWithContainerAndCard padding={'0'}>
      <StyledCategoriesWrapper>
        <Container fluid className={isDesktop ? 'col-no-padding' : ''}>
          <StyledCard
            padding={isDesktop ? '0' : '2.5rem 0'}
            height='calc(100vh - 10.4rem)'
          >
            <Row>
              <Col>
                <StyledForm onSubmit={handleSubmit(submitHandler)}>
                  <StyledHeadingMain
                    id='mainContent'
                    tabIndex='-1'
                    textalign='center'
                  >
                    Please enter the ZIP code used to create this account.
                  </StyledHeadingMain>

                  <StyledHouseholdMembersListItem>
                    <MuiThemeProvider theme={ssTheme}>
                      <StyledHouseholdMembersList>
                        <Row>
                          <Col>
                            <StyledMuiTextFieldWrapper
                              width='50%'
                              marginRight='auto'
                              marginLeft='auto'
                            >
                              <Field
                                name='zipCode'
                                type='text'
                                component={InputRenderField}
                                label='ZIP Code'
                                validate={validate}
                                autoComplete='zip-code'
                              />
                            </StyledMuiTextFieldWrapper>
                          </Col>
                        </Row>
                      </StyledHouseholdMembersList>
                      <FormSubmissionError error={error} />
                    </MuiThemeProvider>
                  </StyledHouseholdMembersListItem>

                  <StyledHouseholdMembersList textalign='center'>
                    <Row>
                      <Col>
                        <StyledDynamicCtaButton width='50%' type='submit'>
                          Continue
                          <ContinueIcon />
                        </StyledDynamicCtaButton>
                      </Col>
                      <Col>
                        <StyledScreenerBackButton
                          margin='1rem auto 0'
                          type={'button'}
                          aria-label={'Go To Login'}
                          onClick={() => {
                            navigate('/login')
                          }}
                        >
                          <Icon
                            position={'absolute'}
                            left={'1.375rem'}
                            fill={singleStopBlack}
                            margin={'0'}
                            scale={'1rem'}
                            viewBox={'0 0 24 24'}
                            cursorOnHover={'pointer'}
                          >
                            {backIconPath}
                          </Icon>
                          Back
                        </StyledScreenerBackButton>
                      </Col>
                    </Row>
                  </StyledHouseholdMembersList>
                </StyledForm>
              </Col>
            </Row>
          </StyledCard>
        </Container>
      </StyledCategoriesWrapper>
    </HeaderWithContainerAndCard>
  )
}

export default reduxForm({
  form: 'zipCodeVerification',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(ZipCodeVerification)
