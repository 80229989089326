import theme from 'Shared/Theme/ssTheme'

import { StyledBusinessHoursData } from './style'

type DayProps = {
  day: string
  openingTime: string | null
  closingTime: string | null
}

const Day = ({ day, openingTime, closingTime }: DayProps) => {
  const today = new Date()
    .toLocaleString('default', { weekday: 'long' })
    .toLowerCase()
  const isClosed = openingTime === null && closingTime === null
  const isToday = today === day
  return (
    <tr
      style={{
        color: isToday ? theme.palette.primary.dark : '',
        fontWeight: isToday ? '600' : '450',
      }}
    >
      <StyledBusinessHoursData
        paddingProp={'0.4rem 0'}
        style={{ color: isClosed ? '#E31010' : '' }}
      >
        {day}
      </StyledBusinessHoursData>
      <StyledBusinessHoursData style={{ color: isClosed ? '#E31010' : '' }}>
        {openingTime === null && closingTime === null
          ? 'Closed'
          : openingTime + '-' + closingTime}
      </StyledBusinessHoursData>
    </tr>
  )
}

export default Day
