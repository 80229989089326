import { Paper, Tooltip, withStyles } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined'
import PollOutlinedIcon from '@material-ui/icons/PollOutlined'
import { chartClearButtonTooltipFont } from 'Shared/Theme'
import styled from 'styled-components'

export const StyledChartContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 1rem 0 1rem 0;
  text-align: center;
`

export const StyledVictoryPieContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
`
export const StyledCenterValue = styled.span`
  width: 100%;
  font-size: 1.4375rem;
  letter-spacing: 0rem;
  color: #252529;
`
export const StyledCenterLabel = styled.span`
  width: 100%;
  font-size: 0.875rem;
  letter-spacing: 0rem;
  color: black;
  margin: 0.3rem 0 0 0;
`

export const StyledCenter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledCenterContainer = styled.div`
  width: 100%;
  height: 10.25rem;
  display: flex;
  align-items: center;
`

export const StyledChartTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: '0.6875rem',
    font: chartClearButtonTooltipFont,
    padding: '0.6rem',
    border: '1px solid #6363633B',
  },
  arrow: {
    '&:before': {
      border: '1px solid #6363633B',
    },
    color: '#FFFFFF',
  },
}))(Tooltip)

const sharedIconRoot = {
  margin: '0.0625rem 0.3125rem 0 0',
  justifyContent: 'center',
  alignSelf: 'center',
  display: 'inline-flex',
  fill: '#29283E',
  width: '1.35625rem',
  height: '1.35625rem',
}

export const StyledGroupOutlinedIcon = withStyles({
  root: sharedIconRoot,
})(GroupOutlinedIcon)

export const StyledPieChartOutlinedIcon = withStyles({
  root: sharedIconRoot,
})(PieChartOutlinedIcon)

export const StyledPollOutlinedIcon = withStyles({
  root: sharedIconRoot,
})(PollOutlinedIcon)

interface StyledPaperProps extends CSSProperties {
  autoheight?: boolean
  nexttolast?: boolean
}

export const StyledPaper = withStyles({
  root: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0 0.625rem 0.9375rem #7088D21A',
    borderRadius: '0.625rem',
    opacity: '1',
    padding: '1.25rem 1.875rem',
    height: '100%',
    minHeight: (props: StyledPaperProps) =>
      props.autoheight ? 'auto' : '15rem',
    gridColumn: (props: StyledPaperProps) =>
      props.nexttolast ? '1 / 3' : undefined,
    '@media only screen and (max-width: 480px)': {
      padding: '1rem 1.25rem',
    },
    '@media only screen and (max-width: 1024px)': {
      width: '100%',
      marginBottom: '1.5rem',
      display: 'flex',
    },
  },
})(Paper)

export const StyledCard = withStyles({
  root: {
    padding: '1.05rem 1.175rem',
    height: '100%',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0 0.625rem 0.9375rem #7088D21A',
    borderRadius: '0.625rem',
  },
})(Paper)

StyledPaper.displayName = 'StyledPaper'
StyledChartTooltip.displayName = 'StyledChartClearButtonTooltip'
StyledGroupOutlinedIcon.displayName = 'StyledGroupOutlinedIcon'
