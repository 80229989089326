import React, {
  Children,
  cloneElement,
  DetailedHTMLProps,
  HTMLAttributes,
  useState,
} from 'react'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { IStyledComponent } from 'styled-components'
import { FastOmit } from 'styled-components/dist/types'

import { StyledLoadingWrapper } from './style'

type LoadingContainerProps = {
  children: React.ReactElement
  multiple?: boolean
  WrapperComponent?: IStyledComponent<
    'web',
    FastOmit<
      DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      never
    >
  >
}

const LoadingContainer = ({
  children,
  multiple,
  WrapperComponent = StyledLoadingWrapper,
}: LoadingContainerProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)

  const hideLoadingIndicator = () => {
    setIsLoading(false)
  }
  const showLoadingIndicator = () => {
    setIsLoading(true)
  }

  if (multiple) {
    return (
      <>
        {Children.map(
          children,
          (
            child: React.ReactElement<{
              key: number
              cols?: string
              showLoadingIndicator: () => void
              hideLoadingIndicator: () => void
            }>,
            i: number
          ) =>
            child ? ( // in case any children are conditionally rendered
              <WrapperComponent cols={child.props?.cols}>
                <LoadingIndicator show={isLoading} />
                {cloneElement(child, {
                  key: i,
                  showLoadingIndicator,
                  hideLoadingIndicator,
                })}
              </WrapperComponent>
            ) : null
        )}
      </>
    )
  }

  return (
    <WrapperComponent isTable={true}>
      <LoadingIndicator show={isLoading} />
      {Children.map(
        children,
        (
          child: React.ReactElement<{
            hideLoadingIndicator: () => void
            showLoadingIndicator: () => void
          }>
        ) =>
          cloneElement(child, {
            hideLoadingIndicator,
            showLoadingIndicator,
          })
      )}
    </WrapperComponent>
  )
}

export default LoadingContainer
