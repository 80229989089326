export const filters = {
  quickFilters: {
    1102: {
      name: 'Food',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'food',
    },
    1103: {
      name: 'Housing',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'housing',
    },
    1104: {
      name: 'Goods',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'goods',
    },
    1105: {
      name: 'Transit',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'transit',
    },
    1106: {
      name: 'Health',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'health',
    },
    1107: {
      name: 'Money',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'money',
    },
    1108: {
      name: 'Care',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'care',
    },
    1109: {
      name: 'Education',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'education',
    },
    1110: {
      name: 'Work',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'work',
    },
    1111: {
      name: 'Legal',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'legal',
    },
    1270: {
      name: 'Tax Preparation',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'tax%20preparation',
    },
    1381: {
      name: 'Citizenship \u0026 Immigration',
      selected: false,
      filterKey: 'serviceTag',
      filterValue: 'citizenship%20%26%20immigration',
    },
    20010: {
      name: 'Veterans',
      selected: false,
      filterKey: 'attributeTag',
      filterValue: 'veterans',
    },
  },
  attributes: {
    singleStop: { label: 'Single Stop', id: 'singleStop', selected: false },
  },
  serviceTags: '',
  distance: { meters: 32187, value: 20, unit: 'mi' },
  query: '',
  rehydrated: false,
  persisted: false,
  categoriesFilter: {
    topLevelLabel: '',
    secondLevelLabel: '',
    lastLevelLabel: '',
    currentFilterLevel: 'first',
  },
}
