import React, { useState } from 'react'
import { Client, HouseholdMember } from '__generated__/graphql'
import {
  updateCurrentStep,
  updateHouseholdMembersIndex,
} from 'Actions/screener'
import { removeHouseholdMember } from 'Actions/screener'
import {
  firstChar,
  firstName,
  isClient,
  lastName,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/helpers'
import {
  StyledCircle,
  StyledEditIcon,
  StyledOnlyVisibleToScreenReader,
  StyledRemoveCircleIcon,
  StyledTableRow,
  StyledTableTd,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'
import { isSpacebarOrEnterKey } from 'Shared/helpers'
import { useAppDispatch } from 'Store/hooks'

export interface HouseholdMemberWithIndex extends HouseholdMember {
  index: number
}
type HouseholdMembersProps = {
  householdMembers: HouseholdMemberWithIndex[]
  client: Client
}
const HouseholdMembers = ({
  householdMembers,
  client,
}: HouseholdMembersProps) => {
  const [memberButtonFocusId, setMemberButtonFocusId] = useState<null | number>(
    null
  )

  const dispatch = useAppDispatch()

  const editHouseholdMember = ({ index }: { index: number }) => {
    dispatch(updateHouseholdMembersIndex(index))
    dispatch(updateCurrentStep(209))
  }

  const familyMembers: [Client, ...HouseholdMemberWithIndex[]] =
    householdMembers ? [client, ...householdMembers] : [client]

  const familyMembersSize = familyMembers.length

  const handleButtonBlur = () => {
    setMemberButtonFocusId(null)
  }

  return familyMembers.map((member, index) => (
    <StyledTableRow
      data-testid={`familyMemberRow${index}`}
      key={index}
      matchedButtonFocusId={index === memberButtonFocusId}
      memberId={index}
    >
      <StyledTableTd width='29%' widthSM='27%'>
        <StyledOnlyVisibleToScreenReader>
          {`Member ${index + 1} of ${familyMembersSize}: `}
        </StyledOnlyVisibleToScreenReader>
        <StyledCircle data-testid={`Initials${index}`}>
          {firstChar(firstName(member), lastName(member))}
        </StyledCircle>
        {firstName(member)}
      </StyledTableTd>

      <StyledTableTd width='28%'>{lastName(member)}</StyledTableTd>

      <StyledTableTd>
        {!isClient(member) ? (
          <StyledEditIcon
            tabIndex={0}
            data-testid={`editButton${(member as HouseholdMemberWithIndex).index}`}
            onClick={() =>
              editHouseholdMember({
                index: (member as HouseholdMemberWithIndex).index,
              })
            }
            onFocus={() => setMemberButtonFocusId(index)}
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                editHouseholdMember({
                  index: (member as HouseholdMemberWithIndex).index,
                })
              }
            }}
            onBlur={handleButtonBlur}
            aria-label={`Button to edit ${
              firstName(member) + lastName(member)
            }`}
          />
        ) : (
          ''
        )}
      </StyledTableTd>
      <StyledTableTd household>
        {!isClient(member) ? (
          <StyledRemoveCircleIcon
            tabIndex={0}
            data-testid={`RemoveButton${(member as HouseholdMemberWithIndex).index}`}
            onClick={() =>
              dispatch(
                removeHouseholdMember({
                  index: (member as HouseholdMemberWithIndex).index,
                })
              )
            }
            onKeyDown={(evt) => {
              if (isSpacebarOrEnterKey(evt.keyCode)) {
                removeHouseholdMember({
                  index: (member as HouseholdMemberWithIndex).index,
                })
              }
            }}
            onFocus={() => setMemberButtonFocusId(index)}
            onBlur={handleButtonBlur}
            aria-label={`Button to remove ${
              firstName(member) + lastName(member)
            }`}
          />
        ) : (
          ''
        )}
      </StyledTableTd>
    </StyledTableRow>
  ))
}

export default HouseholdMembers
