import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'

import {
  CustomContainer,
  CustomStyledClientPortalContent,
  CustomStyledClientPortalWrapper,
} from './customClientPortal/clientcustomstyle'
import ClientPortalTabs from './customClientPortal/ClientPortalTabs'

const ClientPortalTemplate = ({ children }: { children: ReactElement }) => {
  const { location: pathname } = useLocation()
  return (
    <>
      <Header />
      <Menu pathname={pathname} />

      <CustomStyledClientPortalWrapper>
        <CustomContainer>
          <ClientPortalTabs />
        </CustomContainer>
        <CustomContainer>
          <CustomStyledClientPortalContent>
            {children}
          </CustomStyledClientPortalContent>
        </CustomContainer>
      </CustomStyledClientPortalWrapper>
    </>
  )
}

export default ClientPortalTemplate
