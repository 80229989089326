import {
  CLEAR_CLIENT,
  CLIENT_AUTHENTICATED,
  COMPLETE_SCREENER,
  RESET_COMPLETE_SCREENER,
  START_EDITING,
  STOP_EDITING,
  UPDATE_CLIENT,
} from 'Actions/client'
import { UPDATE_PHYSICAL_ADDRESS } from 'Actions/geolocation'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { client as initialState } from './initialState'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.client,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case START_EDITING: {
      const { editingStepNumber, editingCategoryId } = action
      return {
        ...state,
        editing: true,
        screenerCompleted: false,
        editingStepNumber,
        editingCategoryId,
      }
    }

    case STOP_EDITING:
      return {
        ...state,
        editing: false,
        editingStepNumber: null,
        editingCategoryId: null,
      }

    case COMPLETE_SCREENER:
      return {
        ...state,
        screenerCompleted: true,
        editing: false,
      }

    case RESET_COMPLETE_SCREENER:
      return {
        ...state,
        screenerCompleted: false,
      }

    case UPDATE_CLIENT: {
      const { client } = action

      return {
        ...state,
        ...client,
      }
    }

    case UPDATE_PHYSICAL_ADDRESS: {
      const { physicalAddress } = action
      return {
        ...state,
        physical_address: physicalAddress,
      }
    }

    case CLEAR_CLIENT:
      return initialState

    case CLIENT_AUTHENTICATED: {
      const { authenticated } = action
      return {
        ...state,
        authenticated,
      }
    }

    default:
      return state
  }
}
