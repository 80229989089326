import { useDispatch, useSelector } from 'react-redux'
import { updateStep } from 'Actions/preScreener'
import { RootState } from 'Store'

const useStep = () => {
  const step = useSelector((state: RootState) => state.preScreener.step)
  const dispatch = useDispatch()

  const handleUpdateStep = (nextStep: number) => {
    const next = nextStep ? nextStep : step + 1
    dispatch(updateStep(next as number))
  }
  return { step, handleUpdateStep }
}

export default useStep
