import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { updateClient } from 'Actions/client'
import authClient from 'ApolloClient/authClient'
import { setSessionCredentials } from 'Shared/helpers'
import { isJestTest } from 'Shared/helpers'

import { CASE_MANAGER_VERIFY_CLIENT_OTP, GET_OTP, USER_VERIFY_OTP } from './gql'
import {
  displayUserNameArgs,
  parseAuthDataArgs,
  requestOtpArgs,
  setClientIdArgs,
  verifyOtpArgs,
} from './types'

const mutationOptions = isJestTest()
  ? {}
  : {
      client: authClient,
    }

const parseIdFromClient = ({ data, operationName }: parseAuthDataArgs) => {
  const {
    [operationName]: {
      client: { id },
    },
  } = data
  return id
}

const parseIdFromUserable = ({ data, operationName }: parseAuthDataArgs) => {
  const {
    [operationName]: {
      authenticatable: {
        userable: { id },
      },
    },
  } = data
  return id
}

const setClientCredentials = ({ data, operationName }: parseAuthDataArgs) => {
  if (operationName !== 'userVerifyOtp') return
  setSessionCredentials(data[operationName].credentials)
}

const setUserDisplayName = ({
  response,
  operationName,
}: displayUserNameArgs) => {
  let fullName
  switch (operationName) {
    case 'caseManagerSkipClientOtp':
      fullName = response?.data?.caseManagerSkipClientOtp.client.fullName
      break
    case 'caseManagerVerifyClientOtp':
      fullName = response?.data?.caseManagerVerifyClientOtp.client.fullName
      break
    case 'userVerifyOtp':
      fullName = response?.data?.userVerifyOtp.authenticatable.userable.fullName
      break
    default:
      fullName = response?.data?.userVerifyOtp.authenticatable.userable.fullName
      break
  }
  sessionStorage.setItem('userDisplayName', fullName)
}

const useOtp = () => {
  const dispatch = useDispatch()

  const handleUpdateClient = (id: string) => {
    dispatch(updateClient({ id }))
  }

  const [getOtp, { loading }] = useMutation(GET_OTP, {
    ...mutationOptions,
  })

  const [userVerifyOtp] = useMutation(USER_VERIFY_OTP, {
    ...mutationOptions,
  })

  const [caseManagerVerifyClientOtp] = useMutation(
    CASE_MANAGER_VERIFY_CLIENT_OTP,
    {
      ...mutationOptions,
    }
  )

  const handleRequestOtp = ({ email, primaryPhone }: requestOtpArgs) => {
    const uuid = email ? { email } : { phoneNumber: primaryPhone }
    const variables = {
      ...uuid,
      deliveryMethod: email ? 'EMAIL' : 'SMS',
    }
    return getOtp({ variables }).then((response) => response)
  }

  const handleVerifyOtp = ({
    email,
    otp,
    primaryPhone,
    isACaseManager,
  }: verifyOtpArgs) => {
    const uuid = email ? { email } : { phone: primaryPhone }
    const variables = {
      ...uuid,
      ...{ otp },
    }
    const mutationFunction = isACaseManager
      ? caseManagerVerifyClientOtp
      : userVerifyOtp

    const operationName = isACaseManager
      ? 'caseManagerVerifyClientOtp'
      : 'userVerifyOtp'

    return mutationFunction({ variables })
      .then((response) => {
        setUserDisplayName({ response, operationName })
        return response
      })
      .catch((response) => response)
  }

  const setClientId = ({
    operationName,
    isACaseManager,
    data,
  }: setClientIdArgs) => {
    const parserFunction =
      operationName === 'caseManagerSkipClientOtp' ||
      operationName === 'caseManagerVerifyClientOtp'
        ? parseIdFromClient
        : parseIdFromUserable
    const id = parserFunction({ data, operationName })
    sessionStorage.setItem('clientId', id)
    handleUpdateClient(id)
  }

  return {
    handleRequestOtp,
    handleUpdateClient,
    handleVerifyOtp,
    loading,
    setClientId,
    setClientCredentials,
    parseIdFromUserable,
    parseIdFromClient,
  }
}

export default useOtp
