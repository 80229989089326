import grey from '@material-ui/core/colors/grey'
import { createTheme } from '@material-ui/core/styles'

export const mainMuiTheme = createTheme({
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    primary: grey,
  },
})

export const caseManagementMuiTheme = createTheme({
  typography: {
    htmlFontSize: 18,
  },
  palette: {
    primary: {
      main: '#0d8762',
    },
    error: {
      main: '#e41a0c',
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#0d8762',
        color: 'white',
        fontSize: '1rem',
      },
    },
  },
})
