import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

const containerWidth = '1140px'

export const LandingSection = styled.section`
  padding-top: 2em;
  padding-bottom: 4em;
  position: relative;
  ${media.smaller`
    padding-bottom: 6em;
  `}
  ${({ hero }) =>
    hero &&
    css`
      padding-top: 1em;
      &:after {
        content: '';
        background-color: ${theme.palette.secondary.lighten20};
        height: 150px;
        width: 100%;
        position: absolute;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
        bottom: -1px;
        ${media.smaller`
          height: 80px;
          clip-path: polygon(0% 16%,0% 100%,100% 100%);
        `}
      }
      ${media.tablet`
        padding-bottom: 7em;
      `}
      ${media.desktop`
        padding-bottom: 5em;
      `}
    `};

  ${({ light }) =>
    light &&
    css`
      background-color: ${theme.palette.secondary.lighten20};
      padding-top: 0em;
      padding-bottom: 0em;
      ${media.desktop`
      direction: rtl;
      text-align: left;
      padding-top: 3em;
      `}
    `};

  ${({ Help }) =>
    Help &&
    css`
      &:after {
        content: '';
        background-color: ${theme.palette.color.lightSemon};
        height: 9.5rem;
        width: 100%;
        position: absolute;
        clip-path: polygon(0% 40%, 0% 100%, 100% 100%);
        bottom: -1px;
      }
      ${media.smaller`
      &:after {
        content: '';
        background-color: ${theme.palette.secondary.lightSemon};
        width: 100%;
        position: absolute;
        bottom: -1px;
        height: 6.75rem;
        clip-path: polygon(0% 16%,0% 100%,100% 100%);
      }
      `}
      ${media.desktop`
        padding-bottom: 10em;
        padding-top: 4em;
      `}
    `};

  ${({ ResourceSection }) =>
    ResourceSection &&
    css`
      ${u.wd100}
      ${u.dFlex}
    background-color: ${theme.palette.color.lightSemon};
      padding: 0rem 0 4rem;
      ${u.pRelative}
      ${media.smaller`
    padding: 3rem 0 0rem;
    ${u.pRelative}
    top: 0px;
    `};
      ${media.desktop`
    direction: rtl;
    text-align: left;
    `}
    `}
`
export const LandingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
export const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  ${media.desktop`
    max-width: calc(${containerWidth} / (12 / ${(props) => props.num}));
    flex: 0 0 calc(${containerWidth} / (12 / ${(props) => props.num}));
    margin-inline-start: calc(1140px / (12 / ${(props) => props.offset}));
  `}
`

LandingSection.displayName = 'LandingSection'
LandingRow.displayName = 'LandingRow'
Column.displayName = 'Column'
