import React from 'react'
import { useMediaQuery } from 'react-responsive'
import CustomQuestionsForm from 'Components/CustomQuestions/CustomQuestionsForm/CustomQuestionsForm'
import useCustomScreenerQuestions from 'Components/Screener/Hooks/useCustomScreenerQuestions'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import ProgressSideBar from 'Components/Shared/ProgressSideBar/ProgressSideBar'
import { StyledMain } from 'Pages/Results/style'
import { DESKTOP_MEDIA_QUERY } from 'Shared/constants'
import { getClientId } from 'Shared/helpers'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const CustomQuestions = () => {
  const clientId = getClientId()
  const isDesktop = useMediaQuery({
    query: DESKTOP_MEDIA_QUERY,
  })

  const {
    customFields,
    responses,
    customQuestionsHeader,
    customQuestionsText,
    loading: loadingQuestions,
  } = useCustomScreenerQuestions({ clientId })

  // In order to submit empty string values for untouched (optional) fields we have to set empty string initial values
  // However, we may have a previously submitted value
  // Therefore, we reduce the (previously submitted) responses adding in empty string values for any unanswered questions
  const initialValues = customFields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: responses[curr.name] ? responses[curr.name] : '',
    }),
    {}
  )

  return (
    <>
      <Header />
      <Menu />
      <StyledMain>
        {isDesktop && (
          <Container fluid>
            <Row>
              <ProgressSideBar
                preSelectedCategoryId={0}
                preProgressPercentage={99}
              />
            </Row>
          </Container>
        )}
        <Container>
          <Row alignItems='center' justifyContent='center'>
            <Col md='12'>
              <CustomQuestionsForm
                loadingQuestions={loadingQuestions}
                customFields={customFields}
                initialValues={initialValues}
                customQuestionsHeader={customQuestionsHeader}
                customQuestionsText={customQuestionsText}
              />
            </Col>
          </Row>
        </Container>
      </StyledMain>
    </>
  )
}

export default CustomQuestions
