import React from 'react'
import { connect } from 'react-redux'
import MediaQuery from 'react-responsive'
import { toggleMainMenu } from 'Reducers/uiSlice'
import { MOBILE_AND_TABLET_MEDIA_QUERY } from 'Shared/constants'
import closeIconPath from 'Shared/Icons/closeIconPath'
import menuIconPath from 'Shared/Icons/menuIconPath'
import { withTheme } from 'styled-components'

import Icon from '../Icon/Icon'

import { StyledCloseButton, StyledMobileMenuButton } from './style'

const MenuToggle = (props) => {
  const { toggleMenu } = props
  const { showMainMenu } = props.ui

  const { closed: fillClosed } = props.theme.menuToggle.fill

  const iconScale = '2.4rem'

  if (showMainMenu) {
    return (
      <>
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledCloseButton onClick={toggleMenu} tabIndex={0}>
            <Icon
              fill={fillClosed}
              scale={iconScale}
              screenReaderLabel={'Close Menu'}
              viewBox={'0 0 24 24'}
              cursorOnHover={'pointer'}
            >
              {closeIconPath}
            </Icon>
          </StyledCloseButton>
        </MediaQuery>
      </>
    )
  } else {
    return (
      <>
        <MediaQuery query={MOBILE_AND_TABLET_MEDIA_QUERY}>
          <StyledMobileMenuButton
            onClick={toggleMenu}
            aria-label={'Open main menu'}
          >
            <Icon
              fill={fillClosed}
              scale={iconScale}
              cursorOnHover={'pointer'}
              viewBox={'0 0 24 24'}
            >
              {menuIconPath}
            </Icon>
          </StyledMobileMenuButton>
        </MediaQuery>
      </>
    )
  }
}

export const ThemedMenuToggle = withTheme(MenuToggle)

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    ui,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => {
      dispatch(toggleMainMenu())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemedMenuToggle)
