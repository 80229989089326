import { useContext } from 'react'

import { FeatureToggleContext } from '../../FeatureToggleProvider/FeatureToggleProvider'

const useIsFeatureToggleOn = () => {
  const toggles = useContext(FeatureToggleContext)
  return (toggleName: string): boolean =>
    toggles[toggleName] ? toggles[toggleName] === 'on' : false
}

export default useIsFeatureToggleOn
