import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledCard = styled.div<{ transform: string }>`
  background-color: #fff;
  border-radius: 7px;
  width: 100%;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px #1d283117;
  transform: ${(props) => props.transform};
  opacity: 0;
`
export const StyledCardTitle = styled.h3`
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 0.5em;
  color: ${theme.palette.text.Lighten};
`

export const StyledImage = styled.img`
  width: 100px;
  ${media.smaller`
  width: inherit;
  `}
`
export const CustomStyledImage = styled.img`
  width: 100px;
  ${media.smaller`
  width: inherit;
  `}
  @media (min-width:769px ) and (max-width: 1200px) {
    width: 75px;
  }
`
StyledCard.displayName = 'StyledCard'
StyledCardTitle.displayName = 'StyledCardTitle'
StyledImage.displayName = 'StyledImage'
