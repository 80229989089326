import React from 'react'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import { BaseFieldProps } from 'redux-form'

import StyledHouseholdMemberListItem from './StyledHouseholdMemberListItem'

export interface FieldSelectProps {
  name: string
  children: React.ReactNode
  label: string
  validate?: Pick<BaseFieldProps, 'validate'>
  normalize?: Pick<BaseFieldProps, 'normalize'>
}
const FieldSelect = ({
  name,
  children,
  label,
  validate,
  normalize,
}: FieldSelectProps) => (
  <StyledMuiTextFieldWrapper>
    <StyledHouseholdMemberListItem
      name={name}
      label={label}
      validate={validate}
      normalize={normalize}
    >
      {children}
    </StyledHouseholdMemberListItem>
  </StyledMuiTextFieldWrapper>
)

export default FieldSelect
