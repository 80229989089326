import {
  CLEAR_FILTER,
  LOAD_INITIAL_FILTERS,
  TOGGLE_SINGLE_STOP_FILTER,
  UPDATE_CATAGORIES_FILTER,
  UPDATE_DISTANCE_AROUND,
  UPDATE_DISTANCE_FILTER_UI,
  UPDATE_FILTER_LEVEL,
  UPDATE_FILTERED_SERVICE_TAGS,
  UPDATE_LANGUAGE_SELECTED,
  UPDATE_QUERY,
  UPDATE_QUICK_FILTER_SELECTED,
} from 'Actions/filters'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { filters as initialState } from './initialState'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.filters,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_DISTANCE_FILTER_UI: {
      const { value, unit } = action.payload
      return {
        ...state,
        distance: {
          ...state.distance,
          value,
          unit,
        },
      }
    }

    case LOAD_INITIAL_FILTERS:
      return action.payload.filters

    case UPDATE_QUICK_FILTER_SELECTED: {
      const { payload: quickFilterId } = action
      const filter = state.quickFilters[quickFilterId]

      const updatedFilter = {
        ...filter,
        selected: !filter.selected,
      }

      return {
        ...state,
        quickFilters: {
          ...state.quickFilters,
          [quickFilterId]: updatedFilter,
        },
      }
    }

    case UPDATE_LANGUAGE_SELECTED: {
      const { payload: languageId } = action
      const language = state.languageAccommodation[languageId]

      const updatedLanguage = {
        ...language,
        selected: !language.selected,
      }

      return {
        ...state,
        languageAccommodation: {
          ...state.languageAccommodation,
          [languageId]: updatedLanguage,
        },
      }
    }

    case TOGGLE_SINGLE_STOP_FILTER:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          singleStop: {
            ...state.attributes.singleStop,
            selected: !state.attributes.singleStop.selected,
          },
        },
      }

    case UPDATE_DISTANCE_AROUND:
      return {
        ...state,
        distance: {
          ...state.distance,
          meters: action.distance,
        },
      }

    case UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      }

    case CLEAR_FILTER: {
      state = initialState
      return {
        ...state,
        query: action.query,
      }
    }
    case UPDATE_FILTERED_SERVICE_TAGS: {
      const { payload: serviceTags } = action
      return {
        ...state,
        serviceTags,
      }
    }
    case UPDATE_FILTER_LEVEL: {
      const { payload: level } = action
      const categoriesFilter = {
        ...state.categoriesFilter,
        currentFilterLevel: level,
      }
      return {
        ...state,
        categoriesFilter,
      }
    }
    case UPDATE_CATAGORIES_FILTER: {
      const { payload: categoriesFilter } = action
      return {
        ...state,
        categoriesFilter,
      }
    }

    default:
      return state
  }
}
