import React from 'react'
import { BaseFieldProps } from 'redux-form'

import DateSelectorMilitaryFields from './DateSelectorMilitaryFields'
import MilitaryDateSelectorFieldArray from './MilitaryDateSelectorFieldArray'

interface MilitaryDateSelectorProps {
  iterable?: boolean
  name: string
  label: string
  required?: boolean
  disabled?: boolean
  validate?: Pick<BaseFieldProps, 'validate'>
  [key: string]: unknown
}
const MilitaryDateSelector = (props: MilitaryDateSelectorProps) =>
  props.iterable ? (
    <MilitaryDateSelectorFieldArray {...props} />
  ) : (
    <DateSelectorMilitaryFields {...props} />
  )

export default MilitaryDateSelector
