import React from 'react'
import { useSelector } from 'react-redux'
import useInitializeForms from 'Components/Shared/Hooks/useInitializeForms'
import { RootState } from 'Store'

import Step from './Step'

const Steps = () => {
  const { stepsByNumber, steps, householdMembersIndex } = useSelector(
    (state: RootState) => state.screener
  )

  useInitializeForms()

  return steps.map((stepNumber: number) => {
    const step = stepsByNumber[stepNumber]
    return (
      <Step
        {...step}
        key={step.stepNumber}
        householdMembersIndex={householdMembersIndex}
      />
    )
  })
}

export default Steps
