import React from 'react'
import { StyledSubHeading } from 'Components/Screener/style'
import { Container } from 'styled-bootstrap-grid'
import { Row } from 'styled-bootstrap-grid'

import { ITableDisplay } from '..'

import TableDisplay from './ReviewSectionTable'
import { StyledTableGridItem } from './style'

/**
 * Table component used in Review Sections
 *
 * @param {{tableDisplays:Array, isEditing:boolean}} param0
 * @returns {JSX}
 */
const ReviewSectionsTables = ({
  tableDisplays,
}: {
  tableDisplays: ITableDisplay[]
}) => (
  <Container>
    {tableDisplays?.map(({ name, ...table }, i) => (
      <Row container key={i}>
        <StyledSubHeading
          black
          fontSize='1rem'
          fontWeight={400}
          marginBottom='0.625rem'
        >
          {name}
        </StyledSubHeading>
        <StyledTableGridItem item xs={12}>
          <TableDisplay {...table} />
        </StyledTableGridItem>
      </Row>
    ))}
  </Container>
)

export default ReviewSectionsTables
