import { formValueSelector, getFormValues } from 'redux-form'

import { SCREENER_FORM_NAME } from '../Shared/constants'

export const screenerSelector = formValueSelector(SCREENER_FORM_NAME)
export const screenerFormValuesSelector = getFormValues(SCREENER_FORM_NAME)

const getValue = (values, dictionary, categoryIndex) => {
  if (values) {
    const key = Array.isArray(values)
      ? values[categoryIndex]
      : Object.keys(values)[categoryIndex]
    return dictionary[key]
  } else {
    return null
  }
}

export const additionalIncomeType = (state, type) => {
  const { categoryIndex, filteredHouseholdMembers, householdMembersIndex } =
    state.screener
  const dictionary = {
    alimony: 'alimony',
    child_support: 'child support',
    pension: 'pension',
    ssd_ssi: 'SSD/SSI',
    unemployment_insurance: 'unemployment insurance',
    va_benefits: 'VA benefits',
    workers_compensation: 'workers compensation',
    other: 'other',
  }
  if (type === 'client') {
    const values = screenerSelector(state, 'client.additional_income_sources')
    return getValue(values, dictionary, categoryIndex)
  } else {
    const currentMember = filteredHouseholdMembers
      ? filteredHouseholdMembers[householdMembersIndex]
      : null
    if (currentMember) {
      const isClient = currentMember.index === null
      const propertyName = isClient
        ? 'client.additional_income_sources'
        : `household.household_members[${currentMember.index}].additional_income_sources`
      const values = screenerSelector(state, propertyName)
      return getValue(values, dictionary, categoryIndex)
    } else {
      return null
    }
  }
}

export const getClientName = (state) => {
  const { firstName, nickName } = state.client
  return firstName || nickName
}

export const getClientSchool = (state) => {
  const { school } = state.client
  return school
}

export const householdMemberName = (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
  if (householdMembers && householdMembers[householdMembersIndex]) {
    return householdMembers[householdMembersIndex].first_name
  } else {
    return null
  }
}

const householdMemberNameSuggester = (suggestion) => (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = selectHouseholdMembers(
    filteredHouseholdMembers,
    state
  )

  if (householdMembers) {
    const member = householdMembers[householdMembersIndex]

    if (member) {
      return suggestion(member)
    }

    return null
  }

  return null
}

export const householdMemberNamePossessive = householdMemberNameSuggester(
  (member) => (member.index !== null ? `${member.first_name}'s` : 'your')
)

export const householdMemberNameNonPossessive = householdMemberNameSuggester(
  (member) => (member.index !== null ? `${member.first_name}` : 'you')
)

export const doesUser = (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
  if (householdMembers) {
    const member = householdMembers[householdMembersIndex]
    if (member) {
      return member.index !== null ? `does ${member.first_name}` : 'do you'
    } else {
      return null
    }
  } else {
    return null
  }
}

export const hasUser = (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
  if (householdMembers) {
    const member = householdMembers[householdMembersIndex]
    if (member) {
      return member.index !== null ? `has ${member.first_name}` : 'have you'
    } else {
      return null
    }
  } else {
    return null
  }
}

export const isUser = (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
  if (householdMembers) {
    const member = householdMembers[householdMembersIndex]
    if (member) {
      return member.index !== null ? `is ${member.first_name}` : 'are you'
    } else {
      return null
    }
  } else {
    return null
  }
}

const payFrequenciesDictionary = {
  weekly: 'weekly',
  biWeekly: 'bi-weekly',
  semiMonthly: 'semi-monthly',
  monthly: 'monthly',
}

export const clientPayFrequency = (state, ordinalNumber) => {
  const key = `client.${ordinalNumber}_job_pay_frequency`
  const value = screenerSelector(state, key)
  return payFrequenciesDictionary[value]
}

export const householdMemberPayFrequency = (state, ordinalNumber) => {
  const { filteredHouseholdMembers, householdMembersIndex } = state.screener
  const householdMember = filteredHouseholdMembers
    ? filteredHouseholdMembers[householdMembersIndex]
    : null

  if (householdMember) {
    const { index } = householdMember
    const isClient = index === null

    const payFrequencyKey = isClient
      ? `client.${ordinalNumber}_job_pay_frequency`
      : `${ordinalNumber}_job_pay_frequency`

    const payFrequencyValue = isClient
      ? screenerSelector(state, payFrequencyKey)
      : screenerSelector(
          state,
          `household.household_members[${index}]${payFrequencyKey}]`
        )

    if (payFrequencyValue) {
      return payFrequenciesDictionary[payFrequencyValue]
    } else {
      return null
    }
  } else {
    return null
  }
}

export const yourTheir = (state) => {
  const { householdMembersIndex, filteredHouseholdMembers } = state.screener
  const householdMembers = filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
  if (householdMembers) {
    const member = householdMembers[householdMembersIndex]
    if (member) {
      return member.index !== null ? 'their' : 'your'
    } else {
      return null
    }
  } else {
    return null
  }
}
function selectHouseholdMembers(filteredHouseholdMembers, state) {
  return filteredHouseholdMembers
    ? filteredHouseholdMembers
    : screenerSelector(state, 'household.household_members')
}
