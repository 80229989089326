import { withStyles } from '@material-ui/core'
import { Button } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'

export const StyledEditButtonWrapper = withStyles({
  root: {
    padding: '0.5rem 1rem',
    color: theme.palette.primary.darken10,
    fontSize: '.75rem',
    textTransform: 'capitalize',
  },
})(Button)

export const StyledCancelButtonWrapper = withStyles({
  root: {
    padding: '0.5rem 1rem',
    color: theme.palette.color.black,
    fontSize: '.75rem',
    textTransform: 'capitalize',
    marginInlineEnd: '1rem',
    backgroundColor: theme.palette.secondary.lightGrey,
  },
})(Button)

StyledEditButtonWrapper.displayName = 'StyledEditButtonWrapper'
StyledCancelButtonWrapper.displayName = 'StyledCancelButtonWrapper'
