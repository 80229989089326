import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { change as reduxFormChange } from 'redux-form'
import { screenerSelector } from 'Selectors'
import { SCREENER_FORM_NAME } from 'Shared/constants'

export const needsField = (fieldValues, fieldNameIndex) =>
  !fieldValues || (fieldValues && fieldNameIndex >= fieldValues.length)

export const needsCategoryField = (fieldValues, fieldNameIndex, fieldIndex) =>
  !fieldValues?.[fieldNameIndex] ||
  (fieldValues[fieldIndex] && fieldNameIndex >= fieldValues[fieldIndex].length)

export const useScreener = () => useSelector((state) => state.screener)

export const useHasHouseholdMembers = () =>
  useSelector((state) =>
    screenerSelector(state, 'client.has_household_members')
  )

export const useCategoryIterator = () => {
  const { currentStep, stepsByNumber } = useScreener()
  const {
    question: { categoryIterator },
  } = stepsByNumber[currentStep]
  return categoryIterator
}

export const useHouseholdMembersIndex = () =>
  useScreener()?.householdMembersIndex

export const useCategoryIndex = () => useScreener()?.categoryIndex

export const useFieldIndex = () => {
  const householdMembersIndex = useHouseholdMembersIndex()
  const categoryIndex = useCategoryIndex()
  const categoryIterator = useCategoryIterator()

  return categoryIterator ? categoryIndex : householdMembersIndex
}

export const useFieldNameIndex = () => {
  const fieldIndex = useFieldIndex()
  const householdMembersIndex = useHouseholdMembersIndex()
  const hasHouseholdMembers = useHasHouseholdMembers()

  return hasHouseholdMembers ? householdMembersIndex : fieldIndex
}

export const hasNoFields = (
  categoryIterator,
  fieldValues,
  fieldNameIndex,
  fieldIndex
) =>
  categoryIterator
    ? needsCategoryField(fieldValues, fieldNameIndex, fieldIndex)
    : needsField(fieldValues, fieldNameIndex)

export const getInsertTargetName = (
  hasHouseholdMembers,
  name,
  householdMembersIndex
) => (hasHouseholdMembers ? `${name}[${householdMembersIndex}]` : name)

export const insertField = (
  hasHouseholdMembers,
  householdMembersIndex,
  fieldIndex,
  name,
  categoryIterator,
  fieldNameIndex,
  fieldValues,
  value,
  reduxFormArrayInsert
) => {
  const noFields = hasNoFields(
    categoryIterator,
    fieldValues,
    fieldNameIndex,
    fieldIndex
  )

  if (noFields) {
    if (categoryIterator) {
      reduxFormArrayInsert(
        SCREENER_FORM_NAME,
        getInsertTargetName(hasHouseholdMembers, name, householdMembersIndex),
        fieldNameIndex,
        value
      )
    } else {
      reduxFormArrayInsert(SCREENER_FORM_NAME, name, fieldNameIndex, value)
    }
  }
}

export const useFieldsEffect = (
  householdMembersIndex,
  name,
  categoryIterator,
  fieldValues,
  value,
  reduxFormArrayInsert
) => {
  const hasHouseholdMembers = useHasHouseholdMembers()
  const fieldIndex = useFieldIndex()
  const fieldNameIndex = useFieldNameIndex()

  useEffect(() => {
    insertField(
      hasHouseholdMembers,
      householdMembersIndex,
      fieldIndex,
      name,
      categoryIterator,
      fieldNameIndex,
      fieldValues,
      value,
      reduxFormArrayInsert
    )
  }, [
    fieldIndex,
    householdMembersIndex,
    name,
    categoryIterator,
    hasHouseholdMembers,
  ])
}

/**
 *
 * @param {*} props
 * @param {*} value
 */
export const useFields = (props, value = '') => {
  const {
    callbacks: { reduxFormArrayInsert },
    name,
    fieldValues,
    householdMembersIndex,
    categoryIterator,
  } = props

  useFieldsEffect(
    householdMembersIndex,
    name,
    categoryIterator,
    fieldValues,
    value,
    reduxFormArrayInsert
  )
}

export const useFieldNameMemo = (fields, categoryIterator) => {
  const fieldIndex = useFieldIndex()
  const hasHouseholdMembers = useHasHouseholdMembers()

  return useMemo(
    () =>
      fields.map((fieldName) =>
        categoryIterator && hasHouseholdMembers
          ? `${fieldName}[${fieldIndex}]`
          : fieldName
      ),
    [fields, categoryIterator, fieldIndex, hasHouseholdMembers]
  )
}

/**
 * Given the redux-form fields for a form array, this hook returns
 * the current active fieldname
 *
 * @param {Array} fields the redux-form special array of fields
 * @returns {String} the current fieldname
 */
export const useFieldName = (fields) => {
  const categoryIterator = useCategoryIterator()
  const fieldNameIndex = useFieldNameIndex()
  const fieldNames = useFieldNameMemo(fields, categoryIterator)

  return fieldNames[fieldNameIndex]
}

/**
 * Reducer for turning a household single value array into checkbox group data
 *
 * @param {*} accumulator accumulator
 * @param {*} currentValue current array value
 * @param {*} currentIndex current array index
 * @param {*} array original array
 * @returns {Object} The checkbox group formatted data
 */
const householdReducer = (accumulator, currentValue, currentIndex, array) => {
  let valueArray = accumulator[currentValue] ?? new Array(array.length)
  valueArray[currentIndex] = true
  accumulator[currentValue] = valueArray
  return accumulator
}

export const nameSelector = createSelector(
  [
    (state, name) => screenerSelector(state, name),
    (state, name) => screenerSelector(state, `client.${name}`),
    (state, name, isHousehold) => isHousehold,
  ],
  (nameSelector, clientNameSelector, isHousehold) =>
    isHousehold ? nameSelector : (nameSelector ?? clientNameSelector)
)

/**
 * Using this hook inside a checkbox form component will check the current
 * source field values. If the values it finds are for a single answer, it
 * will migrate the values to that of a checkbox group.
 *
 * We know that if we encounter an array of values when working with a checkbox
 * group, 1.) The checkboxes won't show the existing values 2.) while client
 * can re-write the data automatically, household members' checkbox groups will
 * completely break when pointed to an Array and no new answers can be given.
 *
 * If we encounter a string, we know that we are in a similar situation. In the
 * case of a string, we just migrate it into an object with 1 property
 * containing the checkbox state.
 *
 * @param {Object} props properties from the Checkbox component
 * @returns {undefined}
 */
export const useMigrateFromSingleValue = (props) => {
  const dispatch = useDispatch()

  const { name, checkboxType } = props

  const isHousehold = name?.split('.')[0] === 'household'

  const rawData = useSelector((state) => nameSelector(state, name, isHousehold))

  if (checkboxType !== 'householdMemberMultiAnswer') return

  const rawDataIsArray = Array.isArray(rawData)

  const rawDataIsString = typeof rawData === 'string'

  if (isHousehold && rawDataIsArray) {
    const migratedData = rawData.reduce(householdReducer, {})

    dispatch(reduxFormChange(SCREENER_FORM_NAME, name, migratedData))
  } else if (!isHousehold && rawDataIsString) {
    const migratedData = { [rawData]: [true] }

    dispatch(reduxFormChange(SCREENER_FORM_NAME, name, migratedData))
  }
}
