import { gql } from '__generated__/gql'

export const VALIDATE_UNVERIFIED_CLIENT = gql(`
  mutation validateUnverifiedClient(
    $email: String
    $phoneNumber: String
    $zipCode: String!
  ) {
    validateUnverifiedClient(
      email: $email
      phoneNumber: $phoneNumber
      zipCode: $zipCode
    ) {
      errors
      success
    }
 }`)
