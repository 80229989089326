import React from 'react'
import singleStopLogo from 'Shared/Icons/singleStopLogo'

type SingleStopLogoLinkProps = {
  width?: string
  margin?: string
}

const SingleStopLogoLink = ({ width, margin }: SingleStopLogoLinkProps) => {
  return (
    <div>
      <svg
        style={{
          width: `${width || '10rem'}`,
          margin: `${margin || '0 1.2em 0 0'}`,
        }}
        viewBox='0 0 166.729 45.54'
        aria-hidden='true'
      >
        <title id={'single-stop-logo'}>Single Stop - Back to home page</title>
        {singleStopLogo()}
      </svg>
    </div>
  )
}

export default SingleStopLogoLink
