import React from 'react'

const LinkIconPath = (
  <>
    <path
      d='M5.99998 11.3346H4.66665C2.8257 11.3346 1.33331 9.84225 1.33331 8.0013C1.33331 6.16035 2.8257 4.66797 4.66665 4.66797H5.99998M9.99998 11.3346H11.3333C13.1743 11.3346 14.6666 9.84225 14.6666 8.0013C14.6666 6.16035 13.1743 4.66797 11.3333 4.66797H9.99998M4.66665 8.0013L11.3333 8.0013'
      stroke='#151515'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </>
)

export default LinkIconPath
