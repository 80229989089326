import React, { useContext, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { TableContext } from 'Components/Shared/ReduxForm/CheckboxList'
import { StyledFormControlLabel } from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'
import { Field } from 'redux-form'

const RenderCheckbox = ({ input, label }) => {
  const setTable = useContext(TableContext)[1]

  const handleOnFocus = () => {
    setTable((table) => ({ ...table, elevation: 2, focusInput: input.name }))
  }

  const handleOnBlur = () => {
    setTable((table) => ({ ...table, elevation: 0, focusInput: null }))
  }

  const handleOnClick = () => {
    setTable((table) => ({ ...table, focusInput: null }))
  }

  useEffect(() => {
    const box = document.getElementsByClassName('health-insurance__checkbox')
    for (let i = 0; i < box.length; i++) {
      box[i].querySelectorAll('span')?.[3]?.setAttribute('aria-hidden', 'true')
    }
  })

  return (
    <StyledFormControlLabel
      className='health-insurance__checkbox'
      label={label}
      data-testid={input.name}
      control={
        <Checkbox
          checked={!!input.value}
          onChange={input.onChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onClick={handleOnClick}
          color='primary'
          inputProps={{
            'aria-label': `Checkbox ${label}`,
          }}
        />
      }
      labelPlacement='start'
    />
  )
}

const CheckboxRenderField = ({ name, label }) => (
  <Field label={label} name={name} component={RenderCheckbox} />
)

export default CheckboxRenderField
