import SideSvg from 'Components/LandingPage/Assets/sideSvg.svg'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledParentSince = styled.section`
  ${u.dFlex}
  background-image: url(${SideSvg});
  background-repeat: no-repeat;
  background-position-x: right;
  ${u.pRelative}
  z-index: 1;
  padding-top: 2rem;
  ${media.smaller`
      padding-top: 1rem;
  `};
`

export const StyledParentText = styled.div`
  ${u.wd100}
  ${u.dFlex}
${u.hi100}
  ${u.flexCol}
  ${u.justifyContentCenter}
`

export const StyledParentDivBtn = styled.div`
  ${u.wd100}
  margin: 10px 0;
`

export const StyledParentImg = styled.div`
  ${u.alignText}
`

export const StyledElmImg = styled.img`
  width: 100%;
`
StyledParentSince.displayName = 'StyledParentSince'
StyledParentText.displayName = 'StyledParentText'
StyledParentDivBtn.displayName = 'StyledParentDivBtn'
StyledParentImg.displayName = 'StyledParentImg'
StyledElmImg.displayName = 'StyledElmImg'
