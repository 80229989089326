import axios from 'axios'
import { selectResponses } from 'Components/Outcome/selectors'
import { deleteResponse } from 'Reducers/outcome'
import { API_BASE } from 'Shared/constants'

/**
 * This thunk is for the following scenario:
 * 1. user Selects 1 or more benefits
 * 2. user populates responses for these benefits
 * 3. User returns to /outcome/:id?select-benefits via the back button
 * 4. User deselects one or more of the benefits she populated responses for
 *
 * In this case we need to remove the "orphan" responses
 * @param followupIds string[]
 */
export const pruneResponses =
  ({ followupIds }: { followupIds: string[] }) =>
  (dispatch, getState) => {
    const responseIds = Object.keys(selectResponses(getState()))
    const orphanIds = responseIds.filter((id) => !followupIds.includes(id))
    orphanIds.forEach((id) => {
      dispatch(deleteResponse(id))
    })
  }

export const submitFollowups = () => (dispatch, getState) => {
  const responses = selectResponses(getState())
  return axios.post(
    `${API_BASE}/service_case_followups/complete_service_case_followups`,
    { followups: Object.values(responses) }
  )
}

export async function unsubscribeClient(
  token: string | undefined,
  type?: string
) {
  const response = await fetch(`${API_BASE}/clients/unsubscribe_client`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      type,
    }),
  })
  if (!response.ok) {
    throw new Error('Failed to unsubscribe from outcome notifications.')
  } else {
    return response.json()
  }
}
