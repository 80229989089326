import React from 'react'
import { StyledMainHeading } from 'Components/Screener/style'

/**
 * Review Page Header
 */
const ReviewHeading = () => (
  <StyledMainHeading $review={true}>Review Your Responses</StyledMainHeading>
)

export default ReviewHeading
