import React, { useEffect } from 'react'
import { createTimeModel, TimeModel, useTimeModel } from 'react-compound-timer'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { ME } from 'Components/Auth/Hooks/gql'
import useLogOut from 'Components/Auth/Hooks/useLogOut'
import ConfirmOrCancel from 'Components/CaseManagement/Modals/Shared/ConfirmOrCancel'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import { StyledParagraph } from 'Components/CaseManagement/Modals/style'
import { IDLE_TIME_MODAL_COUNTDOWN } from 'Shared/constants'

const timeFormat = (value: number): string =>
  value < 10 ? `0${value}` : `${value}`

interface CountdownProps {
  time: TimeModel
}

const Countdown: React.FC<CountdownProps> = ({ time }) => {
  const { m: minutes, s: seconds } = useTimeModel(time).value

  return (
    <StyledParagraph>
      You will be logged out in {minutes}:{timeFormat(seconds)}
    </StyledParagraph>
  )
}

interface IdleTimeoutModalProps {
  open: boolean
  handleClose: () => void
  reset: () => void
  redirectUrl: string
}

const IdleTimeoutModal: React.FC<IdleTimeoutModalProps> = ({
  open,
  handleClose,
  reset,
  redirectUrl,
}) => {
  const [handleMeQuery] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
  })

  const { clearUserData } = useLogOut()
  const navigate = useNavigate()

  const handleLogOut = (timer: TimeModel) => {
    logOut()
    timer.stop()
  }

  const logOut = () => {
    clearUserData()
    navigate(redirectUrl)
    handleClose()
  }

  const handleStayLoggedIn = (timer: TimeModel) => {
    handleMeQuery()
    reset()
    handleClose()
    timer.stop()
  }

  const time = createTimeModel({
    initialTime: IDLE_TIME_MODAL_COUNTDOWN,
    direction: 'backward',
    startImmediately: false,
    checkpoints: [
      {
        time: 0,
        callback: () => {
          logOut()
        },
      },
    ],
    roundUnit: 's',
  })

  useEffect(() => {
    if (open) {
      time.start()
    }
  }, [open])

  return (
    <ModalTemplate
      open={open}
      heading={'Inactive Session'}
      description={'You are about to be logged out due to inactivity'}
    >
      <Countdown time={time} />
      <ConfirmOrCancel
        handleOnConfirm={() => handleStayLoggedIn(time)}
        handleOnCancel={() => handleLogOut(time)}
        confirmLabel={'Stay Logged In'}
        cancelLabel='Log Out'
      />
    </ModalTemplate>
  )
}

export default IdleTimeoutModal
