import React from 'react'

import { StyledContainer, StyledWrapper } from './style'

const NextStepsLoading = () => (
  <StyledContainer>
    <StyledWrapper>loading...</StyledWrapper>
  </StyledContainer>
)

export default NextStepsLoading
