import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'

export const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 6),
    textAlign: 'center',
    margin: '0 0 3rem 0',
  },
}))(MuiDialogContent)
