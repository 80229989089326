import React from 'react'
import { FieldArray } from 'redux-form'

import { useFieldName } from './hooks'
import MilitaryDateFullSelector from './MilitaryDateFullSelector'

interface MilitaryDateSelectorFieldArrayRenderFieldProps {
  fields: FieldArray
}
const MilitaryDateSelectorFieldArrayRenderField = ({
  fields,
  ...props
}: MilitaryDateSelectorFieldArrayRenderFieldProps) => {
  const fieldName = useFieldName(fields)

  return <MilitaryDateFullSelector {...props} input={{ name: fieldName }} />
}

export default MilitaryDateSelectorFieldArrayRenderField
