import { useLocation, useNavigate } from 'react-router-dom'
import {
  selectEditDetailRoute,
  selectShouldLoopBackwards,
} from 'Components/Outcome/selectors'
import { decrementOutcomeIndex } from 'Reducers/outcome'

const useHandleBackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()

  /**
   * This callback is what allows us to loop backwards over multiple followups
   */
  return () => (dispatch, getState) => {
    if (selectShouldLoopBackwards(getState())) {
      dispatch(decrementOutcomeIndex())
      const detailRoute = selectEditDetailRoute(getState())
      navigate(`../${detailRoute}`, {
        state: { from: location.pathname },
      })
    } else {
      navigate(`../select-benefits`, {
        state: { from: location.pathname, backButtonClicked: true },
      })
    }
  }
}

export default useHandleBackButton
