import React from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import FormHelperText from '@material-ui/core/FormHelperText'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { updateClient } from 'Actions/client'
import CustomQuestionsField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { CUSTOM_FIELDS_MUTATION } from 'Components/Screener/Hooks/gql'
import { mapResponses } from 'Components/Screener/Hooks/helpers'
import {
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import { FORM_ERROR } from 'final-form'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { getClientId } from 'Shared/helpers'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Row } from 'styled-bootstrap-grid'

import { StyledForm } from './style'

const CustomQuestionsForm = ({
  customFields,
  initialValues,
  customQuestionsHeader,
  customQuestionsText,
  loadingQuestions,
}) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [saveCustomFields] = useMutation(CUSTOM_FIELDS_MUTATION)

  const clientId = getClientId()

  const onSubmit = (formData) => {
    const responses = mapResponses(formData)
    return saveCustomFields({ variables: { clientId, responses } })
      .then(() => {
        dispatch(updateClient({ screeningSection: 'SCREENER' }))
        navigate('/screening')
      })
      .catch(() => {
        return { [FORM_ERROR]: ERROR_GENERIC_MESSAGE }
      })
  }

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })
  const preventEnterSubmit = (e) => {
    if (e.key === 'Enter' && e.target?.id !== 'continue-button')
      e.preventDefault() //prevent enter all fields except continue button
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitError, submitting }) => (
        <MuiThemeProvider theme={ssTheme}>
          <StyledForm
            onKeyDown={preventEnterSubmit}
            onSubmit={handleSubmit}
            id={'custom-questions'}
            margin='0'
          >
            <StyledMainHeading
              id='mainContent'
              tabIndex='-1'
              $isOverWrap={true}
            >
              {customQuestionsHeader}
            </StyledMainHeading>
            <StyledParagraph
              fontWeight='500'
              marginBottom='1.375rem'
              color='#545454'
              fontSize='1.15rem'
              dangerouslySetInnerHTML={{ __html: customQuestionsText }}
            ></StyledParagraph>
            {customFields.map((field, i) => (
              <StyledMuiTextFieldWrapper key={i} $mbottom='3rem'>
                <CustomQuestionsField
                  {...field}
                  name={`custom_field_${field.id}`}
                />
              </StyledMuiTextFieldWrapper>
            ))}
            {submitError && (
              <FormHelperText error={true}>{submitError}</FormHelperText>
            )}
            {!isMobile && (
              <Row>
                <ColDynamicCtaButton
                  type='submit'
                  data-testid='continue-button'
                  disabled={submitting || loadingQuestions}
                  width='fit-content'
                >
                  Submit
                </ColDynamicCtaButton>
              </Row>
            )}
            {isMobile && (
              <StyledParentButtonsActionsInMobile>
                <ColDynamicCtaButton
                  type='submit'
                  data-testid='continue-button'
                  disabled={submitting || loadingQuestions}
                  width='100%'
                >
                  Submit
                </ColDynamicCtaButton>
              </StyledParentButtonsActionsInMobile>
            )}
          </StyledForm>
        </MuiThemeProvider>
      )}
    />
  )
}

export default CustomQuestionsForm
