import styled from 'styled-components'

export const StyledContainer = styled.div<{
  display: string
  overflow: string
}>`
  position: relative; // relative to <Menu />
  display: ${(props) => (props.display ? props.display : '')};
  -webkit-overflow-scrolling: touch;
  overflow: ${(props) => props.overflow};
`
