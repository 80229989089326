import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MediaQuery from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { getOnboardingSection } from 'Actions/client'
import { loadScreener } from 'Actions/screener'
import { useDynamicRegistrationUrl } from 'Components/App/Hooks'
import { useRoutes } from 'Components/Client/ClientHooks/useRouteToOnboarding'

import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
  TABLET_MEDIA_QUERY,
} from '../../Shared/constants'

import MobileScreener from './MobileScreener'
import TabletAndDesktopScreener from './TabletAndDesktopScreener'

const Screener = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { screenerCompleted, editing } = useSelector((state) => state.client)
  const currentStep = useSelector(
    (state) => state.screener.stepsByNumber[state.screener.currentStep]
  )

  const { handleRouteToPreScreener } = useRoutes()

  const registrationUrl = useDynamicRegistrationUrl()

  useEffect(() => {
    if (!editing) {
      dispatch(getOnboardingSection()).then((section) => {
        if (section === 'PRESCREENER') {
          handleRouteToPreScreener(registrationUrl)
        } else {
          dispatch(loadScreener())
        }
      })
    }
  }, [])

  useEffect(() => {
    if (screenerCompleted && !editing) {
      navigate('/completed-screener')
    }
  }, [navigate, screenerCompleted, editing])

  if (!currentStep) return null

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        <MobileScreener />
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <MediaQuery query={TABLET_MEDIA_QUERY}>
          {(matches) => {
            const device = matches ? 'tablet' : 'desktop'
            return <TabletAndDesktopScreener device={device} />
          }}
        </MediaQuery>
      </MediaQuery>
    </>
  )
}

export default Screener
