import React from 'react'
import { outlineMailIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from '../LocationInfo/LocationInfo'

import { StyledEmailATag } from './style'

interface EmailAddressProps {
  emailAddress: string
}

const EmailAddress = ({ emailAddress }: EmailAddressProps) => {
  return (
    <LocationInfo
      content={
        <>
          <StyledEmailATag href={`mailto:${emailAddress}`}>
            {emailAddress}
          </StyledEmailATag>
        </>
      }
      screenReaderLabel={'EmailAddress'}
      icon={outlineMailIconPath}
    />
  )
}

export default EmailAddress
