import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { updateReviewSectionIndex } from 'Actions/eligibility'
import { useReviewSections } from 'Components/Review/hooks'
import ReviewHeading from 'Components/Review/ReviewHeading'
import ReviewNavigation from 'Components/Review/ReviewNavigation'
import ReviewNavigationContinueButton from 'Components/Review/ReviewNavigation/ReviewNavigationContinueButton'
import {
  NavParent,
  NavSubParent,
  StyledReviewButtonParent,
} from 'Components/Review/ReviewNavigation/style'
import ReviewSections from 'Components/Review/ReviewSection'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import useInitializeForms from 'Components/Shared/Hooks/useInitializeForms'
import LoadingIndicator from 'Components/Shared/LoadingIndicator/LoadingIndicator'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Container } from 'styled-bootstrap-grid'

import useRedirectToIfScreeningIncomplete from './hooks/useRedirectToIfScreeningIncomplete'
import useReviewSectionRefetch from './hooks/useReviewSectionRefetch'
import useUpdateScreeningType from './hooks/useUpdateScreeningType'

/**
 * Review Page <p>This page displays all the client's
 * responses to the screener.</p>
 */
const Review = () => {
  const { sections, refetch, legacy, loading, error } = useReviewSections()
  const { reviewSectionIndex } = useSelector((state) => state.eligibility)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  useRedirectToIfScreeningIncomplete('/screening')
  useInitializeForms()
  useUpdateScreeningType()
  useReviewSectionRefetch(refetch)

  if (loading) {
    return <LoadingIndicator show={true} />
  }
  if (!loading && !error) {
    return (
      <>
        <HeaderWithContainer containerDisplay='contents'>
          <NavParent>
            <NavSubParent>
              <Container>
                <ReviewHeading />
                <ReviewNavigation
                  sections={sections}
                  onSectionClick={(index) =>
                    dispatch(updateReviewSectionIndex(index))
                  }
                  onContinueClick={() => navigate('/completed-screener')}
                  legacy={legacy}
                />
              </Container>
            </NavSubParent>
          </NavParent>
          <ReviewSections
            legacy={legacy}
            sections={sections}
            sectionIndex={reviewSectionIndex}
            onScroll={() => dispatch(updateReviewSectionIndex(-1))}
          />
        </HeaderWithContainer>
        {isMobile && !legacy && (
          <StyledReviewButtonParent>
            <ReviewNavigationContinueButton
              onContinueClick={() => navigate('/completed-screener')}
            />
          </StyledReviewButtonParent>
        )}
      </>
    )
  } else {
    return null
  }
}

export default Review
