import React from 'react'
import { Grid } from '@material-ui/core'

import { useReviewSectionNavigation } from '../hooks'

import ReviewSection from './ReviewSection'
import { StyledSectionsContainer } from './style'

interface ReviewSectionsProps {
  sections?: Record<string, string>[]
  sectionIndex?: number
  onScroll?: React.UIEventHandler<HTMLDivElement>
  legacy?: boolean
}

const ReviewSections = ({
  sections,
  sectionIndex,
  onScroll,
  legacy,
}: ReviewSectionsProps) => {
  const [container, grid] = useReviewSectionNavigation(sectionIndex)
  return sections ? (
    <div style={{ backgroundColor: '#f1f1f1' }}>
      <StyledSectionsContainer
        data-testid='sections'
        ref={container}
        onScroll={onScroll}
      >
        <Grid ref={grid}>
          {sections.map((section, i) => (
            <ReviewSection key={i} index={i} legacy={legacy} {...section} />
          ))}
        </Grid>
      </StyledSectionsContainer>
    </div>
  ) : (
    <></>
  )
}
export default ReviewSections
