import React from 'react'
import { useLocation } from 'react-router-dom'
import { StyledMainHeading, StyledParagraph } from 'Components/Screener/style'
import Error from 'Components/Shared/Error/Error'
import StyledDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import { StyledWrapper } from 'Pages/NextSteps/style'
import { API_BASE } from 'Shared/constants'
import singleStopLogo from 'Shared/Icons/singleStopLogo'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 500px;
  padding: 0 1rem;
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 2rem;
    text-align: center;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  > input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 40px;
  }
  > div {
    margin-top: 2rem;
  }

  > label {
    margin-bottom: 0.5rem;
    width: 100%;
  }
`

const StyledSvg = styled.svg`
  padding-top: 1rem;
  padding-left: 3rem;
`

const Unsubscribe = () => {
  const [error, setError] = React.useState<string | null>(null)
  const [unsubscribed, setUnsubscribed] = React.useState(false)
  const { search } = useLocation()
  const params = new URLSearchParams(search.split('?')[1])

  const email = params.get('email')
  const token = params.get('token')

  const toggleUnsubscribed = () => {
    setUnsubscribed((prevState) => !prevState)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(null)

    const res = await fetch(`${API_BASE}/clients/unsubscribe_client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        token,
      }),
    })

    if (!res.ok) {
      setError('We were unable to unsubscribe you. Please try again.')
    } else {
      toggleUnsubscribed()
    }
  }

  return (
    <main>
      <StyledSvg role='img' aria-labelledby='single-stop-logo'>
        <title id='single-stop-logo'>Single Stop Logo</title>
        {singleStopLogo()}
      </StyledSvg>

      <Container>
        {unsubscribed ? (
          <StyledWrapper>
            <StyledMainHeading>Unsubscribe</StyledMainHeading>
            <StyledParagraph>
              You've been unsubscribed from Single Stop email notifications.
            </StyledParagraph>
          </StyledWrapper>
        ) : (
          <StyledForm onSubmit={handleSubmit}>
            <StyledMainHeading>Unsubscribe</StyledMainHeading>
            <StyledParagraph>
              If you'd like to opt out of future email communications, confirm
              your email and click the link below.
            </StyledParagraph>
            <label htmlFor='emailInput'>Email</label>
            <input
              id='emailInput'
              type='email'
              defaultValue={email || ''}
              disabled
            />
            {error && <Error error={error} />}
            <div>
              <StyledDynamicCtaButton label='Unsubscribe' />
            </div>
          </StyledForm>
        )}
      </Container>
    </main>
  )
}

export default Unsubscribe
