import React from 'react'
import { connect } from 'react-redux'
import { handlePatchScreenerError } from 'Actions/screener'
import validators from 'Components/Shared/ReduxForm/validators/validators'
import {
  arrayInsert as reduxFormArrayInsert,
  arrayPush as reduxFormArrayPush,
  change as reduxFormChange,
} from 'redux-form'

import { completeScreener } from '../../Actions/client'
import {
  addHouseholdMemberIndex,
  assignToAllPersons,
  assignToFilteredHouseholdMembers,
  assignToHouseholdMembers,
  clearFields,
  clearFilteredHouseholdMembers,
  clearHousehold,
  filterHouseholdMembers,
  handleCategorySelectors,
  handleHouseholdMemberCategorySelectors,
  handleHouseholdMemberCheckboxes,
  handleHouseholdMemberExcludingClientCheckboxes,
  handleHouseholdMemberMultiAnswer,
  handleHouseholdMemberYesOrNoQuestions,
  handleNavigationButtonClick,
  incrementHouseholdMembersIndex,
  resetCategoryIndex,
  resetHouseholdMembersIndex,
  setFieldsTo,
  triggerArrayPush,
} from '../../Actions/screener'

import { componentMap } from './helpers'

let callbacks = {}
let question = null

const RenderQuestion = (props) => {
  ;({
    addHouseholdMemberIndex: callbacks.addHouseholdMemberIndex,
    assignToAllPersons: callbacks.assignToAllPersons,
    assignToFilteredHouseholdMembers:
      callbacks.assignToFilteredHouseholdMembers,
    assignToHouseholdMembers: callbacks.assignToHouseholdMembers,
    clearFilteredHouseholdMembers: callbacks.clearFilteredHouseholdMembers,
    completeScreener: callbacks.completeScreener,
    handleCategorySelectors: callbacks.handleCategorySelectors,
    handleHouseholdMemberCategorySelectors:
      callbacks.handleHouseholdMemberCategorySelectors,
    handleHouseholdMemberCheckboxes: callbacks.handleHouseholdMemberCheckboxes,
    handleHouseholdMemberExcludingClientCheckboxes:
      callbacks.handleHouseholdMemberExcludingClientCheckboxes,
    handleHouseholdMemberYesOrNoQuestions:
      callbacks.handleHouseholdMemberYesOrNoQuestions,
    filterHouseholdMembers: callbacks.filterHouseholdMembers,
    handleNavigationButtonClick: callbacks.handleNavigationButtonClick,
    handlePatchScreenerError: callbacks.handlePatchScreenerError,
    incrementHouseholdMembersIndex: callbacks.incrementHouseholdMembersIndex,
    question,
    reduxFormArrayInsert: callbacks.reduxFormArrayInsert,
    reduxFormArrayPush: callbacks.reduxFormArrayPush,
    reduxFormChange: callbacks.reduxFormChange,
    clearFields: callbacks.clearFields,
    setFieldsTo: callbacks.setFieldsTo,
    clearHousehold: callbacks.clearHousehold,
    resetHouseholdMembersIndex: callbacks.resetHouseholdMembersIndex,
    triggerArrayPush: callbacks.triggerArrayPush,
    resetCategoryIndex: callbacks.resetCategoryIndex,
    handleHouseholdMemberMultiAnswer:
      callbacks.handleHouseholdMemberMultiAnswer,
  } = props)

  const TagName = componentMap[question.component]

  return (
    <TagName
      {...props}
      {...question}
      callbacks={callbacks}
      validate={validators[question.component]}
    />
  )
}

const dispatchToProps = {
  addHouseholdMemberIndex,
  assignToAllPersons,
  assignToFilteredHouseholdMembers,
  assignToHouseholdMembers,
  clearFilteredHouseholdMembers,
  completeScreener,
  filterHouseholdMembers,
  handleCategorySelectors,
  handleHouseholdMemberCategorySelectors,
  handleHouseholdMemberCheckboxes,
  handleHouseholdMemberExcludingClientCheckboxes,
  handleHouseholdMemberYesOrNoQuestions,
  handleNavigationButtonClick,
  handlePatchScreenerError,
  incrementHouseholdMembersIndex,
  reduxFormArrayInsert,
  reduxFormArrayPush,
  reduxFormChange,
  clearFields,
  setFieldsTo,
  clearHousehold,
  resetHouseholdMembersIndex,
  triggerArrayPush,
  resetCategoryIndex,
  handleHouseholdMemberMultiAnswer,
}

export default connect(null, dispatchToProps)(RenderQuestion)
