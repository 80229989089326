import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

/**
 * Retain the 'Previous Action' so we know exactly when the user is saving:
 * the action will go from 'edit' to undefined.
 */
let previousAction: string | undefined

/**
 * Refetch review section data when the user is done editing. When that
 * happens, the action param will transition from 'edit' to undefined
 *
 * @param {function} refetch
 */
const useReviewSectionRefetch = (refetch: () => void) => {
  const { action } = useParams()

  useEffect(() => {
    if (previousAction === 'edit' && !action) {
      refetch()
    }
    previousAction = action
  }, [action, refetch])
}

export default useReviewSectionRefetch
