import { convertSnakeCaseToCapitalized } from 'Shared/helpers'

/**
 * Relationship table
 */
export const relationshipTypes = {
  SPOUSE_DOMESTIC_PARTNER: 'Spouse/Domestic Partner',
  GIRLFRIEND_BOYFRIEND: 'Girlfriend/Boyfriend',
  CHILD_STEPCHILD: 'Child/Stepchild',
  PARENT_STEPPARENT: 'Parent/Stepparent',
  SIBLING: 'Sibling',
  AUNT_UNCLE: 'Aunt/Uncle',
  GRANDPARENT: 'Grandparent',
  GRANDCHILD: 'Grandchild',
  NIECE_NEPHEW: 'Niece/Nephew',
  COUSIN: 'Cousin',
  FRIEND_ROOMMATE: 'Friend/Roommate',
  OTHER: 'Other',
}

/**
 *
 * @param {*} value
 */
export const yesNo = (value) => (value ? 'Yes' : 'No')

/**
 *
 * @param {*} value
 */
export const amount = (value) => value ?? 0

/**
 *
 * @param {*} value
 */
export const valueOrNa = (value) => value ?? 'n/a'

/**
 *
 * @param {Object} client
 */
export const allPersons = (client) =>
  [client].concat(getHousehold(client)?.members)

/**
 *
 * @param {Array} sourceArray
 * @param {Array} properties
 */
export const collectProperties = (sourceArray, properties) => {
  let propertyArrays = {}

  sourceArray.forEach((item) => {
    for (let prop of properties) {
      !propertyArrays[prop] && (propertyArrays[prop] = [])
      propertyArrays[prop].push(item[prop])
    }
  })

  return propertyArrays
}

/**
 *
 * @param {Object} client
 */
export const householdHasSavings = (client) =>
  allPersons(client).some((person) =>
    person?.financialAssets?.some(isFinancialAssetSavings)
  )

/**
 *
 * @param {String} type
 * @param {String} __typename
 */
export const householdMemberRelationship = (type, __typename) => {
  if (__typename === 'Client') {
    return 'Self'
  } else {
    return relationshipTypes[type] ?? type ?? 'N/A'
  }
}

/**
 *
 * @param {Object} client
 */
export const claimedHouseholdMembers = (client) => {
  const claimedHouseholdMembers = getHousehold(client)?.members?.filter(
    ({ claimedAsDependent }) => claimedAsDependent
  )
  return claimedHouseholdMembers?.length > 0
    ? claimedHouseholdMembers.map(({ fullName }) => fullName).join(', ')
    : 'No'
}

/**
 * Tests to see if a Financial Asset Object is Savings
 *
 * @param {Object} Financial Asset
 */
export const isFinancialAssetSavings = ({ source }) => source === 'Savings'

/**
 * Test if a person has additional income
 *
 * @param {Object} person
 */
export const isHouseholdMemberWithIncome = (person) =>
  person?.additionalIncomes?.length > 0

/**
 * Test if a person has savings
 *
 * @param {Object} person
 */
export const isHouseholdMemberWithSavings = (person) =>
  person?.financialAssets.some(isFinancialAssetSavings)

/**
 * Test if a person is employed
 *
 * @param {Object} person
 */
export const isWorkingHouseholdMember = (person) =>
  person?.employments?.length > 0

/**
 * Helper method to return household
 *
 * @param {Object} client
 */
export const getHousehold = (client) => client?.household

/**
 *
 * @param {Object} client
 */
export const isAnyoneWorking = (client) =>
  allPersons(client)?.map(isWorkingHouseholdMember).includes(true)

export const adjustedGrossIncome = (income) =>
  income
    ? income.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    : ''

export const taxFilingStatus = (status) =>
  status ? convertSnakeCaseToCapitalized(status) : ''
