import React, { MutableRefObject, useRef } from 'react'
import { useInView } from 'react-hook-inview'
import { StyledMessageElem } from 'Components/LandingPage/Styles/FeaturesText'
import { TimelineMax } from 'gsap'

const FeatureBrandedContent = ({ brandedCopy }: { brandedCopy: string }) => {
  const ElemRef: MutableRefObject<HTMLDivElement[] | never[]> = useRef([])
  let TextTl = null
  let entered = false
  const TweenText = () => {
    TextTl = new TimelineMax({
      defaults: {
        duration: 0.2,
      },
    })
    TextTl.set(ElemRef.current, { y: -20 }).staggerTo(
      ElemRef.current,
      0.6,
      { y: 0, opacity: 1 },
      0.3
    )
  }
  const [textRef] = useInView({
    threshold: 0.5,
    onEnter: () => {
      if (!entered) {
        TweenText()
        entered = true
      }
    },
  })
  return (
    <div ref={textRef}>
      <StyledMessageElem
        ref={(element) => {
          ElemRef.current[0] = element
        }}
        startingOpacity={'1'}
        multilineText={true}
        dangerouslySetInnerHTML={{ __html: brandedCopy }}
      />
    </div>
  )
}

export default FeatureBrandedContent
