import { GetNextStepsQuery } from '__generated__/graphql'
import { Dictionary } from 'lodash'
import _groupBy from 'lodash.groupby'
import { BenefitGuide } from 'Pages/NextSteps/NextStepsBenefitsGuides'
import { OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP } from 'Shared/constants'

export const mapAndGroupBenefitsGuides = (
  benefitGuidesData: GetNextStepsQuery
): Dictionary<BenefitGuide[]> => {
  const services = benefitGuidesData?.nextSteps?.map(({ service }) => ({
    id: service.id,
    name: service.name,
    categoryName: service.mainCategory.name,
    openEligibilityKey: service.mainCategory.openEligibilityKey,
    toolkitUrl: service.toolkitUrl,
  }))
  return _groupBy(services, 'openEligibilityKey')
}

export const sortBenefitCategories = (a: string, b: string): number => {
  if (
    OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[a] <
    OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[b]
  ) {
    return -1
  }
  if (
    OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[a] >
    OPEN_ELIGIBILITY_ID_TO_CATEGORY_NAME_MAP[b]
  ) {
    return 1
  }
  return 0
}

export const sortByKey =
  (key: string) =>
  (a: Record<string, unknown>, b: Record<string, unknown>): number => {
    if (a[key as keyof typeof a] > b[key as keyof typeof b]) {
      return 1
    }
    if (a[key as keyof typeof a] < b[key as keyof typeof b]) {
      return -1
    }
    return 0
  }
