import React from 'react'
import { StyledTab, StyledTabs } from 'Components/Screener/style'

type NextStepsBenefitsTabsProps = {
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => void
  value: number
}

const EligibilityBenefitsTabs = ({
  onChange,
  value,
}: NextStepsBenefitsTabsProps) => {
  return (
    <StyledTabs className='styledAppBar' onChange={onChange} value={value}>
      <StyledTab
        data-testid='tab_0'
        id='likelyEligible'
        label='Likely Eligible'
        tabIndex={0}
        role='tab'
        aria-controls='tab_0'
      />
      <StyledTab
        data-testid='tab_1'
        id='unlikelyEligible'
        label='Unlikely Eligible'
        tabIndex={0}
        role='tab'
        aria-controls='tab_1'
      />
      <StyledTab
        data-testid='tab_2'
        id='allAvailableBenefits'
        label='All Available Benefits'
        tabIndex={0}
        role='tab'
        aria-controls='tab_2'
      />
    </StyledTabs>
  )
}

export default EligibilityBenefitsTabs
