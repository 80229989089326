import { ReactElement } from 'react'

import care from './careIconPath'
import education from './educationIconPath'
import food from './foodIconPath'
import goods from './goodsIconPath'
import health from './healthIconPath'
import housing from './housingIconPath'
import immigration from './immigrationIconPath'
import legal from './legalIconPath'
import money from './moneyIconPath'
import taxes from './taxesIconPath'
import transportation from './transportationIconPath'
import veterans from './veteransIconPath'
import work from './workIconPath'

const categoryIconPathsMap: { [key: string]: ReactElement } = {
  '0': immigration, //Emergency
  '1': food,
  '2': housing,
  '3': goods,
  '4': transportation,
  '5': health,
  '6': money,
  '7': care,
  '8': education,
  '9': work,
  '10': legal,
  '11': veterans,
  '12': taxes,
}

export default categoryIconPathsMap
