import React, { ReactElement } from 'react'
import Dialog from '@material-ui/core/Dialog'
import LinkIcon from '@material-ui/icons/CallMade'
import CloseIcon from '@material-ui/icons/Close'
import {
  ModelContainer,
  ModelContainerCloseIcon,
  ModelContainerContent,
  ModelContainerContentDescription,
  ModelContainerContentLink,
  ModelContainerTitle,
} from 'Components/Shared/BenefitTooltipDialog/BenefitTooltipDialogForClientPortal/style'

export type BenefitTooltipDialogProps = {
  isTabletAndMobile: boolean
  handleClose: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void
  open: boolean
  name: string
  category: string
  categoryIcon: ReactElement
  toolkitUrl?: string
  description: string
}
const BenefitTooltipDialogForClientPortal = ({
  isTabletAndMobile,
  handleClose,
  open,
  name,
  category,
  categoryIcon,
  toolkitUrl,
  description,
}: BenefitTooltipDialogProps) => (
  <Dialog
    fullScreen={isTabletAndMobile}
    style={{
      top: isTabletAndMobile ? 'auto' : 0,
      bottom: isTabletAndMobile ? '-1.5rem' : 0,
    }}
    className='dialogInDesktop'
    onClose={handleClose}
    open={open}
  >
    <ModelContainer className='modelContainer'>
      <ModelContainerTitle className='modelContainer__title' id={name}>
        {name}
      </ModelContainerTitle>
      <ModelContainerContent className='modelContainer__content'>
        {toolkitUrl && (
          <ModelContainerContentLink href={toolkitUrl} target='_blank'>
            View benefit guide{' '}
            <span>
              <LinkIcon />
            </span>
          </ModelContainerContentLink>
        )}
        <ModelContainerContentDescription className='modelContainer__content__description'>
          {description}
        </ModelContainerContentDescription>
      </ModelContainerContent>
      <ModelContainerCloseIcon
        className='modelContainer__closeIcon'
        onClick={handleClose}
      >
        <CloseIcon />
      </ModelContainerCloseIcon>
    </ModelContainer>
  </Dialog>
)

export default BenefitTooltipDialogForClientPortal
