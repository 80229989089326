import { combineReducers } from '@reduxjs/toolkit'
import eligibility from 'Reducers/eligibility'

import client from './client'
import filters from './filters'
import form from './form'
import geolocation from './geolocation'
import locations from './locations'
import myBenefits from './myBenefitsSlice'
import outcome from './outcome'
import preScreener from './preScreener'
import screener from './screener'
import ui from './uiSlice'

const appReducer = combineReducers({
  locations,
  ui,
  client,
  geolocation,
  filters,
  preScreener,
  screener,
  eligibility,
  form,
  myBenefits,
  outcome,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_CLIENT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
