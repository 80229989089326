import { gql } from '__generated__/gql'

export const AGREE_TO_TERMS = gql(`
  mutation agreeToTerms($clientId: ID!, $agreedToTerms: Boolean!) {
    agreeToTerms(clientId: $clientId, agreedToTerms: $agreedToTerms) {
      client {
        id
      }
    }
 }`)
