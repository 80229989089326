import React from 'react'
import { withTheme } from 'styled-components'

import Icon from '../Icon/Icon'

import { CustomIconWrapper, CustomStyledActionButton } from './style'

interface ActionButtonProps {
  icon: React.JSX.Element
  label: string
  theme: {
    text: {
      alt: string
    }
  }
  callback: () => void
  key: string
  marginRight: string
  disabled: boolean
  customStyles?: React.CSSProperties
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}
const ActionButton = ({
  icon,
  label,
  theme,
  callback,
  key,
  marginRight,
  disabled,
  onKeyDown,
}: ActionButtonProps) => {
  const { alt: fill } = theme.text

  return (
    <CustomStyledActionButton
      disabled={disabled}
      onClick={() => callback && callback()}
      key={key}
      marginRight={marginRight}
      aria-label={`${label}`}
      tabIndex={0}
      onKeyDown={
        onKeyDown
          ? (e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter' || e.key === ' ') {
                callback()
              }
            }
          : undefined
      }
    >
      <CustomIconWrapper>
        <Icon
          viewBox={'0 0 24 24'}
          radius='80rem'
          width='100%'
          padding='0'
          fill={fill}
          margin='0'
          screenReaderLabel={label}
          cursorOnHover={disabled ? 'not-allowed' : 'pointer'} // shouldn't be pointer if disabled...
        >
          {icon}
        </Icon>
      </CustomIconWrapper>
      <span>{label}</span>
    </CustomStyledActionButton>
  )
}

export default withTheme(ActionButton)
