import React from 'react'
import { StyledLocationDetail } from 'Components/LocalResources/LocationCard/style'
import Icon from 'Components/Shared/Icon/Icon'

import { StyledLocationInfo } from './style'

interface LocationInfoProps {
  content: React.JSX.Element | string
  screenReaderLabel: string
  icon: React.ReactNode
  alignitems?: string
  $isAddress?: boolean
}
const LocationInfo = ({
  content,
  screenReaderLabel,
  icon,
  alignitems,
  $isAddress,
}: LocationInfoProps) => {
  return (
    <StyledLocationInfo alignitems={'center'} showMore>
      <Icon
        alignSelf='baseline'
        style={{ alignSelf: 'baseline' }}
        scale={'22.4px'}
        screenReaderLabel={screenReaderLabel}
        fill={'rgba(0,0,0,.2)'}
        viewBox={'0 0 24 24'}
        margin={'0 0.8rem 0 0'}
      >
        {icon}
      </Icon>
      <StyledLocationDetail
        $primary
        $isAddress={$isAddress}
        style={{ marginBottom: '0' }}
      >
        {content}
      </StyledLocationDetail>
    </StyledLocationInfo>
  )
}

export default LocationInfo
