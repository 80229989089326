import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import SentryRoutes from 'Components/Shared/SentryRoutes/SentryRoutes'

import Maintenance from '../../../Pages/Maintenance/Maintenance'

const MaintenanceRouter = () => {
  const maintenance = !!process.env.REACT_APP_MAINTENANCE

  return (
    <>
      {maintenance && (
        <SentryRoutes>
          <Route path='/maintenance/maintenance' element={<Maintenance />} />
          <Route
            path='*'
            element={<Navigate to='/maintenance/maintenance' />}
          />
        </SentryRoutes>
      )}
    </>
  )
}

export default MaintenanceRouter
