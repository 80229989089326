import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import OutcomeCashBenefitForm from 'Components/Outcome/OutcomeCashBenefitForm/OutcomeCashBenefitForm'
import OutcomeConfirmationForm from 'Components/Outcome/OutcomeConfirmationForm/OutcomeConfirmationForm'
import OutcomeDenialForm from 'Components/Outcome/OutcomeDenialForm/OutcomeDenialForm'
import OutcomeResponseForm from 'Components/Outcome/OutcomeResponseForm/OutcomeResponseForm'
import OutcomeScreenOne from 'Components/Outcome/OutcomeScreenOne/OutcomeScreenOne'
import OutcomeSelectBenefitsForm from 'Components/Outcome/OutcomeSelectBenefitsForm/OutcomeSelectBenefitsForm'
import OutcomeThankYou from 'Components/Outcome/OutcomeThankYou/OutcomeThankYou'
import OutcomeUpToDate from 'Components/Outcome/OutcomeUpToDate/OutcomeUpToDate'
import { setId, setUnsubscribeToken } from 'Reducers/outcome'
import { API_BASE } from 'Shared/constants'
import { useAppDispatch } from 'Store/hooks'

type FollowUpQuery = {
  logo: string | null
  outcomeTypes: OutcomeTypes
  caseManagerFollowup: boolean
  unsubscribeToken: string
  followups: Followup[]
  token: string
}

export type Followup = {
  caseName: string
  cashBenefit: boolean
  category: string
  id: string
  outcomeTypes: OutcomeTypes
  valid: boolean
}

export type OutcomeTypes = {
  positiveConfirmationTypes: string[]
  negativeConfirmationTypes: string[]
}

export type FollowUpPayload = {
  confirmation_type:
    | 'yes'
    | 'no'
    | 'unknown'
    | 'not_yet'
    | 'prefer_not_to_say'
    | ''
  outcome_amount?: string | undefined
  positive_confirmation_type?: string
  negative_confirmation_type?: string
}

const OutcomePage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [queryData, setQueryData] = useState<FollowUpQuery | null>(null)

  // call /service_case_followups, get data, and set state
  useEffect(() => {
    if (!id) return
    axios
      .get(`${API_BASE}/service_case_followups/${id}`)
      .then(({ data }) => {
        setQueryData(data.data)
      })
      .catch((error) => {
        if (error.response.status === 404) {
          navigate('up-to-date')
        }
      })
  }, [id])

  useEffect(() => {
    if (typeof id === 'string') {
      dispatch(setId(id))
    }
  }, [id])

  useEffect(() => {
    if (queryData?.unsubscribeToken) {
      dispatch(setUnsubscribeToken(queryData.unsubscribeToken))
    }
  })

  return (
    <Routes>
      {/* Screen 1 */}
      <Route
        path={'/'}
        element={
          <OutcomeScreenOne
            logo={queryData?.logo ?? null}
            token={queryData?.unsubscribeToken}
          />
        }
      />
      {/* Screen 2 if answer to screen 1 is "yes". Otherwise Thank you page is next and last screen. (Select followups from checkboxes) */}
      <Route
        path={'select-benefits'}
        element={
          <OutcomeSelectBenefitsForm
            logo={queryData?.logo ?? null}
            followups={queryData?.followups}
          />
        }
      />
      {/* Screen 3. (What was the response, confirmed or denied?) */}
      <Route
        path={'response'}
        element={<OutcomeResponseForm logo={queryData?.logo ?? null} />}
      />
      {/*Screen 4 IF response was a confirmation */}
      <Route
        path={'confirmation'}
        element={<OutcomeConfirmationForm logo={queryData?.logo ?? null} />}
      />
      {/* Screen 4 IF response was a denial */}
      <Route
        path={'denial'}
        element={<OutcomeDenialForm logo={queryData?.logo ?? null} />}
      />
      {/* Screen 5 IF benefit was confirmed AND it's a cash benefit*/}
      <Route
        path={'cash-benefit'}
        element={<OutcomeCashBenefitForm logo={queryData?.logo ?? null} />}
      />
      {/* Thank you page (final page )*/}
      <Route
        path={'thank-you'}
        element={<OutcomeThankYou logo={queryData?.logo ?? null} />}
      />

      <Route
        path={'up-to-date'}
        element={<OutcomeUpToDate logo={queryData?.logo ?? null} />}
      />
    </Routes>
  )
}

export default OutcomePage
