import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { compose } from '@reduxjs/toolkit'
import Header from 'Components/Shared/Header/Header'
import Menu from 'Components/Shared/Menu/Menu'
import { StyledMain } from 'Pages/Results/style'

import { StyledCard, StyledContainer } from './style'

const HeaderWithContainerAndCard = ({ children, preScreener, padding }) => {
  const location = useLocation()
  const { page, isOnCategories } = preScreener
  const scrollRef = useRef()

  useEffect(() => {
    scrollToTopOfStep()
  }, [page])

  const scrollToTopOfStep = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }
  return (
    <>
      <Header />
      <Menu pathname={location.pathname} fullHeight={true} />
      <StyledMain>
        <StyledContainer ref={scrollRef} category={isOnCategories}>
          <StyledCard padding={padding} category={isOnCategories}>
            {children}
          </StyledCard>
        </StyledContainer>
      </StyledMain>
    </>
  )
}

const mapStateToProps = (state) => {
  const { preScreener } = state
  return {
    preScreener,
  }
}

export default compose(connect(mapStateToProps))(HeaderWithContainerAndCard)
