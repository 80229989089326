import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

import 'Components/Uielements/styles/globalStyle.css'

const StyledMainContentAll = css`
  padding: 0;
  overflow: unset;
  max-height: initial;
`

type StyledMainContentProps = {
  $mainContentHeight: number
}

export const StyledMainContent = styled.div<StyledMainContentProps>`
  width: 100%;
  color: ${(props) => props.theme.text.default};
  @media only screen and (max-width: 800px) {
    ${StyledMainContentAll};
  }
  @media only screen and (min-width: 801px) {
    ${StyledMainContentAll};
    padding: 1rem 0;
  }
  @media only screen and (min-width: 1025px) {
    padding: 1rem;
    max-height: ${(props) => props.$mainContentHeight / 10}rem;
    max-width: 1400px;
    margin: 0 auto 0 auto;
  }
`

export const StyledCardListContent = styled.ul`
  padding: 0;
  margin: 0;
`

type StyledScrollInsideBoxProps = {
  $height: string
  $heightSm?: string
  $padding?: string
}

export const StyledScrollInsideBox = styled.div<StyledScrollInsideBoxProps>`
  overflow-x: hidden;
  position: relative;
  padding: ${(props) => props.$padding};
  @media only screen and (max-width: 480px) {
    height: ${(props) => props.$heightSm};
  }
  @media only screen and (min-width: 481px) {
    height: ${(props) => props.$heightSm};
  }
  @media only screen and (min-width: 1025px) {
    height: ${(props) => props.$height};
  }
  ::-webkit-scrollbar {
    background-color: #fff;
    width: 0.3125rem;
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c8c8c8;
  }
  scrollbar-width: thin;
  scrollbar-color: #c8c8c8 #f5f5f5;
`

export const StyledCardParent = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

type StyledElemCardWidthProps = {
  $width?: string
  $widthSm?: string
}

export const StyledElemCardWidth = styled.div<StyledElemCardWidthProps>`
  @media only screen and (max-width: 800px) {
    width: ${(props) => props.$widthSm};
  }
  @media only screen and (max-width: 480px) {
    width: ${(props) => props.$widthSm};
  }
  @media only screen and (min-width: 481px) {
    width: ${(props) => props.$width};
  }
`

export const StyledCategoryList = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  margin: auto;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

type StyledCardTextProps = {
  $margin?: string
  $width?: string
  $closed?: boolean
}

export const StyledCardText = styled.div<StyledCardTextProps>`
  margin: ${(props) => (props.$margin ? props.$margin : 0)};
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  line-height: 1.5;
  font-size: 0.8125rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  ${(props) =>
    props.$closed &&
    css`
      color: #757575;
    `}
`

type StyledCaseEligibilityProps = {
  $highlight?: boolean
  $closed?: boolean
}

export const StyledCaseEligibility = styled(
  StyledCardText
)<StyledCaseEligibilityProps>`
  ${(props) =>
    props.$highlight &&
    css`
      color: ${theme.palette.primary.dark};
    `}
  font-size: 0.8125rem;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`

export const StyledPeopleLength = styled.div`
  font-size: 0.6875rem;
`

export const StyledPeopleLengthChild = styled.span`
  color: ${theme.palette.primary.dark};
`

export const StyledLeftJustifiedLink = styled(StyledCardText)`
  color: ${theme.palette.primary.dark};
  cursor: pointer;
  margin-right: 0.4rem;
  width: auto;
  text-align: start;
`

export const StyledCTALabel = styled.label`
  font-size: 0.7rem;
  font-weight: 600;
`

export const StyledHeadingRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.4375rem;
  margin-bottom: 1.4375rem;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    width: 100%;
    overflow-wrap: break-word;
    background-color: #fff;
    margin-top: 0;
    margin-bottom: 1rem;
    padding: 1rem;
    box-shadow: 0 0.125rem 0.3125rem #0000000a;
  }
`

export const Vline = styled.span`
  background: #ddd;
  width: 1px;
  height: 22px;
  margin-right: 35px;
  margin-left: 35px;
`

export const StyledParentSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

type StyledHeadingMainProps = {
  $display: string
  $marginTop?: string
}

export const StyledHeadingMain = styled.span<StyledHeadingMainProps>`
  color: #252529;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 2px;
  text-align: center;
  display: ${(props) => props.$display};
  @media only screen and (max-width: 800px) {
    margin-top: ${(props) => (props.$marginTop ? props.$marginTop : '1rem')};
    font-size: 0.875rem;
`

export const StyledHeadingSecondary = styled.span`
  font-size: 0.8125rem;
  font-weight: 600;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: ${theme.palette.text.default};
`

export const StyledHeadingSecondarySpan = styled.div`
  color: gray;
  margin-left: 7px;
  font-weight: 500;
  font-size: 0.8125rem;
  font-family: 'Roboto', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
`

export const StyledMoreResultsCircle = styled.div`
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 30px;
  background-color: #fff;
  color: ${theme.palette.primary.dark};
  font-size: 0.6875rem;
  box-shadow: 0 3px 6px #1d283117;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  cursor: pointer;
`

export const StyledParentList = styled.div`
  display: flex;
  position: absolute;
  top: 66%;
  padding-top: 1rem;
  right: -2rem;
`

export const StyledMoreResultsList = styled.ul`
  width: 100%;
  margin: 0;
  z-index: 1;
  padding: 0.625rem 0.5rem 0.75rem;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: ${theme.palette.color.white};
  font-size: 0.75rem;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
`

export const StyledMoreResultsListItem = styled.li`
  list-style: none;
  width: 100%;
  padding: 0.3125rem 0;
  line-height: 1.8;
  &:last-child {
    padding: 0;
  }
`

export const StyledThreeColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 0 1rem 1rem;
  }
  @media only screen and (min-width: 1201px) {
    flex-direction: row;
    padding: 0;
  }
`

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 1201px) {
    width: 40%;
  }
`

export const StyledFullHeightScrollingColumn = styled.div`
  width: 30%;

  @media only screen and (max-width: 800px) {
    margin: 1rem 0 0;
    width: 100%;
  }
  @media only screen and (min-width: 801px) {
    margin: 1rem 0 0;
    width: 100%;
  }
  @media only screen and (min-width: 1025px) {
    margin: 0;
    width: 35%;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: ${(props) => props.$width || ' 30%'};
    margin-bottom: ${(props) => props.$marginbottom || ' '};
    margin-top: ${(props) => props.$margintop || ' '};
  }
  > div:nth-child(2) {
    overflow: auto;
    max-height: calc(100vh - 241px);
  }
`

type StyledCaseCategoryHeadingProps = {
  $margin?: string
  $fontSize?: string
}

export const StyledCaseCategoryHeading = styled.span<StyledCaseCategoryHeadingProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.singleStopDarkerGray};
  fill: ${(props) => props.theme.singleStopDarkerGray};
  font-size: ${(props) => props.$fontSize || '0.875rem'};
  font-weight: 500;
  margin: ${(props) => props.$margin};
`

export const StyledHouseholdMembersWrapper = styled.div`
  width: 100%;
  padding: 0;

  ${StyledCaseCategoryHeading} {
    border-top: 1px solid #f1f1f1;
    padding-top: 16px;
    border-radius: 3px;
    &:first-child {
      border-top: 0 !important;
      padding-top: 0 !important;
    }
  }
`

export const StyledCategoryListItem = styled.li`
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  color: #525252;
`
