import React from 'react'

import { RightArrowIcon } from '../assets'

import { StyledContinueButtonGridItem } from './style'

/**
 * Floating Continue Button
 *
 * @param {*} onContinueClick
 */
const ReviewNavigationContinueButton = ({
  onContinueClick,
}: {
  onContinueClick: () => void
}) => (
  <StyledContinueButtonGridItem
    disableFocusRipple={true}
    onClick={onContinueClick}
    data-testid='continue-button'
    endIcon={RightArrowIcon}
  >
    Continue
  </StyledContinueButtonGridItem>
)

export default ReviewNavigationContinueButton
