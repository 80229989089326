import React from 'react'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import DocumentsChecklistContent from 'Pages/DocumentsChecklist/DocumentsChecklistContent'

const DocumentsChecklistPage = () => {
  return (
    <HeaderWithContainer>
      <DocumentsChecklistContent />
    </HeaderWithContainer>
  )
}

export default DocumentsChecklistPage
