import { Button } from 'Components/Shared/Uielements/styles/buttons.style'
import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'
import { Container, media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledContainer = styled(Container)`
  overflow: hidden;
  margin-top: 1rem;
  ${media.desktop`
  margin-top: 4rem;
  `};
`

export const StyledTitle = styled.h1`
  font-size: 24px;
  width: 100%;
  color: ${theme.palette.primary.dark};
  font-size: 2.5rem;
  font-weight: 700;
  ${media.desktop`
    font-size: 40px;
`}
`
export const StyledParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: 400;
  margin: 0 0 10px;
  color: ${theme.palette.text.default};
  margin-bottom: 24px;
  ${media.desktop`
        margin-bottom: 40px;
`}
`

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 0px;
  ${media.desktop`
  width: fit-content;
    padding: 12px 24px;
    font-size: 18px;
  `};
`

export const StyledParentImg = styled.div`
  ${u.alignText}
`

export const StyledElmImg = styled.img`
  width: 100%;
`

export const StyledFigure = styled.figure`
  position: relative;
  margin: 0;
  z-index: 1;
  ${media.smaller`
    width: 100%;
    & > svg{
      width: 100% !important;
      height: auto;
    }
  `}
  ${media.tablet`
    display: flex;
    justify-content: center;
  `}
`
