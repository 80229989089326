import { Followup } from 'Pages/Outcome/OutcomePage'

export interface OutcomeResponse {
  confirmation_type: 'yes' | 'no' | 'unknown' | 'not_yet' | 'prefer_not_to_say'
  negative_confirmation_type?: string
  outcome_amount?: string | undefined
  positive_confirmation_type?: string
}

export interface OutcomeState {
  id: string
  outcomeIndex: number
  persisted: boolean
  rehydrated: boolean
  responses: Record<string, OutcomeResponse>
  selectedOutcomes: Followup[]
  unsubscribeToken: string
}

export const outcome: OutcomeState = {
  id: '',
  outcomeIndex: 0,
  persisted: false,
  rehydrated: false,
  responses: {},
  selectedOutcomes: [],
  unsubscribeToken: '',
}
