import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import UnverifiedUserPath from 'Shared/Icons/UnverifiedUserPath'
import VerifiedUserPath from 'Shared/Icons/VerifiedUserPath'
import { gray, singleStopGreen } from 'Shared/Theme/index'

const UserVerificationStatusIcon = ({ verified }: { verified: boolean }) => {
  const fill = verified ? singleStopGreen : gray
  const path = verified ? VerifiedUserPath : UnverifiedUserPath
  const screenReaderLabel = verified ? 'verified user' : 'unverified user'

  return (
    <Icon
      fill={fill}
      margin='0'
      opacity={1}
      scale={'1.5rem'}
      screenReaderLabel={screenReaderLabel}
      top={'0.125rem'}
      viewBox={'0 0 29.563 29.563'}
    >
      {path}
    </Icon>
  )
}

export default UserVerificationStatusIcon
