import {
  defaultCheckboxMap,
  householdMembersExcludingClientMap,
  householdMembersSelectorMap,
} from 'Components/Screener/helpers'
import moment from 'moment'

import {
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
} from './constants/checkboxes'

/**
 * [AGE_LIMIT The age limit]
 * @type {Number}
 */
export const AGE_LIMIT = 13

/**
 * [AGE_LIMIT_ERROR Error displayed when under the age limit]
 * @type {String}
 */
export const AGE_LIMIT_ERROR = `You must be over ${AGE_LIMIT} years old to proceed`

/**
 * [AGE_ERROR_NO_BIRTH_MONTH Error shown when the month is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_MONTH = 'Enter birth month'

/**
 * [AGE_ERROR_NO_BIRTH_DAY Error shown when the day is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_DAY = 'Enter birth day'

/**
 * [AGE_ERROR_NO_BIRTH_YEAR Error shown when the year is missing from
 *   the date of birth.]
 * @type {String}
 */
export const AGE_ERROR_NO_BIRTH_YEAR = 'Enter birth year'

/**
 * [AGE_BIRTHDAY_IN_FUTURE Error shown when a DOB is set in the future]
 * @type {String}
 */
export const AGE_ERROR_BIRTHDAY_IN_FUTURE =
  'Date of birth cannot be in the future'

/**
 * [FIELD_FIRST_NAME First and Last Name - First Name]
 * @type {String}
 */
export const FIELD_FIRST_NAME = 'first_name'

/**
 * [FIELD_AGE Birth Date and Age - Age]
 * @type {String}
 */
export const FIELD_AGE = 'age'

/**
 * [FIELD_BIRTH_MONTH Birth Date and Age - Month]
 * @type {String}
 */
export const FIELD_BIRTH_MONTH = 'birth_month'

/**
 * [FIELD_BIRTH_DAY Birth Date and Age - Day]
 * @type {String}
 */
export const FIELD_BIRTH_DAY = 'birth_day'

/**
 * [FIELD_BIRTH_YEAR Birth Date and Age - Year]
 * @type {String}
 */
export const FIELD_BIRTH_YEAR = 'birth_year'

/**
 * [REQUIRED Error shown for empty forms that are required]
 * @type {String}
 */
export const REQUIRED = 'Required'
export const CHECKBOXREQUIRED = 'A selection is required.'

/**
 * Calculates age based on Birthday
 *
 * @param   {Number} options.birthMonth  Birth Month
 * @param   {Number} options.birthDay    Birth Day
 * @param   {Number} options.birthYear   Birth Year
 * @return  {Number} options.age         Age
 */
export const getAge = ({ birthYear, birthMonth, birthDay }) => {
  const date_of_birth = new Date(`${birthYear}-${birthMonth}-${birthDay}`)
  var diffMs = Date.now() - date_of_birth.getTime()
  var ageDifference = new Date(diffMs)
  return ageDifference.getUTCFullYear() - 1970
}

/**
 * Checks for missing birth month
 * @param  {Number} options.birthMonth  Birth Month
 * @return {Boolean}                    True if Month is missing
 */
const missingBirthMonth = ({ birthMonth }) => !birthMonth

/**
 * Checks for missing birthday among month, day, year
 *
 * @param  {Number} options.birthDay    Birth Day
 * @return {Boolean}                    True if Day is missing
 */
const missingBirthDay = ({ birthDay }) => !birthDay

/**
 * Checks for missing birth year among month, day, year
 *
 * @param  {Number} options.birthYear   Birth Year
 * @return {Boolean}                    True if Year is missing
 */
const missingBirthYear = ({ birthYear }) => !birthYear

/**
 * Checks if user is underage, by date of birth
 *
 * @param  {Number} options.birthMonth  Birth Month
 * @param  {Number} options.birthDay    Birth Day
 * @param  {Number} options.birthYear   Birth Year
 * @return {Boolean}                    True if underage
 */
export const userBirthdayUnderage = ({ birthMonth, birthDay, birthYear }) =>
  userUnderage({ age: getAge({ birthYear, birthMonth, birthDay }) })

/**
 * Checks if user is underage, by age
 *
 * @param  {Number}   options.age         Age
 * @return {Boolean}                      True if underage
 */
export const userUnderage = ({ age }) => age <= AGE_LIMIT

/**
 * Returns the common errors with birthday and age input that are shared
 * across Prescreener and Screener
 *
 * @param  {Object} values Object containing birthday or age
 * @return {Object}        Object containing errors
 */
export const getBirthdayErrors = (values) => {
  let errors = {}

  if (missingBirthMonth(values)) {
    errors.birthMonth = AGE_ERROR_NO_BIRTH_MONTH
  }

  if (missingBirthDay(values)) {
    errors.birthDay = AGE_ERROR_NO_BIRTH_DAY
  }

  if (missingBirthYear(values)) {
    errors.birthYear = AGE_ERROR_NO_BIRTH_YEAR
  }

  return errors
}

export const getMilitaryDateErrors = (values) => {
  const errors = {}

  if (Object.keys(errors).length === 0) {
    const startDate = new Date(
      values.start_year,
      +values.start_month - 1,
      values.start_day
    )
    const endDate = new Date(
      values.end_year,
      +values.end_month - 1,
      values.end_day
    )

    if (startDate > endDate) {
      errors.startMonth = 'Start date must be before end date'
    }

    const today = new Date()
    if (startDate > today) {
      errors.startMonth = 'Start date must be in the past'
    }

    if (endDate > today) {
      errors.endMonth = 'End date must be in the past'
    }
  }

  return errors
}

export const checkboxProps = ({
  clientName,
  householdMembers,
  householdMembersIndex,
  checkboxType,
  name: fieldName,
  options,
}) => {
  switch (checkboxType) {
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR:
      return householdMembersSelectorMap(
        clientName,
        householdMembers,
        fieldName
      )
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_EXCLUDING_CLIENT:
      return householdMembersExcludingClientMap(householdMembers, fieldName)
    default:
      return defaultCheckboxMap(householdMembersIndex, options)
  }
}

export const validateDateOfBirthAsSingleField = (value) => {
  if (!value) {
    return 'Required'
  } else if (moment(value).isAfter(moment())) {
    return AGE_ERROR_BIRTHDAY_IN_FUTURE
  } else if (moment().diff(moment(value), 'years') <= 13) {
    return AGE_LIMIT_ERROR
  } else {
    return undefined
  }
}

export const parseDateForUpdateDOB = (value) =>
  value ? moment(value).format('YYYY-MM-DD') : ''

export const formatDateForUpdateDOB = (value) =>
  value ? moment(value).format('MM/DD/yyyy') : ''
