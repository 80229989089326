import React from 'react'

import { StyledPeople, StyledPeopleParent } from './style'

interface makeLabelInterface {
  eligible: boolean
  people: string[]
}
const makeLabel = ({ eligible, people }: makeLabelInterface) => {
  let label
  if (eligible) {
    label = people.length ? 'Likely Eligible For' : 'Likely Eligible'
  } else {
    label = 'Unlikely Eligible'
  }
  return label
}

const EligibilityStatus = ({ eligible, people }: makeLabelInterface) => {
  return (
    <StyledPeopleParent>
      <span>{makeLabel({ eligible, people })}</span>
      {people && (
        <StyledPeople>
          {people.map((item) => item?.split(',')[0]).join(', ')}
        </StyledPeople>
      )}
    </StyledPeopleParent>
  )
}

export default EligibilityStatus
