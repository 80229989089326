import FormHelperText from '@material-ui/core/FormHelperText'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const StyledFormHelperText = withStyles({
  root: {
    margin: '0',
  },
})(FormHelperText)

export const StyledErrorSpan = styled.span`
  position: relative;
  top: 0 !important;
  line-height: 1rem;
  font-size: 0.875rem;
  left: 0.25rem;
  color: #e41a0c;
`

export const StyledParentErrorSpan = styled.span`
  display: flex;
  align-items: center;
`
