import React from 'react'
import { StyledMenuItem } from 'Components/Shared/ReduxForm/SelectMenu/style'

const SelectMenuOptions = ({
  options,
}: {
  options: {
    value: string
    label: string | number
  }[]
}) =>
  options.map((option) => {
    const { label: optionLabel, value } = option
    return (
      <StyledMenuItem id={value} value={value} key={value}>
        {optionLabel}
      </StyledMenuItem>
    )
  })

export default SelectMenuOptions
