import React from 'react'

import BenefitsSection from './BenefitsSection'
import HelpLineSection from './HelpLineSection'
import HelpSec from './HelpSection'
import HeroSection from './HeroSection'
import { KindsSec } from './ResourcesSection'

const Main = () => (
  <>
    <HelpLineSection />
    <HeroSection />
    <BenefitsSection />
    <HelpSec />
    <KindsSec />
  </>
)

export default Main
