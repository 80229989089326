import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateInitialValues } from 'Actions/screener'
import { initialize } from 'redux-form'
import { SCREENER_FORM_NAME } from 'Shared/constants'
import { RootState } from 'Store'

const useInitializeForms = () => {
  const dispatch = useDispatch()
  const { initialValues } = useSelector((state: RootState) => state.screener)

  useEffect(() => {
    if (initialValues) {
      dispatch(initialize(SCREENER_FORM_NAME, initialValues))
      dispatch(updateInitialValues(null))
    }
  }, [initialValues, dispatch])
}

export default useInitializeForms
