import { createContext } from 'react'

export type ProtectedRouteContextType = {
  me: {
    role: string
  }
}
export const ProtectedRouteContext = createContext({})

export const ProtectedRouteProvider = ({
  children,
  data,
}: {
  children: React.ReactNode
  data: Record<string, unknown>
}) => {
  return (
    <ProtectedRouteContext.Provider value={data}>
      {children}
    </ProtectedRouteContext.Provider>
  )
}
