import React, { ComponentType, useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import { GTAG } from 'Shared/constants'

ReactGA.initialize(GTAG)

interface WithTrackerOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const withTracker = <P extends object>(
  WrappedComponent: ComponentType<P>,
  options: WithTrackerOptions = {}
): ComponentType<P> => {
  const trackPage = (page: string) => {
    ReactGA.set({
      page,
      ...options,
    })
    ReactGA.pageview(page)
  }

  const HOC: React.FC<P> = (props) => {
    const location = useLocation()

    useEffect(() => {
      trackPage(location.pathname)
    }, [location.pathname])

    return <WrappedComponent {...props} />
  }

  return HOC
}

export default withTracker
