import React from 'react'
import HelpIcon from 'Components/LandingPage/Assets/HelpIcon.svg'
import { Container } from 'styled-bootstrap-grid'

import { HelpLineStyled } from '../Styles/HelpLine'

const HelpLineSection = () => {
  return (
    <Container>
      <HelpLineStyled>
        <figure className='icon'>
          <img src={HelpIcon} alt='Suicide and Crisis Lifeline' />
        </figure>
        <p>
          If you or someone you know is in crisis, call or text 988 to reach the{' '}
          <a
            href=' https://988lifeline.org/talk-to-someone-now'
            target='_blank'
            rel='noopener noreferrer'
          >
            Suicide and Crisis Lifeline
          </a>
          , chat with them online via their website, or text HOME to 741741
          (multiple languages available). If this is an emergency, call 911.
        </p>
      </HelpLineStyled>
    </Container>
  )
}

export default HelpLineSection
