import {
  CLEAR_PRE_SCREENER,
  UPDATE_PRE_SCREENER,
  VERIFICATION_SKIPPED,
} from 'Actions/preScreener'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { preScreener as initialState } from './initialState'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.preScreener,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_PRE_SCREENER: {
      const { preScreener } = action
      return {
        ...state,
        ...preScreener,
      }
    }

    case CLEAR_PRE_SCREENER:
      return initialState

    case VERIFICATION_SKIPPED:
      return {
        ...state,
        verificationSkipped: true,
      }

    default:
      return state
  }
}
