import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import IconList from 'Components/Shared/Icon/Icon'

import {
  StyledMenuItemLabel,
  StyledSidebarMainMenuButton,
  StyledSubMenuNavLink,
} from './style'

import './style.css' // for css animations

const MainMenuButton = ({ header, Icon, subMenus, handleClick }) => {
  const [opened, setOpened] = useState(true)
  const { pathname: currentPath } = useLocation()
  const isOnMainSection = subMenus.find((item) => item.link === currentPath)
  const controlsId = ('list-of-' + header).replace(' ', '-')

  return (
    <>
      <StyledSidebarMainMenuButton
        onClick={() => setOpened(!opened)}
        opened={opened}
        isOnMainSection={isOnMainSection}
        aria-controls={controlsId}
        aria-expanded={!!opened}
      >
        <ArrowDropDownIcon
          className={`chevron ${opened ? 'open' : 'closed'} ${
            isOnMainSection ? 'on-main-section' : ''
          }`}
        />
        <Icon
          className={`menu-icon ${opened ? 'open' : 'closed'} ${
            isOnMainSection ? 'on-main-section' : ''
          }`}
        />
        <StyledMenuItemLabel>{header}</StyledMenuItemLabel>
      </StyledSidebarMainMenuButton>
      {opened && (
        <ul
          className={`sub-menu-list ${opened ? 'open' : 'closed'}`}
          id={controlsId}
        >
          {subMenus.map((subMenu, i) => {
            return (
              <li key={i}>
                <StyledSubMenuNavLink
                  to={`/case-management/reports/${subMenu.link}`}
                  key={i}
                  onClick={handleClick}
                  role='button'
                >
                  <IconList
                    scale={'1.25rem'}
                    margin={'0 0.3125rem 0 0'}
                    display='inline-flex'
                    viewBox={'0 0 24 24'}
                  >
                    {subMenu.icon}
                  </IconList>
                  {subMenu.name}
                </StyledSubMenuNavLink>
              </li>
            )
          })}
        </ul>
      )}
    </>
  )
}

export default MainMenuButton
