import React, { useEffect } from 'react'
import { Form, FormProps } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import ClientPortalSectionCard from 'Components/ClientPortal/MyProfile/ClientPortalSectionCard'
import { StyledClientPortalSection } from 'Components/ClientPortal/MyProfile/style'
import {
  formTouched,
  getInitialValues,
} from 'Components/Review/ReviewSection/helpers'
import useHandleOnEditClick, {
  EditSection,
} from 'Components/Review/ReviewSection/hooks/useHandleOnEditClick'
import useHandleOnSubmit from 'Components/Review/ReviewSection/hooks/useHandleOnSubmit'
import useIsEditing from 'Components/Review/ReviewSection/hooks/useIsEditing'
import { ValidationErrors } from 'final-form'

import { GenericDataValueArray } from './ClientPortalDemographicContent'

type ClientPortalSectionProps = {
  title: string
  data: {
    valueDisplays: GenericDataValueArray[]
    tableDisplays: GenericDataValueArray[]
  }
  editSection: EditSection
  editDisabled: boolean
  index: number
  submitCallback: () => void
  validator?: (
    values: Record<string, unknown>
  ) => ValidationErrors | Promise<ValidationErrors>
}
const ClientPortalSection = (props: ClientPortalSectionProps) => {
  const { data, submitCallback, editSection, index, validator } = props
  const navigate = useNavigate()

  const isEditing = useIsEditing(index)
  const initialValues = getInitialValues(data)

  const handleEditOnClick = useHandleOnEditClick(editSection, index)
  const handleOnSubmit = useHandleOnSubmit(submitCallback)
  const handleCancel = () => {
    navigate(`/account/profile`)
  }

  let form: FormProps,
    error: Error,
    submitError: Error,
    touched: Record<string, string>

  useEffect(() => {
    if (!isEditing && (error || submitError || formTouched(touched))) {
      form.reset()
    }
  })

  return (
    <StyledClientPortalSection item data-testid='section'>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validator}
      >
        {({ form: formApi, handleSubmit }) => {
          ;({ error, submitError, touched } = formApi.getState())
          form = formApi
          return (
            <ClientPortalSectionCard
              {...props}
              {...{
                error,
                submitError,
                touched,
                isEditing,
                handleSubmit,
                handleEditOnClick,
                handleCancel,
              }}
            />
          )
        }}
      </Form>
    </StyledClientPortalSection>
  )
}

export default ClientPortalSection
