import React from 'react'
import { BenefitStatus } from '__generated__/graphql'
import InProgressIcon from '@material-ui/icons/AccessTime'
import CancelIcon from '@material-ui/icons/Cancel'
import Confirmed from '@material-ui/icons/CheckCircleOutline'
import NotStarted from '@material-ui/icons/HighlightOff'
import { StyledActivationStatus } from 'Components/ClientPortal/MyBenefits/CaseCard/CustomCaseStatus/style'
import { toCapitalized } from 'Shared/helpers'

const CustomCaseStatus = ({ status }: { status: BenefitStatus }) => {
  return (
    <div style={{ display: 'flex' }}>
      <StyledActivationStatus $status={status} data-testid={'case-status'}>
        <span className='customIcon'>
          {status === 'IN_PROGRESS' && <InProgressIcon />}
          {status === 'NOT_STARTED' && <NotStarted />}
          {(status === 'CONFIRMED' || status === 'CLOSED') && <Confirmed />}
          {status !== 'CONFIRMED' &&
            status !== 'IN_PROGRESS' &&
            status !== 'CLOSED' &&
            status !== 'NOT_STARTED' && <CancelIcon />}
        </span>
        <span className='customText'>
          {toCapitalized(status).replace('_', ' ')}
        </span>
      </StyledActivationStatus>
    </div>
  )
}

export default CustomCaseStatus
