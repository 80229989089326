import { withStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl)

export const StyledRadioGroup = withStyles({
  root: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: '1rem',
  },
})(RadioGroup)

export const StyledLabel = styled.label`
  font-size: 0.975rem;
  color: #000000;
  font-weight: 400;
`

export const StyledFormControlLabel = withStyles({
  root: {
    border: `0.1rem solid ${theme.palette.color.black}`,
    margin: '0',
    borderRadius: '0.3rem',
    height: '3.8rem',
    padding: '0 3rem',
    width: '100%',
    position: 'relative',
    color: theme.palette.color.black,
    backgroundColor: `${theme.palette.color.white}`,
    '&:hover': {
      '& > span:first-child': {
        // Styles for the button background
        backgroundColor: (props) =>
          props.$isBtnNo
            ? `${theme.palette.color.grey}`
            : `${theme.palette.primary.darken5}`,
      },
      '& > span:last-child': {
        // Styles for the button text
        color: (props) =>
          props.$isBtnNo
            ? `${theme.palette.color.white}`
            : `${theme.palette.color.white}`,
      },
    },
    '& > span:first-child': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      padding: '0',
      left: '0',
      top: '0',
      borderRadius: '0',
      '& span div': {
        opacity: '0',
      },
    },
    '& >span:last-child': {
      fontSize: '1.2rem',
      fontWeight: '500',
      margin: 'auto',
      zIndex: 1,
    },
    '& span.Mui-checked': {
      color: 'unset !important',
      background: (props) =>
        props.$isBtnNo
          ? `${theme.palette.color.grey}`
          : `${theme.palette.primary.dark}`,
      '&&:hover': {
        background: (props) =>
          props.$isBtnNo
            ? `${theme.palette.color.grey}`
            : `${theme.palette.primary.dark}`,
      },
      '& + span': {
        color: `${theme.palette.color.white}`,
      },
    },
  },
})(FormControlLabel)

StyledFormControl.displayName = 'StyledFormControl'
StyledRadioGroup.displayName = 'StyledRadioGroup'
StyledFormControlLabel.displayName = 'StyledFormControlLabel'
