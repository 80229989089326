import React, { createContext, useEffect } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Client } from '__generated__/graphql'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import {
  handleNavigationButtonClick,
  updateHouseholdMembersIndex,
} from 'Actions/screener'
import CallsToAction from 'Components/Screener/CallsToAction'
import ScreenerBackButton from 'Components/Screener/ScreenerBackButton'
import {
  ReverseCol,
  StyledHeadingMain,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { screenerSelector } from 'Selectors'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import HouseholdMembers, { HouseholdMemberWithIndex } from './HouseholdMembers'
import { StyledFullWidthTable, StyledFullWidthTbody } from './style'

export const TableContext = createContext('')

export interface HouseholdMembersTableProps {
  client: Client
  householdMembers: HouseholdMemberWithIndex[]
  questionDirectionText: string
  questionText: string
}
const HouseholdMembersTable = (props: HouseholdMembersTableProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const { client, householdMembers, questionDirectionText, questionText } =
    props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      updateHouseholdMembersIndex(
        householdMembers?.length ? householdMembers.length - 1 : 0
      )
    )
  }, [])

  return (
    <Container>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <StyledHouseholdMembersListItem>
        <MuiThemeProvider theme={ssTheme}>
          {/* Are we using this context? */}
          <TableContext.Provider value=''>
            <StyledHouseholdMembersListItem
              houseHold
              display='flex'
              justifycontent='space-between'
            >
              <StyledParagraph houseHold width={'35%'}>
                First Name
              </StyledParagraph>
              <StyledParagraph houseHold width={'35%'}>
                Last Name
              </StyledParagraph>
              <StyledParagraph houseHold>Edit</StyledParagraph>
              <StyledParagraph houseHold textalign='right'>
                Remove
              </StyledParagraph>
            </StyledHouseholdMembersListItem>
            <StyledHouseholdMembersList>
              <Row>
                <Col lg='12'>
                  <StyledMuiTextFieldWrapper mbottom='1rem'>
                    <StyledFullWidthTable>
                      <StyledFullWidthTbody data-testid='HouseholdMembers'>
                        <HouseholdMembers
                          householdMembers={householdMembers}
                          client={client}
                        />
                      </StyledFullWidthTbody>
                    </StyledFullWidthTable>
                  </StyledMuiTextFieldWrapper>
                </Col>
              </Row>
              <CallsToAction {...props} margin='0' />
            </StyledHouseholdMembersList>
          </TableContext.Provider>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
      {!isMobile && (
        <Row>
          <ScreenerBackButton width='180px' margin='0' />
        </Row>
      )}
      {isMobile && (
        <StyledParentButtonsActionsInMobile>
          <ReverseCol>
            <ScreenerBackButton
              width='100%'
              maxWidth='100%'
              smallerMargin='0'
            />
          </ReverseCol>
        </StyledParentButtonsActionsInMobile>
      )}
    </Container>
  )
}

const stateToProps = (
  state: {
    client: Client
    householdMembers: HouseholdMemberWithIndex[]
  },
  ownProps: {
    name: string
  }
) => {
  return {
    fieldValues: screenerSelector(state, ownProps.name) ?? {},
    client: state.client,
    householdMembers:
      screenerSelector(state, 'household.household_members') ?? [],
  }
}

const dispatchToProps = {
  handleNavigationButtonClick,
}

export default connect(stateToProps, dispatchToProps)(HouseholdMembersTable)
