import React, { useContext } from 'react'
import { useIsValidOrganizationAndLocationSlugs } from 'Components/App/Hooks/index'
import CookiesConsent from 'Components/LandingPage/Components/CookiesConsent/CookiesConsent'
import MainPage from 'Components/LandingPage/Sections/Main'
import PartnerLocationModal, {
  PartnerLocationModalContext,
} from 'Components/PartnerLocationModal/PartnerLocationModal'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import MainFooter from 'Components/Shared/PageFooter/Footer'
import PageNotFound from 'Pages/PageNotFound/PageNotFound'

import 'Components/Uielements/styles/globalStyle.css'

const LandingPage = ({ modalContext }) => {
  const { isValidUrl, isLoading } = useIsValidOrganizationAndLocationSlugs()
  let { showModal } = useContext(modalContext)

  if (isValidUrl)
    return (
      <>
        {showModal && (
          <PartnerLocationModal context={PartnerLocationModalContext} />
        )}
        <CookiesConsent />
        <HeaderWithContainer overflow={'hidden'}>
          <MainPage />
        </HeaderWithContainer>
        <MainFooter />
      </>
    )

  if (isLoading) return <div></div>

  return <PageNotFound />
}

export default LandingPage
