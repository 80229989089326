import { Client, HouseholdMember } from '__generated__/graphql'

export const isClient = (member: Client | HouseholdMember): boolean =>
  member?.uuid === undefined

export const firstName = (member: Client | HouseholdMember): string => {
  const { first_name, firstName, nickName } = member
  if (isClient(member)) {
    const first = firstName || nickName
    return first ? `${first} (You)` : ``
  } else {
    return `${first_name}`
  }
}

export const lastName = (member: Client | HouseholdMember): string => {
  const { last_name, lastName } = member
  return last_name || lastName || ''
}

export const firstChar = (firstName: string, lastName: string): string => {
  if (!lastName) return `${firstName.charAt(0)}`
  if (firstName && lastName)
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  return ''
}
