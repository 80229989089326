import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`
export const StyledTitle = styled.h1`
  font-size: 2.5rem;
`

export const StyledParagraph = styled.p`
  margin: 0.5rem;
`

export const CornerComponent = styled.img<{
  top: string
  right: string
  bottom: string
  left: string
}>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  @media (max-width: 768px) {
    display: none;
  }
`

export const ContainerImages = styled.div`
  position: relative;
`
export const StyledSideImage = styled.img<{
  left: string
  right: string
}>`
  position: absolute;
  width: 18rem;
  top: 3rem;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  @media (max-width: 768px) {
    display: none;
  }
`
