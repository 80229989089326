import { salmon } from 'Shared/Theme'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const ClientInfoWrapper = styled.section`
  display: flex;
  min-height: 5.5rem;
`
export const ClientInfoIconWrapper = styled.div``

//Icon
export const ClientInfoIcon = styled.div`
  width: 3.0625rem;
  height: 3.0625rem;
  background: ${theme.palette.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: white;
  text-transform: uppercase;
  margin-right: 0.8125rem;
  border-radius: 100%;
  ${media.smaller`
    width: 2.4375rem;
    height: 2.4375rem;
    flex: 0 0 2.4375rem;
  `}
`

export const ClientInfoContent = styled.div`
  flex-grow: 1;
`
export const ClientInfoNameWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
//Name
export const ClientInfoName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #252529;
  text-transform: capitalize;
  ${media.smaller`
  font-size: 1rem;
  margin-bottom: .75rem;
  `}
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const StyledHeadingInfoCard = styled.div``
export const StyledNameInfoCard = styled.div``

//Status
const statusBackground = {
  CLOSED: '#EFEFEF',
  ACTIVE: '#C1FFDA',
  ARCHIVED: salmon,
  FRESH: '#FFF1CC',
}
export const ClientInfoStatus = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  margin-right: 0.3125rem;
  ${media.smaller`
  margin-left: 0;
  margin-right: .75rem;
  `}
`
export const ClientInfoLabel = styled.div`
  color: ${theme.palette.text.Lighten};
  font-size: 0.75rem;
  margin-right: 0.3125rem;
`
export const ClientInfoBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.1875rem;
  height: 1.375rem;
  background: ${(props) => statusBackground[props.status]};
  border-radius: 1.875rem;
  font-size: 0.6875rem;
  letter-spacing: 0.01rem;
  color: #000000;
  text-align: center;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
//Email
export const ClientInfoEmail = styled.div`
  color: ${theme.palette.text.default};
  font-size: 0.75rem;
  margin-top: 0.4375rem;
  display: flex;
  align-items: center;
  ${media.smaller`
  margin-top: 0.1875rem;
  text-transform: none;
  `}
  a {
    color: inherit;
    margin-left: 0.4375rem;
    margin-right: 0.25rem;
  }
  p {
    color: inherit;
    margin-left: 0.4375rem;
  }
`
export const ClientInfoContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 480px) {
    margin-top: 1.125rem;
  }
`
export const ClientInfoContactItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    margin-right: 0;
    padding-right: 0.3125rem;
  }
`

export const StyledContactsInInfoCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
  width: 100%;
`

export const ClientInfoContactItemIcon = styled.div`
  margin-right: 0.25rem;
`
export const ClientInfoContactItemLabel = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.003125rem;
  color: #000000;
  line-height: 1;
  ${({ phoneNumber }) =>
    phoneNumber &&
    css`
      margin-right: 0.25rem;
    `}
`

export const StyledClientInfoFields = styled.div`
  padding: 0.5rem 0 0 0;
`

export const StyledClientInfoField = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 0.875rem;
  }
`
export const StyledInfo = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
`

export const StyledTooltipText = styled.h1`
  margin: 0;
  width: fit-content;
  font-weight: 700;
  font-size: 1rem;
`
