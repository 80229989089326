import React, { useRef } from 'react'
import { useInView } from 'react-hook-inview'
import { useMediaQuery } from 'react-responsive'
import Education from 'Components/LandingPage/Assets/Education.svg'
import Food from 'Components/LandingPage/Assets/Food.svg'
import Health from 'Components/LandingPage/Assets/Health.svg'
import Housing from 'Components/LandingPage/Assets/Housing.svg'
import Legal from 'Components/LandingPage/Assets/Legal.svg'
import Money from 'Components/LandingPage/Assets/Money.svg'
import Taxes from 'Components/LandingPage/Assets/Taxes.svg'
import Transit from 'Components/LandingPage/Assets/Transit.svg'
import Work from 'Components/LandingPage/Assets/Work.svg'
import { ResourceCard } from 'Components/Shared/ResourcesCard/ResourcesCard'
import { TimelineMax } from 'gsap'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { FeaturesText } from '../Components/FeaturesText'
import { LandingSection } from '../Styles/LandingSection'
import {
  StyledListItem,
  StyledListItems,
  StyledParentWithBG,
} from '../Styles/ResourcesSection'

const StyledParentElemImgFirstCol = (props) => {
  return (
    <StyledListItems>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefFirstColItemOne}
          transform='translate(-1.875rem, -6.25rem)'
          title='Money'
          imageSrc={Money}
          imageAlt='Money'
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefFirstColItemTwo}
          transform='translate(-1.875rem, -3.125rem)'
          title='Work'
          imageSrc={Work}
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefFirstColItemThree}
          transform='translate(-1.875rem, 3.125rem)'
          title='Education'
          imageSrc={Education}
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefFirstColItemFour}
          transform='translate(-1.875rem, 6.25rem)'
          title='Transit'
          imageSrc={Transit}
        ></ResourceCard>
      </StyledListItem>
    </StyledListItems>
  )
}
const StyledParentElemImgSecondCol = (props) => {
  return (
    <StyledListItems>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefSecondColItemOne}
          transform='translate(-1.875rem, -3.125rem)'
          title='Housing'
          imageSrc={Housing}
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefSecondColItemTwo}
          transform='translate(-1.875rem, 0)'
          title='Taxes'
          imageSrc={Taxes}
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefSecondColItemThree}
          transform='translate(-1.875rem, 3.125rem)'
          title='Legal'
          imageSrc={Legal}
        ></ResourceCard>
      </StyledListItem>
    </StyledListItems>
  )
}
const StyledParentElemImgThirdCol = (props) => {
  return (
    <StyledListItems>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefThirdColItemOne}
          transform='translate(-1.875rem, -3.125rem)'
          title='Food'
          imageSrc={Food}
        ></ResourceCard>
      </StyledListItem>
      <StyledListItem>
        <ResourceCard
          forwardRef={props.forwardRefThirdColItemTwo}
          transform='translate(-1.875rem, 3.125rem)'
          title='Health'
          imageSrc={Health}
        ></ResourceCard>
      </StyledListItem>
    </StyledListItems>
  )
}

export const KindsSec = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  let ElemRef = useRef([])
  let ResourcesTL = null
  let entered = false
  window.screen.orientation?.addEventListener('change', () => {
    entered = false
  })
  const TweenResources = () => {
    ResourcesTL = new TimelineMax({
      defaults: {
        duration: 0.6,
      },
    })
    ResourcesTL.staggerTo(ElemRef.current, 0.6, { y: 0, x: 0, opacity: 1 }, 0)
  }
  const [resourcesRef] = useInView({
    threshold: 0.5,
    onEnter: () => {
      if (!entered) {
        TweenResources()
        entered = true
      }
    },
  })
  return (
    <LandingSection ResourceSection>
      <StyledParentWithBG>
        <Container>
          <Row>
            <Col lg='6'>
              <FeaturesText
                marginSmallerMessage={'0'}
                widthMessage={'75%'}
                marginMessage={'0 0 0 auto'}
                subTitle='Single Stop can connect you to'
                title='All kinds of resources'
                message='Quickly browse our vast network of providers to find access to healthcare, healthy food, transportation, childcare and more!'
              />
            </Col>
            {!isMobile && (
              <Col
                lg='6'
                as='aside'
                ref={resourcesRef}
                style={{ direction: 'initial' }}
              >
                <Row
                  mdJustifyContent='center'
                  lgJustifyContent='start'
                  style={{ paddingLeft: '1rem' }}
                >
                  <Col md='3'>
                    <StyledParentElemImgFirstCol
                      forwardRefFirstColItemOne={(element) => {
                        ElemRef.current[0] = element
                      }}
                      forwardRefFirstColItemTwo={(element) => {
                        ElemRef.current[1] = element
                      }}
                      forwardRefFirstColItemThree={(element) => {
                        ElemRef.current[2] = element
                      }}
                      forwardRefFirstColItemFour={(element) => {
                        ElemRef.current[3] = element
                      }}
                    />
                  </Col>
                  <Col md='3'>
                    <StyledParentElemImgSecondCol
                      forwardRefSecondColItemOne={(element) => {
                        ElemRef.current[4] = element
                      }}
                      forwardRefSecondColItemTwo={(element) => {
                        ElemRef.current[5] = element
                      }}
                      forwardRefSecondColItemThree={(element) => {
                        ElemRef.current[6] = element
                      }}
                    />
                  </Col>
                  <Col md='3'>
                    <StyledParentElemImgThirdCol
                      forwardRefThirdColItemOne={(element) => {
                        ElemRef.current[7] = element
                      }}
                      forwardRefThirdColItemTwo={(element) => {
                        ElemRef.current[8] = element
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col lg='6'>
              <FeaturesText
                moveBtn={'true'}
                footerMarginSmaller={'0'}
                topBtn={'-10rem'}
                action1='Browse Resources'
                target1='/locations/list'
                action2='About Us'
                target2='https://singlestop.org'
                target2External={true}
                customBackgroundSecondaryButton={'#fff !important'}
              />
            </Col>
          </Row>
          <Row>
            {isMobile && (
              <Col
                as='aside'
                ref={resourcesRef}
                style={{ direction: 'initial' }}
              >
                <Row
                  mdJustifyContent='center'
                  lgJustifyContent='start'
                  style={{ paddingLeft: '1rem' }}
                >
                  <Col className='custom-padding-for-resources-column' xs='4'>
                    <StyledParentElemImgFirstCol
                      forwardRefFirstColItemOne={(element) => {
                        ElemRef.current[0] = element
                      }}
                      forwardRefFirstColItemTwo={(element) => {
                        ElemRef.current[1] = element
                      }}
                      forwardRefFirstColItemThree={(element) => {
                        ElemRef.current[2] = element
                      }}
                      forwardRefFirstColItemFour={(element) => {
                        ElemRef.current[3] = element
                      }}
                    />
                  </Col>
                  <Col className='custom-padding-for-resources-column' xs='4'>
                    <StyledParentElemImgSecondCol
                      forwardRefSecondColItemOne={(element) => {
                        ElemRef.current[4] = element
                      }}
                      forwardRefSecondColItemTwo={(element) => {
                        ElemRef.current[5] = element
                      }}
                      forwardRefSecondColItemThree={(element) => {
                        ElemRef.current[6] = element
                      }}
                    />
                  </Col>
                  <Col className='custom-padding-for-resources-column' xs='4'>
                    <StyledParentElemImgThirdCol
                      forwardRefThirdColItemOne={(element) => {
                        ElemRef.current[7] = element
                      }}
                      forwardRefThirdColItemTwo={(element) => {
                        ElemRef.current[8] = element
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </StyledParentWithBG>
    </LandingSection>
  )
}
