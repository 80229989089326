import {
  BasicInformationIcon,
  DemographicsIcon,
  ExpensesIcon,
  IncomeIcon,
  SavingsIcon,
  TaxInformationIcon,
  TitleBasicIcon,
  TitleDemoIcon,
  TitleExpensesIcon,
  TitleIncomeIcon,
  TitleSavingsIcon,
  TitleTaxIcon,
} from 'Components/Review/assets'

import { validName } from '../../PreScreener/helpers'

import {
  alternateContactInformationSubmitCallback,
  basicInformationSubmitCallback,
} from './callbacks'
import {
  basicInformation,
  contactInformation,
  demographics,
  expenses,
  income,
  savings,
  taxInformation,
} from './sections'
/**
 * Removes screener fields by removing fields that are not designated as a
 * custom field.
 *
 * @param {{}} values key value pair from final-form
 */
export const removeScreenerFields = (values) => {
  for (const prop in values) {
    !prop.includes('custom_field_') && delete values[prop]
  }
}

/**
 * Takes the client location object and turns it into an array
 * of sections
 *
 * @param {{}} client
 * @param {function} handlePatchClient
 * @param {function} saveCustomFields
 * @returns review sections data
 */
export const sections = (
  client,
  handlePatchClient,
  saveCustomFields,
  saveAlternateContactInfo
) => [
  {
    title: 'Basic Information',
    icon: BasicInformationIcon,
    titleIcon: TitleBasicIcon,
    data: basicInformation(client),
    editSection: { inline: true },
    submitCallback: basicInformationSubmitCallback(
      client?.id,
      handlePatchClient,
      saveCustomFields
    ),
    validator: validName,
  },
  {
    title: 'Contact Information',
    icon: BasicInformationIcon,
    titleIcon: TitleBasicIcon,
    data: contactInformation(client),
    editSection: { inline: true },
    submitCallback: alternateContactInformationSubmitCallback(
      client?.id,
      saveAlternateContactInfo
    ),
    readOnly: client?.legacy,
  },
  {
    title: 'Demographics',
    icon: DemographicsIcon,
    titleIcon: TitleDemoIcon,
    data: demographics(client),
    editSection: { clientStep: 107, householdStep: 208 },
  },
  {
    title: 'Income',
    icon: IncomeIcon,
    titleIcon: TitleIncomeIcon,
    data: income(client),
    editSection: { clientStep: 122, householdStep: 227 },
  },
  {
    title: 'Savings',
    icon: SavingsIcon,
    titleIcon: TitleSavingsIcon,
    data: savings(client),
    editSection: { clientStep: 142, householdStep: 249 },
  },
  {
    title: 'Expenses',
    icon: ExpensesIcon,
    titleIcon: TitleExpensesIcon,
    data: expenses(client),
    editSection: { clientStep: 145, householdStep: 253 },
  },
  {
    title: 'Tax Information',
    icon: TaxInformationIcon,
    titleIcon: TitleTaxIcon,
    data: taxInformation(client),
    editSection: { clientStep: 159, householdStep: 267 },
  },
]

/**
 * Gets sections for rendering the Review UI from
 * the graphql query result
 *
 * @param {{client: {}}} data
 * @param {{handlePatchClient:function, saveCustomFields:function, saveAlternateContactInfo:function}} callbacks
 * @returns
 */
export const getReviewSections = (
  data,
  handlePatchClient,
  saveCustomFields,
  saveAlternateContactInfo
) =>
  sections(
    data?.client,
    handlePatchClient,
    saveCustomFields,
    saveAlternateContactInfo
  )
