import gql from 'graphql-tag'

export const QUERY_GET_RESPONSES = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      ...ClientFields
      preferences {
        allowContact
        preferredLanguage
      }
    }
  }

  fragment ClientFields on Client {
    id
    nickName
    ...PersonFields
    email
    primaryPhone
    alternateEmail
    alternatePhone
    user {
      id
      verified
      __typename
    }
    zipCode
    languages
    household {
      ...HouseholdFields
    }
    demographic {
      ...DemographicFields
    }
    screening {
      legacy
      id
      location {
        id
        slug
        name
        organization {
          id
          slug
        }
      }
    }
  }

  fragment HouseholdFields on Household {
    id
    size
    situation
    members {
      ...HouseholdMemberFields
    }
  }

  fragment HouseholdMemberFields on HouseholdMember {
    id
    ...PersonFields
    relationshipToClient
  }

  fragment DemographicFields on Demographic {
    id
    gender
    race
    ethnicity
    education
    pregnancyStatus
  }

  fragment PersonFields on Person {
    fullName
    firstName
    lastName
    age
    dateOfBirth
    ... on Client {
      displayDateOfBirth @client
    }
    ... on HouseholdMember {
      displayDateOfBirth @client
    }
    claimedAsDependent
    demographic {
      ...DemographicFields
    }
    legacy
  }
`

export const ALTERNATE_CONTACT_MUTATION = gql`
  mutation saveAlternateContact(
    $clientId: ID!
    $alternateContact: String
    $alternateContactType: ClientAlternateContactType!
  ) {
    updateClientAlternateContactInfo(
      clientId: $clientId
      alternateContact: $alternateContact
      alternateContactType: $alternateContactType
    ) {
      client {
        alternatePhone
        alternateEmail
      }
    }
  }
`

export const PREFERENCES_CONTACT_MUTATION = gql`
  mutation SetClientPreferences(
    $clientId: ID!
    $preferences: ClientPreferencesInput!
  ) {
    setClientPreferences(clientId: $clientId, preferences: $preferences) {
      client {
        id
      }
    }
  }
`
