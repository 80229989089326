import React from 'react'
import FormError from 'Components/Shared/FormError/FormError'
import {
  StyledFormControl,
  StyledSelect,
} from 'Components/Shared/ReduxForm/SelectMenu/style'

interface SelectMenuMuiRenderFieldProps {
  input: object
  label: string
  children: Array<React.ReactElement>
  meta: {
    touched: boolean
    error?: string
  }
}

const SelectMenuMuiRenderField = ({
  input: inputProps,
  label,
  children,
  meta: { touched, error },
}: SelectMenuMuiRenderFieldProps) => {
  return (
    <StyledFormControl variant='outlined' fullWidth>
      <StyledSelect
        {...inputProps}
        variant='outlined'
        label={label}
        error={touched && !!error}
      >
        {children}
      </StyledSelect>
      <FormError touched={touched} error={error} id={label} />
    </StyledFormControl>
  )
}

export default SelectMenuMuiRenderField
