import React from 'react'
import ClientPortalContactLoginContent from 'Components/ClientPortal/MyProfile/ClientPortalContactLoginContent'
import ClientPortalDemographicContent from 'Components/ClientPortal/MyProfile/ClientPortalDemographicContent'
import ClientPortalPersonalInfoContent, {
  ClientPortalPersonalInfoContentProps,
} from 'Components/ClientPortal/MyProfile/ClientPortalPersonalInfoContent'
import {
  StyledCard,
  StyledCardError,
  StyledClientPortalCardContent,
  StyledSectionHeader,
  StyledSectionTitle,
} from 'Components/ClientPortal/MyProfile/CustomClientPortalSection/CustomClientPortalSectionStyle'
import { formTouched } from 'Components/Review/ReviewSection/helpers'
import ActionButton from 'Components/Review/ReviewSection/ReviewSectionCard/ActionButton/ActionButton'
import FormError from 'Components/Shared/FormError/FormError'

export type ClientPortalSectionCardProps = {
  title: string
  isEditing: boolean
  error: string
  submitError: () => void
  data: ClientPortalPersonalInfoContentProps['data']
  touched: boolean
  readOnly?: boolean
}

const ClientPortalSectionCard = ({
  title,
  isEditing,
  error,
  submitError,
  data,
  touched,
  readOnly,
  ...buttonProps
}: ClientPortalSectionCardProps) => {
  return (
    <StyledCard
      className={`${isEditing ? 'StyledClientPortalCardContent__isEditing' : ''}`}
    >
      <StyledClientPortalCardContent>
        <StyledSectionHeader>
          <StyledSectionTitle>{title}</StyledSectionTitle>
          {title !== 'Basic Demographics' && (
            <span
              className={`${isEditing ? 'actionButtonsWrapper__isEditing' : ''} actionButtonsWrapper`}
            >
              <ActionButton
                {...buttonProps}
                isEditing={isEditing}
                editDisabled={readOnly}
                isClientPortal
              />
            </span>
          )}
        </StyledSectionHeader>

        <FormError
          error={error ?? submitError}
          touched={formTouched(touched)}
        />
        <StyledCardError></StyledCardError>
        {title === 'Personal Info' && (
          <ClientPortalPersonalInfoContent data={data} isEditing={isEditing} />
        )}
        {title === 'Contact and Login' && (
          <ClientPortalContactLoginContent data={data} isEditing={isEditing} />
        )}
        {title === 'Basic Demographics' && (
          <ClientPortalDemographicContent data={data} />
        )}
      </StyledClientPortalCardContent>
    </StyledCard>
  )
}

export default ClientPortalSectionCard
