import { StyledCircle } from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'

type CircleProps = {
  isMobile: boolean
  firstLetter?: string
  secondLetter: string
}

const Circle = ({ isMobile, firstLetter, secondLetter }: CircleProps) => {
  return (
    <>
      {isMobile && isNaN(Number(firstLetter)) && firstLetter !== undefined && (
        <StyledCircle display='flex' margin='.75rem 0 0.875rem 0' scale='4rem'>
          {firstLetter}
          {secondLetter}
        </StyledCircle>
      )}
      {!isMobile && (
        <StyledCircle>
          {firstLetter}
          {secondLetter}
        </StyledCircle>
      )}
    </>
  )
}

export default Circle
