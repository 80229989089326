import React from 'react'
import useIsFeatureToggleOn from 'Components/Shared/Hooks/useIsFeatureToggleOn'
import HouseholdMembersTableEditable, {
  HouseholdMembersTableProps,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/HouseholdMembersTable_Editable'
import HouseholdMembersTableOriginal, {
  HouseholdMembersTableOriginalProps,
} from 'Components/Shared/ReduxForm/HouseholdMembersTable/HouseholdMembersTable_Original'

const HouseholdMembersTable = (
  props: HouseholdMembersTableOriginalProps | HouseholdMembersTableProps
) => {
  const isFeatureToggleOn = useIsFeatureToggleOn()
  const isEditHouseholdMembers = isFeatureToggleOn('edit_household_members')
  return isEditHouseholdMembers ? (
    <HouseholdMembersTableEditable {...props} />
  ) : (
    <HouseholdMembersTableOriginal {...props} />
  )
}

export default HouseholdMembersTable
