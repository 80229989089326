import { validName } from '../../PreScreener/helpers'

import {
  alternateContactInformationSubmitCallback,
  basicInformationSubmitCallback,
} from './callbacks'
import { basicInformation, contactInformation, demographics } from './sections'
/**
 * Removes screener fields by removing fields that are not designated as a
 * custom field.
 *
 * @param {{}} values key value pair from final-form
 */
export const removeScreenerFields = (values) => {
  for (const prop in values) {
    !prop.includes('custom_field_') && delete values[prop]
  }
}

/**
 * Takes the client location object and turns it into an array
 * of sections
 *
 * @param {{}} client
 * @param {function} handlePatchClient
 * @returns review sections data
 */
export const sections = (
  client,
  handlePatchClient,
  saveAlternateContactInfo,
  savePreferenceContactInfo
) => [
  {
    title: 'Personal Info',
    data: basicInformation(client),
    editSection: { inline: true },
    submitCallback: basicInformationSubmitCallback(
      client?.id,
      handlePatchClient,
      savePreferenceContactInfo,
      client?.preferences?.allowContact
    ),
    validator: validName,
  },
  {
    title: 'Contact and Login',
    data: contactInformation(client),
    editSection: { inline: true },
    submitCallback: alternateContactInformationSubmitCallback(
      client?.id,
      saveAlternateContactInfo,
      savePreferenceContactInfo,
      client?.preferences?.preferredLanguage
    ),
    readOnly: client?.legacy,
  },
  {
    title: 'Basic Demographics',
    data: demographics(client),
  },
]

/**
 * Gets sections for rendering the Client Portal UI from
 * the graphql query result
 *
 * @param {{client: {}}} data
 * @param {{handlePatchClient:function, saveAlternateContactInfo:function, savePreferenceContactInfo:function}} callbacks
 * @returns
 */
export const getClientPortalSections = (
  data,
  handlePatchClient,
  saveAlternateContactInfo,
  savePreferenceContactInfo
) =>
  sections(
    data?.client,
    handlePatchClient,
    saveAlternateContactInfo,
    savePreferenceContactInfo
  )
