import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { StyledWidthCard } from 'Components/Review/ReviewSection/style'

import useHandleOnEditClick from './hooks/useHandleOnEditClick'
import useHandleOnSubmit from './hooks/useHandleOnSubmit'
import useIsEditing from './hooks/useIsEditing'
import ReviewSectionCard from './ReviewSectionCard/ReviewSectionCard'
import { formTouched, getInitialValues } from './helpers'

/**
 * Review Page Section
 *
 * Complete Action: Actions/client#completeScreener
 * Editing Complete Action:
 *
 * @param {{title:string, titleIcon, data:{valueDisplays:Array, tableDisplays:Array}, editSection, editDisabled:boolean}} Properties
 */
const ReviewSection = (props) => {
  const { data, submitCallback, editSection, index, validator } = props
  const navigate = useNavigate()

  const isEditing = useIsEditing(index)
  const initialValues = getInitialValues(data)

  const handleEditOnClick = useHandleOnEditClick(editSection, index)
  const handleOnSubmit = useHandleOnSubmit(submitCallback)
  const handleCancel = () => {
    navigate(`/review`)
  }

  let form, error, submitError, touched

  useEffect(() => {
    if (!isEditing && (error || submitError || formTouched(touched))) {
      form.reset()
    }
  })

  return (
    <StyledWidthCard item data-testid='section' className='withOutPadding'>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validator}
      >
        {({ form: formApi, handleSubmit }) => {
          ;({ error, submitError, touched } = formApi.getState())
          form = formApi

          return (
            <ReviewSectionCard
              {...props}
              {...{
                error,
                submitError,
                touched,
                isEditing,
                handleSubmit,
                handleEditOnClick,
                handleCancel,
              }}
            />
          )
        }}
      </Form>
    </StyledWidthCard>
  )
}

export default ReviewSection
