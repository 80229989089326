import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { updateNextSteps } from 'Actions/eligibility'
import ScreeningPairSvg from 'Components/PreScreener/screeningPair.svg'
import { StyledScreeningPairSvg } from 'Components/Screener/style'
import Icon from 'Components/Shared/Icon/Icon'
import { formattedReferralNumber } from 'Pages/Results/helpers'
import { mockLocations, mockReferralNumber } from 'Pages/Results/mockPayloads'
import {
  StyledButtonFilled,
  StyledButtonOutlined,
  StyledHeadingLocation,
  StyledHeadingMain,
  StyledHeadingReferralCode,
  StyledHeadingReferralLabel,
  StyledIconWrapper,
  StyledInfo,
  StyledInfoContainer,
  StyledLink,
  StyledLocationCard,
  StyledLocationCardHeadingWrapper,
  StyledLocationCardInfoContainer,
  StyledParagraph,
  StyledRadio,
  StyledReferralNumberWrapper,
} from 'Pages/Results/style'
import {
  languagesIconPath,
  locationIconPath,
  phoneIconPath,
} from 'Shared/Icons/LocationInfo/paths'

const NextStepsAppointment = ({ selectedLocation, updateNextSteps }) => {
  const handleLocationChange = (event) => {
    const id = +event.target.value
    const selectedPlace = mockLocations.find((location) => location.id === id)
    updateNextSteps({ selectedLocation: selectedPlace })
  }

  const checkFirstRadioDialByDefault = (index) => {
    if (index === 0) {
      const selectedPlace = mockLocations[0]
      updateNextSteps({ selectedLocation: selectedPlace })
      return true
    }
    return false
  }

  return (
    <>
      <StyledHeadingMain id='mainContent' tabIndex='-1'>
        let's make your appointment
      </StyledHeadingMain>
      <StyledIconWrapper>
        <StyledScreeningPairSvg alt='' src={ScreeningPairSvg} />
      </StyledIconWrapper>
      <StyledReferralNumberWrapper>
        <StyledHeadingReferralLabel>
          your referral number
        </StyledHeadingReferralLabel>
        <StyledHeadingReferralCode>
          {formattedReferralNumber(mockReferralNumber)}
        </StyledHeadingReferralCode>
      </StyledReferralNumberWrapper>
      <StyledHeadingMain>choose a preferred partner</StyledHeadingMain>
      <StyledParagraph>
        We've found several Preferred Partners near the location you've shared
        with us.
      </StyledParagraph>
      <StyledParagraph>
        Please select one to request an appointment.
      </StyledParagraph>

      {mockLocations.map((location, index) => {
        return (
          <StyledLocationCard key={location.id}>
            <StyledLocationCardHeadingWrapper>
              <StyledHeadingLocation>{location.name}</StyledHeadingLocation>
              <div>
                <StyledRadio
                  checked={
                    selectedLocation
                      ? selectedLocation.id === location.id
                      : checkFirstRadioDialByDefault(index)
                  }
                  onChange={handleLocationChange}
                  value={location.id}
                  color='default'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': location.name }}
                />
              </div>
            </StyledLocationCardHeadingWrapper>
            <StyledLocationCardInfoContainer>
              <StyledInfoContainer>
                <Icon
                  scale={'1.4rem'}
                  viewBox={'0 0 24 24'}
                  style={{ position: 'relative', top: '-1rem' }}
                >
                  {locationIconPath}
                </Icon>
                <div>
                  <StyledInfo style={{ marginBottom: '0.5rem' }}>
                    {location.distance} miles away
                  </StyledInfo>
                  <StyledInfo>{location.address}</StyledInfo>
                </div>
              </StyledInfoContainer>
              <StyledInfoContainer>
                <Icon scale={'1.4rem'} viewBox={'0 0 24 24'}>
                  {phoneIconPath}
                </Icon>
                <StyledInfo>{location.phoneNumber}</StyledInfo>
              </StyledInfoContainer>
              <StyledInfoContainer>
                <Icon scale={'1.4rem'} viewBox={'0 0 24 24'}>
                  {languagesIconPath}
                </Icon>
                <StyledInfo>{location.languages}</StyledInfo>
              </StyledInfoContainer>
            </StyledLocationCardInfoContainer>
          </StyledLocationCard>
        )
      })}

      <StyledButtonOutlined
        variant='outlined'
        component={Link}
        to={'/locations/list'}
      >
        browse all of our preferred partners
      </StyledButtonOutlined>

      {selectedLocation ? (
        <StyledParagraph>
          By requesting an appointment, you{' '}
          <StyledLink to={'/terms-and-conditions'} target='_blank'>
            agree to share your screening data
          </StyledLink>{' '}
          with {selectedLocation.name}.
        </StyledParagraph>
      ) : (
        <StyledParagraph>
          Select one of our Preferred Partners to request an appointment.
        </StyledParagraph>
      )}

      <StyledButtonFilled
        variant='outlined'
        component={Link}
        to={'/eligibility/documentation'}
      >
        request an appointment
      </StyledButtonFilled>
    </>
  )
}

const mapStateToProps = (state) => {
  const { selectedLocation } = state.eligibility
  return {
    selectedLocation,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateNextSteps: (data) => {
    dispatch(updateNextSteps(data))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NextStepsAppointment)
