import styled from 'styled-components'

export const ModelContainer = styled.section`
  position: relative;
  width: 100%;
`
export const ModelContainerTitle = styled.h1`
  padding: 14px 70px 14px 24px;
  font-family: 'Noto Sans HK', sans-serif;
  font-weight: 600;
  color: #08674a;
  font-size: 18px;
  border-bottom: 1px solid #efefef;
  margin: 0;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    font-weight: 550;
    padding: 14px 70px 14px 16px;
  }
`
export const ModelContainerContent = styled.div`
  padding: 16px 24px;
  font-family: 'Noto Sans HK', sans-serif;

  @media only screen and (max-width: 1024px) {
    padding: 16px 16px;
  }
`
export const ModelContainerContentLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #08674a !important;
  background-color: #eef9f6;
  border-radius: 8px;
  margin-bottom: 16px;
  text-decoration: none;
  svg {
    width: 20px;
    height: unset;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
    padding: 10px 12px;
    svg {
      width: 19px;
    }
  }
`
export const ModelContainerContentDescription = styled.p`
  font-weight: 450;
  font-size: 14px;
  margin: 0;
  color: #393946;
  line-height: 22px;
`

export const ModelContainerCloseIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 26px;
  svg {
    width: 24px;
    fill: #151515;
  }
  @media only screen and (max-width: 1024px) {
    right: 15px;
  }
`
