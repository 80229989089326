import React from 'react'

import SelectMenuField from './SelectMenuField'
import SelectMenuFieldArray from './SelectMenuFieldArray'

interface ISelectMenuProps {
  iterable: boolean
}

const SelectMenu = (props: ISelectMenuProps) => {
  return props.iterable ? (
    <SelectMenuFieldArray {...props} />
  ) : (
    <SelectMenuField {...props} />
  )
}
export default SelectMenu
