import { FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const FormControlLabelStyled = withStyles({
  root: {
    fontSize: '0.5rem',
    marginRight: '0px',
  },
  label: {
    fontSize: '0.90rem',
    color: '#393946',
    textAlign: 'left',
    fontWeight: 300,
  },
})(FormControlLabel)
