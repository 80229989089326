import React from 'react'
import HeaderWithContainer from 'Components/Shared/HeaderWithContainer/HeaderWithContainer'
import NextStepsApplyOnline from 'Pages/NextSteps/NextStepsApplyOnline'
import NextStepsAppointment from 'Pages/NextSteps/NextStepsAppointment'
import Eligibility from 'Pages/Results/Eligibility'
import theme from 'Shared/Theme/ssTheme'

import { StyledWrapper } from './style'

const Results = (props) => {
  const { section } = props

  return (
    <HeaderWithContainer section={section}>
      <StyledWrapper
        minHeight={'calc(100vh - ' + theme.layout.headerHeight + ')'}
        padding='0'
        background='#f1f1f1'
      >
        {section === 'benefits' && <Eligibility />}
        {section === 'preferred-partners' && <NextStepsAppointment />}
        {section === 'apply-online' && <NextStepsApplyOnline />}
      </StyledWrapper>
    </HeaderWithContainer>
  )
}

export default Results
