import React from 'react'
import { ServiceCase } from '__generated__/graphql'
import { formatToDollarsEven } from 'Shared/helpers'

import { StyledBenefitAmount } from './style'

interface IBenefitAmount {
  eligibleAmount: ServiceCase['eligibleAmount']
}

const BenefitAmount = ({ eligibleAmount }: IBenefitAmount) => (
  <StyledBenefitAmount highlight={eligibleAmount !== 'N/A'}>
    {` ${formatToDollarsEven(eligibleAmount)}`}
  </StyledBenefitAmount>
)

export default BenefitAmount
