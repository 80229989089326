import { MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'
import CustomDropdown from 'Components/Shared/Dropdown/CustomDropdown'
import styled from 'styled-components'

export const StyledLabel = styled.label`
  font-size: 0.975rem;
  color: #000000;
  font-weight: 400;
  margin-bottom: 1.5rem;
`

export const StyledSelect = styled(CustomDropdown)`
  color: #29283e;
  font-weight: 400;
  font-family: 'Noto Sans HK', sans-serif;
  margin-top: 1rem;
  padding: 1.15625rem 0.875rem;
  font-size: 0.96rem;
`

export const StyledFormControl = withStyles({
  root: {
    borderRadius: '0.375rem',
    width: '100%',
    '& > div': {
      position: 'relative',
      height: '4.5rem',
      paddingBottom: '1rem',
      '& > label': {
        fontSize: '1rem',
        padding: '0px',
        margin: '0px',
        transform: 'translate(14px, 5px) scale(1) !important',
      },
    },
    '& div:focus': {
      background: 'unset',
    },
  },
})(FormControl)

export const StyledOption = styled(MenuItem)`
  font-size: inherit;
`
