import React, { createContext, FC, PropsWithChildren } from 'react'
import { API_BASE } from 'Shared/constants'

export type FeatureToggle = {
  [key: string]: 'on' | 'off'
}

export const FeatureToggleContext = createContext<FeatureToggle>({})

export async function loadFeatureFlags() {
  try {
    const res = await fetch(`${API_BASE}/flipper/api/features`)
    const data = await res.json()

    const toggles = data.features.reduce(
      (acc: FeatureToggle, curr: FeatureToggle) => ({
        ...acc,
        [curr.key]: curr.state,
      }),
      {} as FeatureToggle
    )

    localStorage.setItem('featureToggles', JSON.stringify(toggles))

    return toggles
  } catch (e) {
    console.error(e)
    return {}
  }
}

const FeatureToggleProvider: FC<
  PropsWithChildren & { toggles: FeatureToggle }
> = ({ children, toggles }) => (
  <FeatureToggleContext.Provider value={toggles}>
    {children}
  </FeatureToggleContext.Provider>
)

export default FeatureToggleProvider
