import React from 'react'
import { StyledCaseNameWrapper } from 'Components/ClientPortal/MyBenefits/style'
import Icon from 'Components/Shared/Icon/Icon'
import {
  StyledBenefitHeading,
  StyledFocusButtonWrapper,
} from 'Pages/Results/style'
import { isSpacebarOrEnterKey } from 'Shared/helpers'

const CaseTitle = ({
  name,
  description,
  handleClickOpen = () => undefined,
  focusRef,
  setActiveFocusRef = () => undefined,
}: {
  name: string
  description: string
  handleClickOpen?: () => void
  focusRef: React.RefObject<HTMLDivElement>
  setActiveFocusRef?: (ref: React.RefObject<HTMLDivElement>) => void
}) => {
  return (
    <StyledCaseNameWrapper>
      <StyledBenefitHeading
        className='StyledBenefitHeading--custom-style'
        data-testid='benefit-program'
        onClick={handleClickOpen}
      >
        <span className='StyledBenefitHeading__heading'>
          <span title={name}>{name}</span>
        </span>
        <StyledFocusButtonWrapper
          className='StyledBenefitHeading__button'
          tabIndex={0}
          role={'button'}
          ref={focusRef}
          onKeyDown={(evt) => {
            if (isSpacebarOrEnterKey(evt.keyCode)) {
              handleClickOpen()
            }
          }}
          onClick={() => {
            setActiveFocusRef(focusRef)
          }}
        >
          <Icon
            data-testid={'more-info'}
            fill={'#000'}
            scale={'1.25rem'}
            margin={'0 0 0 .4rem'}
            viewBox={'0 0 24 24'}
            padding={'0'}
            screenReaderLabel={'More Info'}
            cursorOnHover={'pointer'}
          >
            <path d='M0 0h24v24H0V0z' fill='none' />
            <path d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z' />
          </Icon>
        </StyledFocusButtonWrapper>
      </StyledBenefitHeading>
    </StyledCaseNameWrapper>
  )
}

export default CaseTitle
