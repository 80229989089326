import React from 'react'
import { TableRow } from '@material-ui/core'
import {
  StyledTableCell,
  StyledTableHead,
} from 'Components/Review/ReviewSection/style'

import { ITableDisplay } from '../..'

const ReviewSectionTableHead = ({
  labels,
}: {
  labels: ITableDisplay['labels']
}) => (
  <StyledTableHead>
    <TableRow>
      {labels?.map((label, i) => (
        <StyledTableCell key={i}>{label}</StyledTableCell>
      ))}
    </TableRow>
  </StyledTableHead>
)

export default ReviewSectionTableHead
