import Polygon1 from 'Components/LandingPage/Assets/Polygon1.svg'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledFooterWrapper = styled.footer`
  ${u.dFlex}
  ${u.justifyContentCenter}
  ${u.alignItemsCenter}
  ${u.flexCol}
  padding: 3rem 0 0;
  ${u.pRelative}
  ${media.smaller`
    padding: 1rem 0 0;
  `}
`
export const StyledPolyShape = styled.div`
  ${u.pAbsolute}
  width: 17%;
  height: 86%;
  background-image: url(${Polygon1});
  background-repeat: no-repeat;
  background-position: 70% 173%;
  background-size: contain;
  top: 0;
  right: 0;
`
export const StyledFooterTop = styled.section`
  ${u.dFlex}
  ${u.justifyContentSB}
  opacity: 0;
  ${media.smaller`
    ${u.flexCol}
  `}
`
StyledFooterWrapper.displayName = 'StyledFooterWrapper'
StyledPolyShape.displayName = 'StyledPolyShape'
StyledFooterTop.displayName = 'StyledFooterTop'
