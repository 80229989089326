import { Link } from 'react-router-dom'
import theme from 'Shared/Theme/ssTheme'
import * as u from 'Shared/Theme/utilities.styles'
import { media } from 'styled-bootstrap-grid'
import styled, { css } from 'styled-components'

export const NavList = styled.ul`
  ${u.zeroMargin}
  ${u.zeroPadding}
  list-style: none;
  font-size: 0.75rem;
  ${u.dFlex}
  ${media.smaller`
    margin-top: 0.75rem;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
  `}
`
export const NavItem = styled.li`
  padding-left: 1em;
  ${media.smaller`
    padding-left: 0;
    padding-right: 1em;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    line-height: 1.5rem;
  `}
`

const navLinkCss = css`
  color: ${theme.palette.text.default};
  text-decoration: none;
  white-space: pre;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${theme.palette.primary.dark};
  }
`

export const NavLink = styled.a`
  ${navLinkCss}
`

export const NavLinkRouter = styled(Link)`
  ${navLinkCss}
`
export const ExternalLink = styled.a`
  ${navLinkCss}
`

export const Paragraph = styled.p`
  ${u.wd100}
  font-size: 14px;
  @media only screen and (max-width: 480px) {
    text-align: center;
  }
  ${media.tablet`
  margin: 0 0 1rem;
  `}
  ${media.desktop`
  ${u.zeroMargin}
  `}
`
export const Strong = styled.strong`
  font-weight: 700;
`

export const Wrapper = styled.nav`
  ${u.dFlex}
  ${u.flexCol}
  ${media.smaller`
  `};
  ${media.tablet`
  `}
  ${media.desktop`
    ${u.flexRow}
  `}
`
export const FooterBottom = styled.div`
  ${u.wd100}
  margin: 2rem 0 0;
  background-color: ${theme.palette.color.lightGrey};
  padding: 1rem 0;
  opacity: 0;
  transform: translate(0, 1.875rem);
  ${media.smaller`
  margin: 1rem 0 0;
  `}
`
