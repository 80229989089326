import React from 'react'
import { Container } from 'styled-bootstrap-grid'
import { Row } from 'styled-bootstrap-grid'

import { ReviewSectionContentProps } from '..'

import ValueDisplay from './ReviewSectionValue'

const ReviewSectionValues = ({
  valueDisplays,
  isEditing,
}: {
  valueDisplays: ReviewSectionContentProps['data']['valueDisplays']
  isEditing: ReviewSectionContentProps['isEditing']
}) => (
  <Container>
    <Row>
      {valueDisplays?.map((value, i) => (
        <ValueDisplay
          key={i}
          isEditing={!!value?.editable && isEditing}
          {...value}
        />
      ))}
    </Row>
  </Container>
)

export default ReviewSectionValues
