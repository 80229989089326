import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

const SurfaceStyle = css`
  background-color: ${(props) =>
    props.background ? props.background : theme.palette.color.white};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  bottom: ${(props) => props.bottom};
  color: ${(props) => props.theme.text.default};
  box-shadow: ${(props) =>
    props.boxShadow || props.theme.surfaceElevation(props.elevation)};
  display: ${(props) => props.display || 'flex'};
  flex: ${(props) => props.flex};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : `column`};
  justify-content: center;
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin: ${(props) => props.margin};
  min-height: ${(props) =>
    props.minHeight ? props.minHeight : theme.layout.headerHeight};
  padding: ${(props) => props.padding};
  height: ${(props) => props.Height};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  transition: box-shadow 0.3s ease-in-out;
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  z-index: ${(props) => (props.zIndex ? props.zIndex : props.elevation)};
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight}` : '')}
  ${(props) => (props.overflowY ? `overflow-y: ${props.overflowY}` : '')}
opacity: ${(props) => props.opacity};

  &:focus {
    box-shadow: ${(props) =>
      props.boxShadow || props.theme.surfaceElevation(props.elevation + 1)};
    outline: ${(props) =>
      props.outlineOnFocus ? '1px dotted #212121' : 'none'};
    outline: ${(props) =>
      props.outlineOnFocus ? '5px auto -webkit-focus-ring-color' : 'none'};
    background-color: ${(props) =>
      props.focusBackground ? theme.palette.primary.lighten30 : ''};
    > div {
      background-color: ${theme.palette.primary.lighten30};
    }
  }
  @media only screen and (max-width: 480px) {
    width: ${(props) => props.mobileWidth};
    min-height: ${(props) =>
      props.minHeightMob ? props.minHeightMob : props.minHeight};
  }
  @media only screen and (min-width: 481px) {
    width: ${(props) => props.tabletWidth};
    min-height: ${(props) =>
      props.minHeightTab ? props.minHeightTab : props.minHeight};
  }
  @media only screen and (min-width: 1025px) {
    width: ${(props) => props.width};
    min-height: ${(props) =>
      props.minHeight ? props.minHeight : theme.layout.headerHeight};
  }

  ${({ customMobile }) =>
    customMobile &&
    css`
      background-color: ${(props) => props.background};
      padding: ${(props) => props.padding};
      border-radius: ${(props) => props.radius};
      min-height: ${(props) => props.minHeight};
    `}
`

export const StyledSurface = styled.header`
  ${SurfaceStyle}
`

export const StyledCustomSurface = styled.div`
  ${SurfaceStyle}
`

StyledSurface.displayName = 'StyledSurface'
StyledCustomSurface.displayName = 'StyledCustomSurface'
