import React from 'react'
import { FormSection } from 'redux-form'

import {
  CHECKBOX_TYPE_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER,
  CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR,
} from './constants/checkboxes'
import CheckboxList from './CheckboxList'
import { useMigrateFromSingleValue } from './hooks'

const CheckboxesFormSection = (props) => {
  const { name, validate } = props

  useMigrateFromSingleValue(props)

  return (
    <FormSection name={name}>
      <CheckboxList {...props} validate={validate} />
    </FormSection>
  )
}

const Checkboxes = (props) => {
  const { checkboxType, disableValidation } = props
  const { validate: checkboxesValidators, ...rest } = props
  const validator = checkboxesValidators[checkboxType]
  const validate = !disableValidation && validator ? validator : undefined

  switch (checkboxType) {
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_SELECTOR:
      return (
        <CheckboxList
          data-testid='memberSelectorCheckboxList'
          {...rest}
          validate={validate}
        />
      )
    case CHECKBOX_TYPE_CATEGORY_SELECTOR:
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_CATEGORY_SELECTOR:
    case CHECKBOX_TYPE_HOUSEHOLD_MEMBER_MULTI_ANSWER:
    default:
      return (
        <CheckboxesFormSection
          data-testid='categorySelectorCheckboxList'
          {...rest}
          validate={validate}
        />
      )
  }
}

export default Checkboxes
