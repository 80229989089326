import React from 'react'
import Error from 'Components/Shared/Error/Error'

const FieldError = ({
  meta: { submitFailed, error },
}: {
  meta: { submitFailed: boolean; error: string }
}) => (submitFailed && error ? <Error error={error} /> : null)

export default FieldError
