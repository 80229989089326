import React from 'react'

import Surface from '../Surface/Surface'

interface CardProps {
  children: React.ReactNode
  margin?: string
  radius?: string
  customSurface?: boolean
  [key: string]: unknown
}

const Card = (props: CardProps) => {
  const {
    children,
    margin = '0.4rem',
    radius = '0.4rem',
    customSurface,
  } = props

  return (
    <Surface
      {...props}
      radius={radius}
      margin={margin}
      isCustomSurface={customSurface}
    >
      {children}
    </Surface>
  )
}

export default Card
