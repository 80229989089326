import React from 'react'
import ScreenerProgressBar from 'Components/Screener/ScreenerProgressBar'
import Steps from 'Components/Screener/Steps'
import Header from 'Components/Shared/Header/Header'
import { StyledCard } from 'Components/Shared/HeaderWithContainerAndCard/style'
import Menu from 'Components/Shared/Menu/Menu'
import { StyledMain } from 'Pages/Results/style'

const MobileScreener = () => (
  <>
    <Header />
    <Menu />
    <StyledMain>
      <ScreenerProgressBar />
      <StyledCard>
        <Steps />
      </StyledCard>
    </StyledMain>
  </>
)

export default MobileScreener
