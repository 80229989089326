import React, { createContext, useState } from 'react'
import { connect } from 'react-redux'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import ScreenerActions from 'Components/Screener/screenerActions'
import {
  StyledHeadingMain,
  StyledParagraph,
  StyledSubHeading,
} from 'Components/Screener/style'
import { StyledCheckBoxWrapperList } from 'Components/Shared/ReduxForm/HouseholdMembersTable/style'
import {
  StyledFieldset,
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
} from 'Components/Shared/ReduxForm/style'
import { FieldArray } from 'redux-form'
import { getClientName, screenerSelector } from 'Selectors'
import { SCREENER_FORM_NAME } from 'Shared/constants'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Row } from 'styled-bootstrap-grid'

import {
  handleCategorySelectors,
  handleHouseholdMemberCategorySelectors,
  handleHouseholdMemberCheckboxes,
} from '../../../Actions/screener'

import CheckboxGroup from './CheckboxGroup'
import { checkboxProps } from './helpers'

export const TableContext = createContext()

const CheckboxList = (props) => {
  const {
    leadingTableTitleText,
    name: fieldName,
    questionDirectionText,
    questionText,
    validate,
    'data-testid': dataTestId,
  } = props

  const tableState = useState({
    elevation: 0,
    focusInput: null,
  })

  return (
    <>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}
      {questionDirectionText && (
        <StyledSubHeading>{questionDirectionText}</StyledSubHeading>
      )}

      <StyledFieldset>
        <legend>{leadingTableTitleText}</legend>

        <StyledHouseholdMembersListItem data-testid={dataTestId}>
          <MuiThemeProvider theme={ssTheme}>
            <TableContext.Provider value={tableState}>
              {fieldName === 'household.additional_income_sources' ||
              fieldName === 'utility_expenses' ||
              fieldName === 'health_insurance' ||
              fieldName === 'household.health_insurance' ||
              fieldName === 'client.military_branch' ||
              fieldName === 'household.military_branch' ? (
                ''
              ) : (
                <StyledHouseholdMembersListItem income houseHold>
                  <StyledParagraph income>First Name</StyledParagraph>
                  <StyledParagraph income>Select</StyledParagraph>
                </StyledHouseholdMembersListItem>
              )}
              <StyledHouseholdMembersList>
                <Row>
                  <Col lg='12'>
                    <StyledCheckBoxWrapperList>
                      <FieldArray
                        options={checkboxProps(props)}
                        name={fieldName}
                        component={CheckboxGroup}
                        validate={validate}
                      />
                    </StyledCheckBoxWrapperList>
                  </Col>
                </Row>
              </StyledHouseholdMembersList>
            </TableContext.Provider>
          </MuiThemeProvider>
        </StyledHouseholdMembersListItem>
      </StyledFieldset>
      <ScreenerActions {...props} />
    </>
  )
}

const stateToProps = (state, ownProps) => ({
  data: screenerSelector(state, ownProps.name),
  clientName: getClientName(state, SCREENER_FORM_NAME),
  householdMembers: screenerSelector(state, 'household.household_members'),
  householdMembersIndex: state.screener.householdMembersIndex,
})

const dispatchToProps = {
  handleCategorySelectors,
  handleHouseholdMemberCheckboxes,
  handleHouseholdMemberCategorySelectors,
}

export default connect(stateToProps, dispatchToProps)(CheckboxList)
