import React from 'react'

import { DescriptionContent, DescriptionWrapper } from './style'

const ProgramDescription = ({ text }: { text: string }) => {
  return (
    <DescriptionWrapper>
      <DescriptionContent dangerouslySetInnerHTML={{ __html: text }} />
    </DescriptionWrapper>
  )
}

export default ProgramDescription
