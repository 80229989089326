import styled from 'styled-components'

export const StyledQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`

export const StyledSecondaryHeading = styled.h4`
  font-size: 1.4rem;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
`
