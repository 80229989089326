import { StyledH1 } from 'Components/LocalResources/LocationCard/style'
import styled from 'styled-components'

export const CustomStyledBorderBottom = styled.div`
  border-bottom: 1px solid #00000017;
  padding-bottom: 1rem;
  padding-top: 1rem;
`

export const CustomStyledH3 = styled(StyledH1)`
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  @media only screen and (max-width: 1024px) {
    font-weight: 550;
  }
`
