import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { startEditingOnStep } from 'Actions/screener'
import { useHasHouseholdMembers } from 'Components/Shared/ReduxForm/hooks'
import { isClientPortalPage } from 'Shared/helpers'

export type EditSection = {
  inline: boolean
  householdStep: number
  clientStep: number
}

const useHandleOnEditClick = (editSection: EditSection, index: number) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const hasHouseholdMembers = useHasHouseholdMembers()

  const editInline = () => {
    if (isClientPortalPage()) {
      navigate(`/account/profile/edit/${index}`)
    } else {
      navigate(`/review/edit/${index}`)
    }
  }

  const editAtStep = () => {
    dispatch(
      startEditingOnStep(
        hasHouseholdMembers
          ? editSection?.householdStep
          : editSection?.clientStep
      )
    )
    navigate('/screening')
  }

  return () => (editSection?.inline ? editInline() : editAtStep())
}

export default useHandleOnEditClick
