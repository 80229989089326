import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import { ArrowSpan, styles } from 'Components/Shared/Dialog/style'

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('closeModalBtn')?.focus()
    }, 100)
  }, [])

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
          id='closeModalBtn'
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography variant='h6' style={props.typostyle}>
        {children}
      </Typography>
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 6),
  },
}))(MuiDialogContent)

const CustomizedDialogs = ({ ...props }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant='outlined' style={props.Styles} onClick={handleClickOpen}>
        {props.btnName}
        <ArrowSpan />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        role='dialog'
        aria-modal='true'
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={handleClose}>
          {props.btnName}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>{props.content}</Typography>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CustomizedDialogs
