import {
  StyledHeadingDialog as heading,
  StyledParagraph as paragraph,
} from 'Components/CaseManagement/Modals/style'
import styled from 'styled-components'

export const StyledHeadingDialog = styled(heading)`
  width: 26rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`
export const StyledParagraph = styled(paragraph)`
  width: 25.625rem;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
`
StyledParagraph.displayName = 'StyledParagraph'
StyledHeadingDialog.displayName = 'StyledHeadingDialog'
