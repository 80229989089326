import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

export const StyledTableGridItem = withStyles({
  root: {
    overflowX: 'auto',
  },
})(Grid)

export const StyledEmptyBoxParent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`

export const StyledIconBox = styled.div``

export const StyledTextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
  @media only screen and (max-width: 480px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`

export const StyledHeading = styled.h3`
  font-size: 1.25rem;
  margin: 0 0 0.625rem;
  font-family: 'Roboto', sans-serif;
`

export const StyledParagraph = styled.p`
  font-size: 0.875rem;
  color: #737373;
  font-family: 'Roboto', sans-serif;
  margin: 0;
`
