import React from 'react'
import CaseManagementTopNavLink from 'Components/CaseManagement/Header/TopNavLink/TopNavLink'
import useActiveLink from 'Components/Shared/Hooks/useActiveLink'
import TabletAndDesktopTopNavLink from 'Components/Shared/MenuItem/TabletAndDesktopTopNavLink'
import { StyledListItem } from 'Components/Shared/NavBar/NavBar.style'

const MenuItems = ({
  items,
  color,
  fontSize,
  fontWeight,
  padding,
  position,
  isCaseManagement,
}) => {
  const { isActiveLink } = useActiveLink()
  const TopNavLink = isCaseManagement
    ? CaseManagementTopNavLink
    : TabletAndDesktopTopNavLink

  return items.map((props, index) => {
    return (
      <StyledListItem key={index}>
        <TopNavLink
          fontSize={fontSize}
          fontWeight={fontWeight}
          padding={padding}
          position={position}
          {...props}
          key={index}
          activelink={isActiveLink(props)}
          color={props.color || color}
        />
      </StyledListItem>
    )
  })
}

export default MenuItems
