import React, { createContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { Client } from '__generated__/graphql'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import {
  handleNavigationButtonClick,
  removeHouseholdMember,
  updateHouseholdMembersIndex,
} from 'Actions/screener'
import CallsToAction from 'Components/Screener/CallsToAction'
import ScreenerBackButton from 'Components/Screener/ScreenerBackButton'
import {
  ReverseCol,
  StyledHeadingMain,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import {
  StyledHouseholdMembersList,
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { screenerSelector } from 'Selectors'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { ssTheme } from 'Shared/Theme/ssTheme'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import { HouseholdMemberWithIndex } from './HouseholdMembers'
import {
  StyledCircle,
  StyledFullWidthTable,
  StyledFullWidthTbody,
  StyledOnlyVisibleToScreenReader,
  StyledRemoveCircleIcon,
  StyledTableRow,
  StyledTableTd,
} from './style'

export const TableContext = createContext()

export interface HouseholdMembersTableOriginalProps {
  client: Client
  householdMembers: HouseholdMemberWithIndex[]
  questionDirectionText: string
  questionText: string
  removeHouseholdMember: (index: { index: number }) => void
}
const HouseholdMembersTable = (props: HouseholdMembersTableOriginalProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const {
    client,
    householdMembers,
    questionDirectionText,
    questionText,
    removeHouseholdMember,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      updateHouseholdMembersIndex(
        householdMembers?.length ? householdMembers.length - 1 : 0
      )
    )
  }, [])

  const [, setElevation] = useState(0)
  const [memberButtonFocusId, setMemberButtonFocusId] = useState(null)

  const raiseElevation = () => setElevation(2)

  const lowerElevation = () => setElevation(0)

  const handleButtonBlur = () => {
    setMemberButtonFocusId(null)
    lowerElevation()
  }

  const renderHouseholdMembers = () => {
    const familyMembers = householdMembers
      ? [client, ...householdMembers]
      : [client]
    const familyMembersSize = familyMembers.length

    const isClient = (member: HouseholdMemberWithIndex) => {
      return member.index === undefined
    }

    const firstName = (member: Client | HouseholdMemberWithIndex) => {
      const { first_name, firstName, nickName } = member
      if (isClient(member)) {
        const first = firstName || nickName
        return first ? `${first} (You)` : ``
      } else {
        return `${first_name}`
      }
    }

    const lastName = (member) => {
      const { last_name, lastName } = member
      return last_name || lastName
    }

    const firstChar = (firstName, lastName) => {
      if (!lastName) return `${firstName.charAt(0)}`

      if (firstName && lastName)
        return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }

    return familyMembers.map((member, index) => (
      <StyledTableRow
        data-testid={`familyMemberRow${index}`}
        key={index}
        matchedButtonFocusId={index === memberButtonFocusId}
        memberId={index}
        onFocus={raiseElevation}
        onBlur={lowerElevation}
      >
        <StyledTableTd width='37%' widthSM='40%'>
          <StyledOnlyVisibleToScreenReader>
            {`Member ${index + 1} of ${familyMembersSize}: `}
          </StyledOnlyVisibleToScreenReader>
          <StyledCircle data-testid={`Initials${index}`}>
            {firstChar(firstName(member), lastName(member))}
          </StyledCircle>
          {firstName(member)}
        </StyledTableTd>

        <StyledTableTd width='37%'>{lastName(member)}</StyledTableTd>

        <StyledTableTd household>
          {!isClient(member) ? (
            <StyledRemoveCircleIcon
              data-testid={`RemoveButton${member.index}`}
              onClick={() => removeHouseholdMember({ index: member.index })}
              onFocus={() => setMemberButtonFocusId(index)}
              onBlur={handleButtonBlur}
              aria-label={`Button to remove ${
                firstName(member) + lastName(member)
              }`}
            />
          ) : (
            ''
          )}
        </StyledTableTd>
      </StyledTableRow>
    ))
  }

  return (
    <Container>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <StyledHouseholdMembersListItem>
        <MuiThemeProvider theme={ssTheme}>
          <TableContext.Provider value=''>
            <StyledHouseholdMembersListItem
              houseHold
              display='flex'
              justifycontent='space-between'
            >
              <StyledParagraph houseHold>First Name</StyledParagraph>
              <StyledParagraph houseHold>Last Name</StyledParagraph>
              <StyledParagraph houseHold textalign='right'>
                Remove
              </StyledParagraph>
            </StyledHouseholdMembersListItem>
            <StyledHouseholdMembersList>
              <Row>
                <Col lg='12'>
                  <StyledMuiTextFieldWrapper mbottom='1rem'>
                    <StyledFullWidthTable>
                      <StyledFullWidthTbody data-testid='HouseholdMembers'>
                        {renderHouseholdMembers()}
                      </StyledFullWidthTbody>
                    </StyledFullWidthTable>
                  </StyledMuiTextFieldWrapper>
                </Col>
              </Row>
              <CallsToAction {...props} margin='0' />
            </StyledHouseholdMembersList>
          </TableContext.Provider>
        </MuiThemeProvider>
      </StyledHouseholdMembersListItem>
      {!isMobile && (
        <Row>
          <ScreenerBackButton width='180px' margin='0' />
        </Row>
      )}
      {isMobile && (
        <StyledParentButtonsActionsInMobile>
          <ReverseCol>
            <ScreenerBackButton
              width='100%'
              maxWidth='100%'
              smallerMargin='0'
            />
          </ReverseCol>
        </StyledParentButtonsActionsInMobile>
      )}
    </Container>
  )
}

const stateToProps = (state, ownProps) => {
  return {
    fieldValues: screenerSelector(state, ownProps.name) ?? {},
    client: state.client,
    householdMembers:
      screenerSelector(state, 'household.household_members') ?? [],
  }
}

const dispatchToProps = {
  handleNavigationButtonClick,
  removeHouseholdMember,
}

export default connect(stateToProps, dispatchToProps)(HouseholdMembersTable)
