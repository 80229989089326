import React from 'react'
import { StyledDynamicCtaButton } from 'Components/Screener/style'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'

interface ColDynamicCtaButtonProps {
  label?: string
  width?: string
  lgWidth?: string
  'data-testid'?: string
  disabled?: boolean
  height?: string
}

const ColDynamicCtaButton = ({
  label,
  width,
  lgWidth,
  disabled,
  height,
  'data-testid': dataTestId,
}: ColDynamicCtaButtonProps) => (
  <StyledDynamicCtaButton
    data-testid={dataTestId}
    type='submit'
    width={width}
    lgwidth={lgWidth}
    id={dataTestId}
    disabled={disabled}
    height={height}
    background={disabled && '#C7C7C7'}
  >
    {label ? label : 'Continue'}
    {!label && <ContinueIcon />}
  </StyledDynamicCtaButton>
)

export default ColDynamicCtaButton
