import React from 'react'
import MomentUtils from '@date-io/moment'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { StyledFormControl } from 'Components/CustomQuestions/CustomQuestionField/Inputs/DateInput/style'
import { StyledLabel } from 'Components/CustomQuestions/CustomQuestionField/Inputs/ListInput/style'
import FormError from 'Components/Shared/FormError/FormError'
import moment from 'moment'
import { ssTheme } from 'Shared/Theme/ssTheme'

import { StyledDatePickerWrap } from './style'

/**
 * North-American Month Day Year date format: 12/31/2021
 */
const format = 'MM/DD/yyyy'

/**
 * Date Input. Rendered automatically by dataType from within CustomQuestionField.
 *
 * @param {{input: {checked: boolean, name: string, onBlur: function, onChange: function, onFocus: function, value: string}}} props The Form props received from final form
 * @returns {JSX.Element} A date picker form component for use inside a final form field
 */
const DateInput = ({
  input: { name, onBlur, onChange, onFocus, value },
  meta: { touched, error, submitError },
  label,
}) => (
  <MuiThemeProvider theme={ssTheme}>
    <StyledLabel htmlFor={`${name}-label`}>{label}</StyledLabel>
    <StyledDatePickerWrap review={true}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledFormControl error={touched && (!!error || !!submitError)}>
          <KeyboardDatePicker
            data-testid={name}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(newValue) => onChange(newValue?.format(format))}
            format={format}
            value={value ? moment(value, format) : null}
            InputProps={{ disableUnderline: true, id: `${name}-label` }}
          />
          <FormError error={error ?? submitError} touched={touched} />
        </StyledFormControl>
      </MuiPickersUtilsProvider>
    </StyledDatePickerWrap>
  </MuiThemeProvider>
)

export default DateInput
