import React from 'react'
import NextStepsDocumentationListItem from 'Pages/NextSteps/NextStepsDocumentationListItem'
import { StyledDocumentationList } from 'Pages/Results/style'

export const documentsList = [
  {
    type: 'Photo ID',
    description:
      "Your state-issued driver's license, a U.S. or other legal passport, or other government-issued ID.",
    examples: ["driver's license", 'passport', 'state id'],
  },
  {
    type: 'Social Security Number (SSN)',
    description:
      'Your personal and unique government-issued number, found on your Social Security Card or on state or federal tax returns.',
    examples: null,
  },
  {
    type: 'Proof of Address',
    description:
      'A water, electricity, gas, telephone, or Internet bill; a credit card bill or statement; a bank statement; or mortgage or rental contract for your residence that includes your full name.',
    examples: ['utility bill', 'postmarked letter', 'lease'],
  },
  {
    type: 'Citizenship or Alien Status',
    description:
      'Your U.S. Passport, a Permanent Resident Card, “Green Card” (I-551), a Reentry Permit (I-327), or Refugee Travel Document (I-571).',
    examples: ['passport', 'US Citizenship', 'H1B Visa'],
  },
  {
    type: 'Proof of Income',
    description:
      "A recent pay stub, bank statement, W-2, or the most recent year's tax returns.",
    examples: ['pay stubs', 'bank statements'],
  },
  {
    type: 'Wages & Earning Statement(s)',
    description:
      "A recent pay stub, bank statement, W-2, or the most recent year's tax returns. Any non-wage earnings (alimony, child support, SSI/SSD, etc) should also be documented.",
    examples: ['pay stubs', 'bank statements'],
  },
  {
    type: 'Dependent Costs',
    description:
      'Documentation of any costs used to pay for dependent care, including a babysitter inside or outside the home, day care, before and after school or extended day programs, or eldercare expenses.',
    examples: ['bills', 'school enrollment form'],
  },
  {
    type: 'Medical Expenses',
    description:
      'Documentation of any expenses spent for the prevention, diagnosis, cure, mitigation, or treatment of any physical or mental condition.',
    examples: ['HSA statements', 'EOB', 'Claims'],
  },
]

const NextStepsDocumentationList = () => {
  return (
    <StyledDocumentationList>
      {documentsList.map((document, i) => (
        <NextStepsDocumentationListItem document={document} key={i} />
      ))}
    </StyledDocumentationList>
  )
}

export default NextStepsDocumentationList
