import _get from 'lodash.get'
import { loadingStarted, loadingStopped } from 'Reducers/uiSlice'
import { axiosWithoutAuthHeaders } from 'Shared/axiosInstances'
import { GOOGLE_API_GEOCODE_URL_WITH_KEY } from 'Shared/constants'
import { getZipCodeFromCoordinates } from 'Shared/helpers'

/**
 * [GEOLOCATION_ERROR Action type for geolocation error]
 * @type {String}
 */
export const GEOLOCATION_ERROR = 'GEOLOCATION_ERROR'
export const GEOLOCATION_STATE = 'GEOLOCATION_STATE'

/**
 * [UPDATE_PHYSICAL_ADDRESS description]
 * @type {String}
 */
export const UPDATE_PHYSICAL_ADDRESS = 'UPDATE_PHYSICAL_ADDRESS'

export const UPDATE_COORDINATES = 'UPDATE_COORDINATES'

export const UPDATE_ZIPCODE = 'UPDATE_ZIPCODE'
export const UPDATE_RESOURCES_ZIPCODE = 'UPDATE_RESOURCES_ZIPCODE'

export const CLEAR_GEOLOCATION = 'CLEAR_GEOLOCATION'
/**
 * Geolocation Error Action
 *
 * @param  {String} message Error message
 * @return {Object}         Action
 */
export const geolocationError = () => ({
  type: GEOLOCATION_ERROR,
})
export const geolocationState = (geolocationState) => ({
  type: GEOLOCATION_STATE,
  geolocationState,
})

export const updateZipCode = (zipCode) => ({
  type: UPDATE_ZIPCODE,
  zipCode,
})

export const updateResourcesZipCode = (resourcesZipCode) => ({
  type: UPDATE_RESOURCES_ZIPCODE,
  resourcesZipCode,
})

export const updateCoordinates = (coordinates) => ({
  type: UPDATE_COORDINATES,
  coordinates,
})

/**
 * Updates physical location
 *
 * @param  {Object} physicalAddress  [description]
 * @return {Object}                  [description]
 */
export const updatePhysicalAddress = (physicalAddress) => (dispatch) => {
  physicalAddress && dispatch(updateZipCode(physicalAddress.zip_code))
  dispatch({
    type: UPDATE_PHYSICAL_ADDRESS,
    physicalAddress,
  })
}

export const getZipCodeFromResult = (result) => (dispatch) => {
  const status = _get(result, 'data.status')

  if (status !== 'OK') {
    dispatch(geolocationError())
  } else {
    const zipCodeObject = _get(
      result,
      'data.results[0].address_components',
      []
    ).find((component) => _get(component, 'types[0]') === 'postal_code')

    dispatch(updateZipCode(_get(zipCodeObject, 'short_name')))
  }
}

export const getGeolocation =
  (onSuccess, onError, onBefore = null, options) =>
  (dispatch) => {
    const { geolocation } = navigator
    if (geolocation) {
      dispatch(loadingStarted())
      onBefore && onBefore()
      geolocation.getCurrentPosition(
        (position) => {
          dispatch(loadingStopped())
          onSuccess(position)
        },
        (error) => {
          dispatch(loadingStopped())
          onError(error)
        },
        options
      )
    }
  }

export const getGeoLocationWithDefaultOptions = (
  onSuccess,
  onError,
  onBefore = null,
  options = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 0,
  }
) => getGeolocation(onSuccess, onError, onBefore, options)

export const promptGeolocationPermission = () => (dispatch) => {
  const onSuccess = (position) => {
    const { latitude: lat, longitude: lng } = position.coords
    const coordinates = { lat, lng }

    getZipCodeFromCoordinates({ lat, lng })
      .then((zipCode) => {
        dispatch(updateZipCode(zipCode))
      })
      .catch((err) => {
        console.error(`Google maps API error: ${err}`)
      })

    dispatch(updateCoordinates(coordinates))
  }

  const onError = (error) => {
    console.error(`promptGeolocationPermission():  onError(): ${error.message}`)
    dispatch(geolocationError(error.message))
  }

  navigator?.permissions?.query({ name: 'geolocation' }).then(({ state }) => {
    console.log(`state: ${state}`)
    if (state === 'granted') {
      dispatch(getGeoLocationWithDefaultOptions(onSuccess, onError))
    }
  })
}

export const handleShareLocation = () => (dispatch) => {
  const onSuccess = (position) => {
    const { latitude: lat, longitude: lng } = position.coords
    const coordinates = { lat, lng }
    getZipCodeFromCoordinates(coordinates)
      .then((zipCode) => {
        dispatch(updateZipCode(zipCode))
        dispatch(updateResourcesZipCode(zipCode))
      })
      .catch((err) => {
        console.error(`Google maps API error: ${err}`)
      })

    dispatch(geolocationState('granted'))
    dispatch(updateCoordinates(coordinates))
  }

  const onError = (error) => {
    console.error(error)
    const errorMessage = error.code === 1 ? 'denied' : 'error'
    dispatch(geolocationState(errorMessage))
  }

  dispatch(getGeoLocationWithDefaultOptions(onSuccess, onError))
}

export const getPreScreenLocation = () => (dispatch) => {
  const handleSuccess = (position) => {
    const { latitude, longitude } = position.coords

    axiosWithoutAuthHeaders
      .get(`${GOOGLE_API_GEOCODE_URL_WITH_KEY}&latlng=${latitude},${longitude}`)
      .then((result) => dispatch(getZipCodeFromResult(result)))
      .catch((error) => dispatch(geolocationError(error.message)))
  }

  const handleError = (error) => {
    dispatch(geolocationError(error.message))
  }

  dispatch(getGeoLocationWithDefaultOptions(handleSuccess, handleError))
}

export const getDistanceBetween =
  (resourceLocation) => (dispatch, getState) => {
    const rad = (x) => (x * Math.PI) / 180
    const { filters, geolocation } = getState()
    const { coordinates: userLocation } = geolocation
    const { unit } = filters.distance || 'mi'

    if (!userLocation.lat || !userLocation.lng) return null

    const R = 6378137 // Earth's mean radius in meter
    const dLat = rad(resourceLocation.lat - userLocation.lat)
    const dLong = rad(resourceLocation.lng - userLocation.lng)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(userLocation.lat)) *
        Math.cos(rad(resourceLocation.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distanceInMeters = R * c
    return unit === 'km' ? distanceInMeters / 1000 : distanceInMeters / 1609.344
  }

export const clearGeoLocation = () => ({
  type: CLEAR_GEOLOCATION,
})
