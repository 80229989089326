import React from 'react'
import ScreenerActions from 'Components/Screener/screenerActions'
import { StyledHeadingMain, StyledParagraph } from 'Components/Screener/style'
import { BaseFieldProps, FieldArray } from 'redux-form'

interface FieldArrayContainerProps {
  questionText?: string
  questionDirectionText?: string
  label: string
  name: string
  validate?: Pick<BaseFieldProps, 'validate'>
  fieldArrayComponent: React.ElementType
}
const FieldArrayContainer = (props: FieldArrayContainerProps) => {
  const {
    questionText,
    questionDirectionText,
    label,
    name,
    validate,
    fieldArrayComponent,
  } = props

  return (
    <>
      {questionText && <StyledHeadingMain>{questionText}</StyledHeadingMain>}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <FieldArray
        name={name}
        label={label}
        component={fieldArrayComponent}
        validate={validate}
      />

      <ScreenerActions {...props} />
    </>
  )
}

export default FieldArrayContainer
