import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SvgIcon } from '@material-ui/core'
import { Location } from '@types/history'
import { unsubscribeClient } from 'Components/Outcome/helpers'
import { OutcomeButton } from 'Components/Outcome/OutcomeButtons'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import { FAQIcon } from 'Pages/BenefitsRecieved/IconPaths'

const OutcomeScreenOne = ({
  logo,
  token,
}: {
  logo: string | null
  token: string | undefined
}) => {
  const location: Location = useLocation()
  const navigate = useNavigate()
  const proceedToThankYouPage = () => {
    navigate(`../thank-you`)
  }
  return (
    <OutcomePageTemplate logo={logo} radioContainer={true}>
      <section className='form-container'>
        <article className='screen-one-header'>
          <SvgIcon>{FAQIcon}</SvgIcon>
          <h1 data-testid='screen-one-title'>
            Did you get a decision on any of the <br />
            benefit(s) you applied for?
          </h1>
          <p>
            Your feedback will help us improve the benefits application process.
          </p>
        </article>
        <ul>
          <li>
            <OutcomeButton
              type='primary'
              label='Yes'
              onClick={() => {
                navigate(`../select-benefits`, {
                  state: { from: location.pathname },
                })
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='secondary'
              label='No'
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label='Not yet'
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label="I don't know"
              onClick={() => {
                proceedToThankYouPage()
              }}
            />
          </li>
          <li>
            <OutcomeButton
              type='tertiary'
              label="I'd prefer to not give feedback"
              onClick={async () => {
                await unsubscribeClient(token, 'client_outcome_feedback')
                proceedToThankYouPage()
              }}
            />
          </li>
        </ul>
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeScreenOne
