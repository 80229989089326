import React from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import {
  selectActiveOutcome,
  selectActiveResponseNegativeConfirmationType,
} from 'Components/Outcome/selectors'
import { StyledLineBreak } from 'Components/Outcome/style'
import useHandleLoop from 'Components/Outcome/useHandleLoop'
import { FORM_ERROR } from 'final-form'
import { setNegativeConfirmationType } from 'Reducers/outcome'
import { DENIAL_MAPPINGS } from 'Shared/constants'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import StyledRadioButton from '../ui/StyledRadioButton'

const OutcomeDenialForm = ({ logo }: { logo: string | null }) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const activeOutcome = useAppSelector(selectActiveOutcome)
  const handleLoop = useHandleLoop()

  const initialValue = useAppSelector(
    selectActiveResponseNegativeConfirmationType
  )

  const onSubmit = (values: { denialType: string }) => {
    const { denialType } = values
    dispatch(
      setNegativeConfirmationType({
        id: activeOutcome.id,
        negative_confirmation_type: denialType,
      })
    )
    dispatch(handleLoop())
  }

  const backButtonCallback = () => {
    navigate(`../response`, {
      state: {
        from: location.pathname,
        backButtonClicked: true,
      },
    })
  }

  return (
    <OutcomePageTemplate logo={logo} radioContainer>
      <section className='form-container'>
        <h2>
          <StyledLineBreak>Why were you denied</StyledLineBreak>
          {activeOutcome?.caseName}?
        </h2>
        <Form
          initialValues={initialValue ? { denialType: initialValue } : {}}
          onSubmit={onSubmit}
          validate={({ denialType }) =>
            denialType ? {} : { [FORM_ERROR]: 'Please choose an option' }
          }
          render={({ handleSubmit, values, error, touched }) => (
            <form onSubmit={handleSubmit}>
              <ul>
                {DENIAL_MAPPINGS.filter((mapping) =>
                  activeOutcome.outcomeTypes.negativeConfirmationTypes.includes(
                    mapping.value
                  )
                ).map((mapping, index) => {
                  const { label, value } = mapping
                  const isChecked = values.denialType === value
                  const radioId = `${value}+${index}`

                  return (
                    <li key={radioId}>
                      <StyledRadioButton
                        id={radioId}
                        name='denialType'
                        value={value}
                        $isChecked={isChecked}
                        label={label}
                      />
                    </li>
                  )
                })}
              </ul>
              {error && touched?.denialType && (
                <p className='error-message'>{error}</p>
              )}
              <ButtonsContainer backButtonCallback={backButtonCallback} />
            </form>
          )}
        />
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeDenialForm
