import React from 'react'
import { StyledCaseCategoryHeading } from 'Components/CaseManagement/ClientSummary/style'
import { mainCategoryMap } from 'Components/CaseManagement/helpers'
import Icon from 'Components/Shared/Icon/Icon'
import iconMap from 'Shared/Icons/CategoryIcons'

export type CaseCategoryHeadingProps = {
  openEligibilityKey: string
  hideName?: boolean
  margin?: string
  $fontSize?: string
  $iconMargin?: string
}

const CaseCategoryHeading = ({
  openEligibilityKey,
  hideName,
  margin,
  $fontSize,
  $iconMargin = '0 7px 0 0',
}: CaseCategoryHeadingProps) => {
  const path = iconMap[openEligibilityKey]
  const name = mainCategoryMap[openEligibilityKey]?.name
  return (
    <StyledCaseCategoryHeading
      className='StyledCaseCategoryHeading--custom-style'
      $margin={margin || '0 0 1rem 0'}
      $fontSize={$fontSize}
    >
      <Icon
        scale={'2.0625rem'}
        screenReaderLabel={name}
        margin={$iconMargin}
        style={{
          background: '#fff',
          boxShadow: '0px 3px 6px #1D283117',
          borderRadius: '7px',
          padding: '2px',
        }}
      >
        {path}
      </Icon>
      {!hideName && name}
    </StyledCaseCategoryHeading>
  )
}

export default CaseCategoryHeading
