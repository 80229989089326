import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { TableRow } from '@material-ui/core'
import { StyledSubHeading } from 'Components/Screener/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import Circle from './Circle'
import ReviewSectionTableCell from './ReviewSectionTableCell'
import ReviewSectionTableInfoCell from './ReviewSectionTableInfoCell'

interface ReviewSectionTableRowProps {
  row: number[][] | number[] | Array<string | number>[]
  labels: string[]
}
const ReviewSectionTableRow = ({ row, labels }: ReviewSectionTableRowProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  const firstLetter = row?.[0]?.[0]
  const fullName = row?.[0]?.toString()?.split(' ')
  const fullNameArray = [fullName]
  const secondName = fullNameArray?.[0]?.[1]
  const secondNameSplit = [secondName].toString().split('')
  const secondLetter = secondNameSplit[0]

  return (
    <>
      {isMobile && (
        <>
          <Circle
            firstLetter={firstLetter}
            isMobile={isMobile}
            secondLetter={secondLetter}
          />
          {row?.map((column, i) => {
            return (
              <div
                key={i}
                style={{
                  width: '50%',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  marginBottom: '1rem',
                }}
              >
                <StyledSubHeading
                  fontSize='.875rem'
                  fontWeight='400'
                  marginBottom='4px'
                  opacity='.7'
                >
                  {labels[i]}
                </StyledSubHeading>
                {Array.isArray(column) ? (
                  column?.map((item, i) => {
                    return (
                      <StyledSubHeading
                        key={i}
                        fontSize='1rem'
                        fontWeight='600'
                        marginBottom='10x'
                      >
                        {item}
                      </StyledSubHeading>
                    )
                  })
                ) : (
                  <StyledSubHeading
                    fontSize='1rem'
                    fontWeight='600'
                    marginBottom='0'
                  >
                    {column}
                  </StyledSubHeading>
                )}
              </div>
            )
          })}
          {labels[labels.length - 1] === '' && <ReviewSectionTableInfoCell />}
        </>
      )}

      {!isMobile && (
        <TableRow>
          {row?.map((column, i) => (
            <ReviewSectionTableCell key={i} column={column} />
          ))}
          {labels[labels.length - 1] === '' && <ReviewSectionTableInfoCell />}
        </TableRow>
      )}
    </>
  )
}
export default ReviewSectionTableRow
