import styled, { css } from 'styled-components'

export interface StyledIconProps {
  alignSelf?: string
  backgroundColor?: string
  border?: string
  borderColor?: string
  boxShadow?: string
  cursorOnHover?: string
  display?: string
  fill?: string
  stroke?: string
  height?: string
  left?: string
  margin?: string
  padding?: string
  position?: string
  radius?: string
  right?: string
  scale?: string
  top?: string
  width?: string
  zIndex?: string
  'aria-labelledby'?: string
  rotate?: string
}
export const StyledIcon = styled.div<StyledIconProps>`
  position: ${(props) => (props.position ? props.position : 'relative')};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  z-index: ${(props) => props.zIndex};
  align-items: center;
  align-self: ${(props) => props.alignSelf || 'center'};
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => props.radius};
  border: ${(props) => props.border};
  display: ${(props) => (props.display ? props.display : 'flex')};
  fill: ${(props) => props.fill || props.theme.text.default};
  stroke: ${(props) => props.stroke};
  justify-content: center;
  margin: ${(props) => props.margin};
  box-shadow: ${(props) => props.boxShadow};
  padding: ${(props) => props.padding};
  width: ${(props) => (props.scale ? props.scale : props.width)};
  height: ${(props) => (props.scale ? props.scale : props.height)};
  &:hover {
    cursor: ${(props) => props.cursorOnHover || 'default'};
  }
  ${({ rotate }) =>
    rotate &&
    css<StyledIconProps>`
      transform: rotate(180deg);
    `};
  ${({ $isMobileCategories }) =>
    $isMobileCategories &&
    css`
      @media only screen and (max-width: 574px) {
        position: absolute;
        left: 0;
      }
    `}
`

export const screenReaderContext = {
  opacity: 0,
}

StyledIcon.displayName = 'StyledIcon'
