import React, { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import Cost from 'Components/PreScreener/Cost.svg'
import Finally from 'Components/PreScreener/Finally.svg'
import GreatSvg from 'Components/PreScreener/GreatSvg.svg'
import IncomeIcon from 'Components/PreScreener/Income.svg'
import Savings from 'Components/PreScreener/Savings.svg'
import ScreeningPairSvg from 'Components/PreScreener/screeningPair.svg'
import { StyledTermsAndButtonToContinueWrapper } from 'Components/PreScreener/style'
import ScreenerBackButton from 'Components/Screener/ScreenerBackButton'
import {
  ReverseCol,
  StyledDiv,
  StyledDynamicCtaButton,
  StyledHeadingMain,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledScreeningPairSvg,
} from 'Components/Screener/style'
import ContinueIcon from 'Components/Shared/Icon/ContinueButtonIcon'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Col, Container, Row } from 'styled-bootstrap-grid'

const Icons = {
  ScreeningPairSvg: ScreeningPairSvg,
  GreatSvg: GreatSvg,
  IncomeIcon: IncomeIcon,
  Savings: Savings,
  Cost: Cost,
  Finally: Finally,
}
const SectionIntroduction = (props) => {
  const {
    navigation,
    questionText,
    secondP,
    questionDirectionText,
    handleNavigationButtonClick,
    stepNumber,
    icon,
  } = props

  const {
    nextButton: { buttonText: nextButtonText },
  } = navigation

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  const StyledDynamicCtaButtonComp = useMemo(() => {
    return () => {
      return (
        <StyledDynamicCtaButton
          data-testid='ctaButton'
          width='100%'
          name={'next'}
          type={'button'}
          variant={'contained'}
          $hasExtraButton={false}
          onClick={() => {
            handleNavigationButtonClick(stepNumber, 'nextButton')
          }}
        >
          {nextButtonText || 'continue'}
          <ContinueIcon />
        </StyledDynamicCtaButton>
      )
    }
  }, [])

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='10'>
          <StyledDiv>
            {questionText && (
              <StyledParagraph
                introduction
                marginBottom='0.6875rem'
                textalign='center'
              >
                {questionText}
              </StyledParagraph>
            )}
            {questionDirectionText && (
              <StyledHeadingMain textalign='center' $isOverWrap={true}>
                {questionDirectionText}
              </StyledHeadingMain>
            )}
            <StyledScreeningPairSvg
              margin='auto auto 4rem'
              alt=''
              src={Icons[icon]}
            />
            {secondP && (
              <StyledParagraph
                width='80%'
                textalign='center'
                fontSize='1.125rem'
                fontWeight='300'
                marginBottom='1.375rem'
              >
                {secondP}
              </StyledParagraph>
            )}
            {isMobile ? (
              <StyledParentButtonsActionsInMobile>
                <ReverseCol>
                  <StyledParentPreScreenerButton width='100%'>
                    <ScreenerBackButton
                      width='100%'
                      maxWidth={'100%'}
                      margin='1rem 0 0 0'
                    />
                  </StyledParentPreScreenerButton>
                  <StyledParentPreScreenerButton width='100%'>
                    <StyledDynamicCtaButtonComp />
                  </StyledParentPreScreenerButton>
                </ReverseCol>
              </StyledParentButtonsActionsInMobile>
            ) : (
              <StyledTermsAndButtonToContinueWrapper width='80%' margin='auto'>
                <StyledDynamicCtaButtonComp />
                <ScreenerBackButton
                  width='100%'
                  maxWidth={'100%'}
                  margin='1rem 0 0 0'
                />
              </StyledTermsAndButtonToContinueWrapper>
            )}
          </StyledDiv>
        </Col>
      </Row>
    </Container>
  )
}

export default SectionIntroduction
