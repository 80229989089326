import React from 'react'

import { StyledActionBar } from './style'

interface ActionBarProps {
  children: React.ReactNode
  actionType?: 'ACTIONS' | 'HEADER' | 'MENU'
  callback?: () => void
  backgroundColor?: string
  padding?: string
  borderRadius?: string
}
const ActionBar = ({
  children,
  actionType,
  callback,
  backgroundColor,
  padding,
  borderRadius,
}: ActionBarProps) => {
  return (
    <StyledActionBar
      padding={padding || '0 1.6rem'}
      actionType={actionType}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      callback={callback}
    >
      {children}
    </StyledActionBar>
  )
}

export default ActionBar
