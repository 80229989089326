import React from 'react'
import { LanguageAccommodation } from 'Components/LocalResources/FindHelpResource'
import { outlineLanguagesIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from './LocationInfo/LocationInfo'

interface LanguagesProps {
  languages: LanguageAccommodation[]
}

const Languages = ({ languages }: LanguagesProps) => {
  const languagesArray = Object.values(languages)

  // ToDo: FIND HELP API
  // current implementation gives us an abject containing a `nativeName` key. For example, 'Español or Italiano'
  // Find Help / wrapper simply gives us an (abbreviated) string. For example, 'es' or 'it'
  const content = languagesArray
    .map((language) => language?.nativeName)
    .join(', ')

  return (
    <LocationInfo
      content={content}
      screenReaderLabel={'Spoken Languages'}
      icon={outlineLanguagesIconPath}
    />
  )
}

export default Languages
