import { DEFAULT_LOCATION, DEFAULT_ORGANIZATION } from 'Shared/constants'
import { getLoggedInStates } from 'Shared/helpers'

export const getHomeUrl = ({
  orgSlug,
  locationSlug,
}: {
  orgSlug: string
  locationSlug: string
}) => {
  if (!orgSlug && !locationSlug) return '/'

  if (orgSlug !== DEFAULT_ORGANIZATION && locationSlug !== DEFAULT_LOCATION) {
    return `/${orgSlug}/${locationSlug}`
  }

  return '/'
}

export const isUserLoggedIn = () => {
  const { isClientLoggedIn, isCaseManagerLoggedIn } = getLoggedInStates()

  return isClientLoggedIn || isCaseManagerLoggedIn
}
