import React from 'react'
import SectionContent, {
  ReviewSectionContentProps,
} from 'Components/Review/ReviewSection/ReviewSectionContent'
import {
  StyledCard,
  StyledCardHeader,
} from 'Components/Review/ReviewSection/style'
import FormError from 'Components/Shared/FormError/FormError'

import { formTouched } from '../helpers'

import ActionButton from './ActionButton/ActionButton'

interface ReviewSectionCardProps {
  title: string
  titleIcon: string
  isEditing: boolean
  error?: string
  submitError?: string
  data: ReviewSectionContentProps['data']
  touched: Record<string, string>
  readOnly: boolean
  onClick: () => void
  onCancel: () => void
  onSave: () => void
}
const ReviewSectionCard = ({
  title,
  titleIcon,
  isEditing,
  error,
  submitError,
  data,
  touched,
  readOnly,
  ...buttonProps
}: ReviewSectionCardProps) => (
  <StyledCard>
    <StyledCardHeader
      elementtype='h2'
      title={title}
      avatar={titleIcon}
      action={
        <ActionButton
          {...buttonProps}
          isEditing={isEditing}
          editDisabled={readOnly}
        />
      }
    />
    <FormError error={error ?? submitError} touched={formTouched(touched)} />
    <SectionContent data={data} isEditing={isEditing} />
  </StyledCard>
)

export default ReviewSectionCard
