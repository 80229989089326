import React from 'react'
import MediaQuery from 'react-responsive'
import LocationList from 'Components/LocalResources/LocationsList'
import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

const LocationsList = () => {
  const { showFilterMenu, showMainMenu } = useAppSelector((state) => state.ui)
  const hideMobileContent = showFilterMenu || showMainMenu

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>
        {!hideMobileContent && <LocationList />}
      </MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <LocationList />
      </MediaQuery>
    </>
  )
}

export default LocationsList
