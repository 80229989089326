import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

export const textPrimary = css`
  color: ${theme.palette.primary.dark};
`

export const wd100 = css`
  width: 100%;
`
export const hi100 = css`
  height: 100%;
`

export const SrOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
`

export const SrOnlyLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
`

export const GParentElem = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export const StyledSubTitleElem = css`
  font-size: 1.875rem;
  font-weight: 300;
  margin: 0;
  margin-bottom: 1rem;
  color: ${theme.palette.text.default};
  @media only screen and (max-width: 480px) {
    font-size: 1.375rem;
  }
`

export const GTitleElem = css`
  font-size: 3.125rem;
  font-weight: 700;
  color: ${theme.palette.text.darken};
  margin-top: 0;
  margin-bottom: 0.4em;
  ${() => css`
    span {
      font-weight: 900;
    }
    strong {
      color: #0d8762; // passes https://webaim.org/resources/contrastchecker/
    }
  `};
`

export const GMessageElem = css`
  font-size: 1.125rem;
  line-height: 2;
  color: ${theme.palette.text.default};
  margin-top: 0;
  margin-bottom: 3em;
`

export const dNone = css`
  display: none;
`

export const dBlock = css`
  display: block;
`

export const dFlex = css`
  display: flex;
`

export const dInlineFlex = css`
  display: inline-flex;
`

export const flexRow = css`
  flex-direction: row;
`

export const flexCol = css`
  flex-direction: column;
`

export const flexColR = css`
  flex-direction: column-reverse;
`

export const justifyContentCenter = css`
  justify-content: center;
`

export const justifyContentSB = css`
  justify-content: space-between;
`

export const justifyContentSA = css`
  justify-content: space-around;
`

export const alignItemsCenter = css`
  align-items: center;
`

export const pRelative = css`
  position: relative;
`

export const pAbsolute = css`
  position: absolute;
`

export const pFixed = css`
  position: fixed;
`

export const zeroMargin = css`
  margin: 0;
`
export const zeroPadding = css`
  padding: 0;
`
export const alignText = css`
  ${wd100}
  ${hi100}
${dFlex}
${justifyContentCenter}
${alignItemsCenter}
`
export const alignShapeImage = css`
  ${pAbsolute}
  ${wd100}
${hi100}
`
export const listStyleNone = css`
  list-style: none;
`
