import React from 'react'
import {
  StyledInfoDataName,
  StyledInfoDataValue,
  StyledPersonalInfoDataBox,
} from 'Components/ClientPortal/MyProfile/CustomClientPortalSection/CustomClientPortalSectionStyle'
import { Container } from 'styled-bootstrap-grid'
import { Row } from 'styled-bootstrap-grid'

import {
  CustomInfoWrapper,
  CustomInfoWrapperItems,
} from './CustomClientPortalSection/CustomClientPortalSectionStyle'

export type GenericDataValueArray = {
  editable: unknown
  data: {
    valueDisplays: {
      name: string
      value: string
    }[]
  }
}

const ClientPortalDemographicContent = ({
  data: { valueDisplays },
}: GenericDataValueArray) => {
  return (
    <CustomInfoWrapper>
      <Container className='container--full-width'>
        <Row>
          <CustomInfoWrapperItems>
            <>
              <StyledPersonalInfoDataBox className='fieldWrapper first-row-lg first-column-lg  first-row-md first-column-md'>
                <StyledInfoDataName
                  $isDemographic
                  className='fieldWrapper__label'
                >
                  {valueDisplays[0].name}
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isDemographic
                  className='fieldWrapper__value'
                >
                  {valueDisplays[0].value}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
            </>
            <>
              <StyledPersonalInfoDataBox className='fieldWrapper first-row-lg second-column-lg second-row-md first-column-md'>
                <StyledInfoDataName
                  $isDemographic
                  className='fieldWrapper__label'
                >
                  {valueDisplays[1].name}
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isDemographic
                  className='fieldWrapper__value'
                >
                  {valueDisplays[1].value}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
              <StyledPersonalInfoDataBox className='fieldWrapper second-row-lg second-column-lg third-row-md first-column-md'>
                <StyledInfoDataName
                  $isDemographic
                  className='fieldWrapper__label'
                >
                  {valueDisplays[2].name}
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isDemographic
                  className='fieldWrapper__value'
                >
                  {valueDisplays[2].value}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
            </>
            <>
              <StyledPersonalInfoDataBox className='fieldWrapper first-row-lg third-column-lg first-row-md second-column-md'>
                <StyledInfoDataName
                  $isDemographic
                  className='fieldWrapper__label'
                >
                  {valueDisplays[3].name}
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isDemographic
                  className='fieldWrapper__value'
                >
                  {valueDisplays[3].value}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
              <StyledPersonalInfoDataBox className='fieldWrapper second-row-lg third-column-lg second-row-md second-column-md'>
                <StyledInfoDataName
                  $isDemographic
                  className='fieldWrapper__label'
                >
                  {valueDisplays[4].name}
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isDemographic
                  className='fieldWrapper__value'
                >
                  {valueDisplays[4].value}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
            </>
          </CustomInfoWrapperItems>
        </Row>
      </Container>
    </CustomInfoWrapper>
  )
}

export default ClientPortalDemographicContent
