import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'
import theme from 'Shared/Theme/ssTheme'

import {
  ClientInfoContactItemIcon,
  ClientInfoContactItemLabel,
  ClientInfoContactItemWrapper,
} from './style'

export const ClientInfoContactItem = ({
  icon,
  content,
  verified,
  isPhoneNumber,
  screenReaderLabel,
}) => {
  return (
    <ClientInfoContactItemWrapper>
      <ClientInfoContactItemIcon>
        <Icon
          fill={theme.palette.primary.dark}
          scale={'1.8rem'}
          viewBox={'0 0 21.904 21.904'}
          width={'1.375rem'}
          screenReaderLabel={screenReaderLabel}
        >
          {icon}
        </Icon>
      </ClientInfoContactItemIcon>
      <ClientInfoContactItemLabel phoneNumber={isPhoneNumber}>
        {content}
      </ClientInfoContactItemLabel>
      {isPhoneNumber && content !== 'N/A' && (
        <UserVerificationStatusIcon verified={verified} />
      )}
    </ClientInfoContactItemWrapper>
  )
}

export default ClientInfoContactItem
