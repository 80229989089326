import { useLocation, useNavigate } from 'react-router-dom'
import { submitFollowups } from 'Components/Outcome/helpers'
import { selectShouldLoopForward } from 'Components/Outcome/selectors'
import { FORM_ERROR } from 'final-form'
import { incrementOutcomeIndex } from 'Reducers/outcome'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'

const useHandleLoop = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return () => (dispatch, getState) => {
    if (selectShouldLoopForward(getState())) {
      dispatch(incrementOutcomeIndex())
      navigate(`../response`, { state: { from: location.pathname } })
    } else {
      return dispatch(submitFollowups())
        .then(() => {
          navigate(`../thank-you`)
        })
        .catch((error) => {
          console.error(error)
          return { [FORM_ERROR]: ERROR_GENERIC_MESSAGE }
        })
    }
  }
}

export default useHandleLoop
