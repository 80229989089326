import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import {
  CLEAR_FILTERED_HOUSEHOLD_MEMBERS,
  CLEAR_SCREENER,
  DECREMENT_HOUSEHOLD_MEMBERS_INDEX,
  INCREMENT_CATEGORY_INDEX,
  INCREMENT_HOUSEHOLD_MEMBERS_INDEX,
  RESET_CATEGORY_INDEX,
  RESET_CATEGORY_OFFSET,
  RESET_HOUSEHOLD_MEMBERS_INDEX,
  RESET_RETURN_STEP,
  UPDATE_CATEGORY_INDEX,
  UPDATE_CATEGORY_OFFSET,
  UPDATE_CURRENT_STEP,
  UPDATE_FILTERED_HOUSEHOLD_MEMBERS,
  UPDATE_HAS_CUSTOM_QUESTIONS,
  UPDATE_HOUSEHOLD_MEMBERS_INDEX,
  UPDATE_INITIAL_VALUES,
  UPDATE_PROGRESS,
  UPDATE_RETURN_STEP,
  UPDATE_SCREENER_QUESTIONS,
  UPDATE_SCREENING_LOCATION,
  UPDATE_SCREENING_ORGANIZATION,
} from '../Actions/screener'

import { screener as initialState } from './initialState'

export default (state = initialState, action) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.screener,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case CLEAR_FILTERED_HOUSEHOLD_MEMBERS:
      return {
        ...state,
        filteredHouseholdMembers: null,
      }

    case DECREMENT_HOUSEHOLD_MEMBERS_INDEX:
      return {
        ...state,
        householdMembersIndex:
          state.householdMembersIndex > 0 ? state.householdMembersIndex - 1 : 0,
      }

    case INCREMENT_CATEGORY_INDEX:
      return {
        ...state,
        categoryIndex: state.categoryIndex + 1,
      }

    case INCREMENT_HOUSEHOLD_MEMBERS_INDEX:
      return {
        ...state,
        householdMembersIndex: state.householdMembersIndex + 1,
      }

    case RESET_CATEGORY_INDEX:
      return {
        ...state,
        categoryIndex: 0,
      }

    case RESET_CATEGORY_OFFSET:
      return {
        ...state,
        categoryOffset: 0,
      }

    case RESET_HOUSEHOLD_MEMBERS_INDEX:
      return {
        ...state,
        householdMembersIndex: 0,
      }

    case RESET_RETURN_STEP:
      return {
        ...state,
        returnStep: null,
      }

    case UPDATE_CATEGORY_INDEX:
      return {
        ...state,
        categoryIndex: action.categoryIndex,
      }

    case UPDATE_CATEGORY_OFFSET:
      return {
        ...state,
        categoryOffset: action.categoryOffset + state.categoryOffset,
      }

    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.step,
      }

    case UPDATE_FILTERED_HOUSEHOLD_MEMBERS:
      return {
        ...state,
        filteredHouseholdMembers: action.householdMembers,
      }

    case UPDATE_HAS_CUSTOM_QUESTIONS:
      return {
        ...state,
        hasCustomQuestions: action.hasCustomQuestions,
      }

    case UPDATE_HOUSEHOLD_MEMBERS_INDEX:
      return {
        ...state,
        householdMembersIndex: action.householdMembersIndex,
      }

    case UPDATE_SCREENER_QUESTIONS:
      return {
        ...state,
        stepsByNumber: action.questions,
        steps: Object.keys(action.questions),
      }

    case UPDATE_INITIAL_VALUES:
      return {
        ...state,
        initialValues: action.initialValues,
      }

    case UPDATE_RETURN_STEP:
      return {
        ...state,
        returnStep: action.returnStep,
      }

    case UPDATE_SCREENING_LOCATION:
      return {
        ...state,
        location: action.screeningLocation,
      }

    case UPDATE_SCREENING_ORGANIZATION:
      return {
        ...state,
        organization: action.screeningOrganization,
      }
    case UPDATE_PROGRESS:
      return {
        ...state,
        progressStatus: action.progressStatus,
      }
    case CLEAR_SCREENER:
      return initialState

    default:
      return state
  }
}
