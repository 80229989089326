import React from 'react'
import { useMediaQuery } from 'react-responsive'
import AnimatingCircleIcon from 'Components/LandingPage/Components/AnimatingCircleIcon'
import { StyledAnimatingLensBox } from 'Components/LandingPage/Styles/FeaturesText'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Col, Container } from 'styled-bootstrap-grid'

import AnimatingLens from '../Components/AnimatingLens'
import { FeaturesText } from '../Components/FeaturesText'
import { LandingRow, LandingSection } from '../Styles/LandingSection'

import 'Components/Uielements/styles/globalStyle.css'

const BenefitsSection = () => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  return (
    <LandingSection light Benefits>
      <Container fluid>
        <LandingRow>
          <Col lg='6'>
            <FeaturesText
              Benefits
              sectionTop={'-7rem'}
              topBtn={'-7rem'}
              topBtnTab={'-10rem'}
              widthMessage={'75%'}
              marginSmallerMessage={'0'}
              dirLTR
              subTitle='Browse all benefits & resources'
              title='I am looking for...'
              message='Quickly browse our vast network of providers for the benefits and resources you need now.'
            ></FeaturesText>
          </Col>
          {!isMobile && (
            <Col lg='6' className='col-no-padding'>
              <AnimatingCircleIcon />
              <AnimatingLens />
            </Col>
          )}
        </LandingRow>
        <LandingRow>
          <Col lg='6' lgOffset='1'>
            <FeaturesText
              Benefits
              moveBtn={'true'}
              footerMarginSmaller={'0'}
              topBtn={'-12rem'}
              action1='Browse Resources'
              target1='/locations/list'
              action2='About Us'
              target2='https://singlestop.org'
              target2External={true}
            ></FeaturesText>
          </Col>
        </LandingRow>
        <LandingRow>
          {isMobile && (
            <Col className='col-no-padding'>
              <StyledAnimatingLensBox>
                <AnimatingCircleIcon />
                <AnimatingLens />
              </StyledAnimatingLensBox>
            </Col>
          )}
        </LandingRow>
      </Container>
    </LandingSection>
  )
}

export default BenefitsSection
