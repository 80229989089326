import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SoliderElem from 'Components/LandingPage/Assets/SoliderElem.svg'
import HeaderWithContainerAndCard from 'Components/Shared/HeaderWithContainerAndCard/HeaderWithContainerAndCard'
import { SrOnly } from 'Components/Uielements/styles/helpers.styles'
import { Col, Row } from 'styled-bootstrap-grid'

import { isUserLoggedIn } from './helpers'
import {
  StyledButton,
  StyledContainer,
  StyledElmImg,
  StyledFigure,
  StyledParagraph,
  StyledParentImg,
  StyledTitle,
} from './style'

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const hasLoggedOut = localStorage.getItem('hasLoggedOut')

    if (isUserLoggedIn() || !hasLoggedOut) {
      navigate('/login')
    } else {
      // Remove the flag once the user has seen the logout message
      localStorage.removeItem('hasLoggedOut')
    }
  }, [])
  return (
    <HeaderWithContainerAndCard padding='0'>
      <StyledContainer>
        <Row alignItems='center'>
          <Col lg='6' lgOrder='1'>
            <StyledTitle data-testid='logout-title'>
              You Have Successfully <br /> Logged Out
            </StyledTitle>
            <StyledParagraph>
              Thank you for visiting! You have successfully logged out of your
              account. We hope to see you again soon.
            </StyledParagraph>
            <StyledButton primary={true} as={Link} to={'/'} target={'_self'}>
              Back To Home
            </StyledButton>
          </Col>
          <Col lg='6' order='-1' lgOrder='2' className='col-no-padding'>
            <StyledFigure>
              <StyledParentImg>
                <StyledElmImg src={SoliderElem} alt='Logged Out' />
              </StyledParentImg>
              <SrOnly as='figcaption'>You Have Successfully Logged Out</SrOnly>
            </StyledFigure>
          </Col>
        </Row>
      </StyledContainer>
    </HeaderWithContainerAndCard>
  )
}

export default Logout
