import React from 'react'
import {
  CustomInfoWrapper,
  CustomInfoWrapperItemHolder,
  CustomInfoWrapperItems,
  StyledConsentDescription,
  StyledConsentTitle,
  StyledContactConsentWrapper,
  StyledInfoDataName,
  StyledInfoDataValue,
  StyledPersonalInfoDataBox,
} from 'Components/ClientPortal/MyProfile/CustomClientPortalSection/CustomClientPortalSectionStyle'
import CustomQuestionField from 'Components/CustomQuestions/CustomQuestionField/CustomQuestionField'
import { INPUT_TYPE_STRING } from 'Components/CustomQuestions/CustomQuestionField/Inputs/constants'
import UserVerificationStatusIcon from 'Components/Shared/Icon/UserVerificationStatusIcon'
import { Container, Row } from 'styled-bootstrap-grid'

import ContactConsentRadioGroup from '../Shared/ContactConsentRadioGroup'

/**
 * Client Portal Contact Info Section Contents are rendered in this component
 *
 * @param {{data:{valueDisplays:Array.<{name:string,value:string}>},isEditing:boolean}} param0
 * @returns {ClientPortalSectionContent}
 */
/**
 * Value pair component used in Review Sections
 *
 * @param {{valueDisplays:Array, isEditing:boolean}} param0
 * @returns
 */

type ValueDisplay = {
  id: string
  name: string
  value: string
  verified: boolean
  editable: boolean
  dataType: string
  validator: (value: string) => string | undefined
  maxLength: number
  inputTypePhone: boolean
}

type ClientPortalContactLoginContentProps = {
  data: {
    valueDisplays: ValueDisplay[]
  }
  isEditing: boolean
}

function loginInfo(login: ValueDisplay, contact: ValueDisplay): string {
  return login?.value === 'Not Available' ? contact?.value : login?.value
}

const ClientPortalContactLoginContent = ({
  data: { valueDisplays },
  isEditing,
}: ClientPortalContactLoginContentProps) => {
  const [login, contact, phone, email, consent] = valueDisplays
  return (
    <CustomInfoWrapper>
      <Container className='container--full-width'>
        <Row>
          <CustomInfoWrapperItems className='CustomInfoWrapperItems__twoColumns'>
            <CustomInfoWrapperItemHolder>
              <StyledPersonalInfoDataBox className='fieldWrapper fieldWrapper--custom-margin'>
                <StyledInfoDataName
                  $isContactLogin
                  className='fieldWrapper__label'
                >
                  Login
                </StyledInfoDataName>
                <StyledInfoDataValue
                  $isContactLogin
                  className='fieldWrapper__value'
                >
                  {login?.value === 'Not Available' ? (
                    ''
                  ) : (
                    <UserVerificationStatusIcon verified={login.verified} />
                  )}
                  {contact?.value === 'Not Available' ? (
                    ''
                  ) : (
                    <UserVerificationStatusIcon verified={contact.verified} />
                  )}
                  {loginInfo(login, contact)}
                </StyledInfoDataValue>
              </StyledPersonalInfoDataBox>
              {isEditing ? (
                <>
                  {phone.editable && (
                    <CustomQuestionField
                      className='inputWrapper inputWrapper--custom-margin'
                      name={phone.id}
                      label={phone.name}
                      dataType={
                        phone.dataType ? phone.dataType : INPUT_TYPE_STRING
                      }
                      validator={phone.validator}
                      maxLength={phone.maxLength}
                      inputTypePhone={phone.inputTypePhone}
                    />
                  )}
                </>
              ) : (
                <StyledPersonalInfoDataBox className='fieldWrapper fieldWrapper--custom-margin'>
                  {phone.value !== 'Not Available' && (
                    <StyledPersonalInfoDataBox className='fieldWrapper'>
                      <StyledInfoDataName
                        $isContactLogin
                        className='fieldWrapper__label'
                      >
                        {phone.name}
                      </StyledInfoDataName>
                      <StyledInfoDataValue
                        $isContactLogin
                        className='fieldWrapper__value'
                      >
                        <UserVerificationStatusIcon verified={phone.verified} />
                        {phone?.value}
                      </StyledInfoDataValue>
                    </StyledPersonalInfoDataBox>
                  )}
                </StyledPersonalInfoDataBox>
              )}
              {isEditing ? (
                <>
                  {email.editable && (
                    <CustomQuestionField
                      className='inputWrapper'
                      name={email.id}
                      label={email.name}
                      dataType={
                        email.dataType ? email.dataType : INPUT_TYPE_STRING
                      }
                      validator={email.validator}
                      maxLength={email.maxLength}
                      inputTypePhone={email.inputTypePhone}
                    />
                  )}
                </>
              ) : (
                <>
                  {email.value !== 'Not Available' && (
                    <StyledPersonalInfoDataBox className='fieldWrapper'>
                      <StyledInfoDataName
                        $isContactLogin
                        className='fieldWrapper__label'
                      >
                        {email.name}
                      </StyledInfoDataName>
                      <StyledInfoDataValue
                        $isContactLogin
                        className='fieldWrapper__value'
                      >
                        <UserVerificationStatusIcon verified={email.verified} />
                        {email?.value}
                      </StyledInfoDataValue>
                    </StyledPersonalInfoDataBox>
                  )}
                </>
              )}
            </CustomInfoWrapperItemHolder>
            <CustomInfoWrapperItemHolder>
              {/* Consent */}
              <StyledContactConsentWrapper>
                <StyledConsentTitle>Consent to be Contacted</StyledConsentTitle>
                <StyledConsentDescription>
                  I consent to be contacted at{' '}
                  <span className='consent__email-info'>
                    {' '}
                    {loginInfo(login, contact)}
                  </span>{' '}
                  for outcomes updates and with information about new programs
                  and services that I may be interested in.
                </StyledConsentDescription>
                {isEditing ? (
                  <>
                    {consent.editable && (
                      <CustomQuestionField
                        className='radioFieldWrapper'
                        name={consent.id}
                        dataType={
                          consent.dataType
                            ? consent.dataType
                            : INPUT_TYPE_STRING
                        }
                        disabledYesNoQuestion={false}
                        booleanInputComponent={ContactConsentRadioGroup}
                      />
                    )}
                  </>
                ) : (
                  <StyledInfoDataValue className='StyledInfoDataValue__label'>
                    {consent.value === 'true'
                      ? "It's ok to contact me."
                      : 'Do not contact me.'}
                  </StyledInfoDataValue>
                )}
              </StyledContactConsentWrapper>
            </CustomInfoWrapperItemHolder>
          </CustomInfoWrapperItems>
        </Row>
      </Container>
    </CustomInfoWrapper>
  )
}

export default ClientPortalContactLoginContent
