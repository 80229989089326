import { Button, Grid, withStyles } from '@material-ui/core'
import theme from 'Shared/Theme/ssTheme'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const NavParent = styled.div`
  background-color: ${theme.palette.secondary.default};
`
export const NavSubParent = styled.div`
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: ${theme.palette.color.white};
  ${media.smaller`
    border-radius: unset;
  `}
`

export const StyledButtonGrid = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'auto',
    padding: '0',
    scrollbarWidth: 'thin',
    scrollbarColor: '#babac0 #fff',
    '&::-webkit-scrollbar': {
      height: '.3rem',
      display: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      border: 'unset',
    },
  },
})(Grid)

export const StyledButtonGridItem = withStyles({
  root: {
    flexShrink: 0,
  },
})(Grid)

export const StyledReviewButtonParent = styled.div`
  background-color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 1rem 0;
`

export const StyledButton = withStyles({
  root: {
    fontSize: '0.75rem',
    margin: '0 2.625rem 0 0',
    minWidth: 'auto',
    fontFamily: 'Noto Sans HK,sans-serif',
    fontWeight: '400',
    lineHeight: '0',
    textTransform: 'capitalize',
    padding: '1rem 8px',
    '& img': {
      width: '1.875rem',
      height: '1.875rem',
    },
    '&:hover': {
      backgroundColor: 'unset',
      color: theme.palette.primary.dark,
    },
    '@media (max-width: 576px)': {
      margin: '0 5px 0 0px',
      padding: '1.5625rem 1.5625rem 1.5625rem 0',
      fontSize: '0.875rem',
      fontWeight: '500',
    },
  },
})(Button)

export const StyledContinueButtonGridItem = withStyles({
  root: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: '7px',
    padding: '1.25rem 1.75rem',
    color: theme.palette.primary.dark,
    margin: '0 0 0 2rem',
    right: '0.625rem',
    height: 'max-content',
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    '@media (max-width: 576px)': {
      position: 'relative',
      width: '90%',
      zIndex: '9',
      margin: 'auto',
      backgroundColor: theme.palette.color.white,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '1.5rem 1.625rem',
      '& img': {
        height: '1rem',
        right: '1rem',
      },
    },
    '@media (min-width: 1200px)': {
      marginLeft: 'auto',
    },
    '&:hover, &:focus': {
      boxShadow:
        '0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%)',
    },
  },
})(StyledButton)
