import React from 'react'
import { connect } from 'react-redux'
import { outlineLocationIconPath } from 'Shared/Icons/LocationInfo/paths'

import LocationInfo from '../LocationInfo/LocationInfo'

const Distance = (props) => {
  const { address, distance, units } = props
  const formattedDistance = distance
    ? `${distance.toFixed(2)} ${units} away`
    : null

  const renderAddress = (address) => {
    if (!address) {
      return null
    }

    let addressArray = []
    const { addressLine1, addressLine2, city, state, zipCode } = address

    if (addressLine1) addressArray.push(addressLine1)
    if (addressLine2) addressArray.push(addressLine2)
    if (city) addressArray.push(city)
    if (state) addressArray.push(state)
    if (zipCode) addressArray.push(zipCode)
    return addressArray.join(', ')
  }

  return (
    <>
      {formattedDistance && (
        <LocationInfo
          alignitems={props.alignitems}
          content={formattedDistance}
          icon={outlineLocationIconPath}
          screenReaderLabel={'Location'}
        />
      )}
      {address && (
        <LocationInfo
          $isAddress
          alignitems={props.alignitems}
          content={renderAddress(address)}
          icon={distance ? null : outlineLocationIconPath}
          screenReaderLabel={'Location'}
        />
      )}
    </>
  )
}

const stateToProps = (state) => {
  const { unit } = state.filters.distance
  const units = unit === 'mi' ? 'miles' : 'kilometers'
  return {
    units: units,
  }
}

export default connect(stateToProps)(Distance)
