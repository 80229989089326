import {
  CalculateScreeningResultsMutation,
  ServiceCase,
} from '__generated__/graphql'

export const formattedReferralNumber = (referralNumber: string) => {
  const pairChunks = referralNumber.match(/.{1,2}/g)
  return pairChunks?.join(' ')
}

export const calculateTotalBenefitAmount = (
  data?: CalculateScreeningResultsMutation
) => {
  if (!data) return 0
  const cases = data?.calculateScreeningResults?.screening?.serviceCases ?? []
  return cases
    .map((c) => c.eligibleAmount as number)
    .reduce((acc, curr) => acc + curr, 0)
}

export const calculateRecurringBenefitAmount = (serviceCase: ServiceCase) => {
  const eligibleAmount = serviceCase?.eligibleAmount
  const frequency = serviceCase?.service?.frequency

  if (!eligibleAmount) return eligibleAmount
  switch (frequency) {
    case 'WEEKLY':
      return eligibleAmount / 52

    case 'MONTHLY':
      return eligibleAmount / 12

    default:
      return eligibleAmount
  }
}
