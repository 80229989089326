import React, { ChangeEvent } from 'react'
import { NumericFormat } from 'react-number-format'
import InputAdornment from '@material-ui/core/InputAdornment'
import { isCaseManagementPage } from 'Shared/helpers'

import { StyledErrorSpan, StyledTextField } from './style'

export type FormatCustomProps = {
  inputRef: ((el: HTMLInputElement) => void) | undefined
  decimalScale: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}
const FormatCustom = (props: FormatCustomProps) => {
  const { inputRef, decimalScale, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
    />
  )
}

const NumberFormatCustom = (props: FormatCustomProps) => (
  <FormatCustom {...props} decimalScale={2} />
)

const IntegerFormatCustom = (props: FormatCustomProps) => (
  <FormatCustom {...props} decimalScale={0} />
)

export type InputRenderFieldProps = {
  disabled?: boolean
  input: {
    name: string
    value: string
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
  }
  label: string
  meta: {
    touched: boolean
    error: string
  }
  numberFormat?: 'integer' | 'currency'
  type?: string
  charLimit?: number
  ariaLabel?: string
  dataTestId?: string
  autoComplete?: string
  prefix?: string
  placeholder?: string
}

const InputRenderField = (props: InputRenderFieldProps) => {
  const {
    disabled,
    input,
    label,
    meta: { touched, error },
    numberFormat,
    type,
    charLimit,
    ariaLabel,
    dataTestId,
    prefix,
    placeholder,
  } = props

  const getHelperText = () => {
    if (touched && error) {
      return (
        <span
          data-testid={'inputRenderFieldError'}
          aria-label={`Error: ${error}`}
          aria-live='polite'
        >
          {error ? (
            <StyledErrorSpan role='alert'>{error}</StyledErrorSpan>
          ) : (
            <StyledErrorSpan role='alert' aria-hidden='true'>
              {error}
            </StyledErrorSpan>
          )}
        </span>
      )
    }
    if (charLimit && charLimit - input.value.length < 20 && touched) {
      return <span>{charLimit - input.value.length} characters remaining</span>
    }
  }

  const customInputProps = () => {
    if (numberFormat === 'integer')
      return {
        inputComponent: IntegerFormatCustom,
        startAdornment: (
          <InputAdornment position='start'>{prefix}</InputAdornment>
        ),
      }

    if (numberFormat === 'currency')
      return {
        inputComponent: NumberFormatCustom,
        startAdornment: <InputAdornment position='start'>$</InputAdornment>,
      }

    return {}
  }

  return (
    <StyledTextField
      type={type}
      fullWidth
      id={input.name}
      label={label}
      variant='outlined'
      placeholder={placeholder}
      disabled={disabled}
      autoComplete={props.autoComplete ? props.autoComplete : input.name}
      error={touched && !!error}
      required={!!isCaseManagementPage()}
      helperText={getHelperText()}
      InputProps={customInputProps()}
      inputProps={{
        maxLength: numberFormat === 'currency' ? '10' : charLimit,
        'aria-label': ariaLabel,
        'data-testid': dataTestId,
      }}
      {...input}
    />
  )
}

export default InputRenderField
