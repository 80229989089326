import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { StyledTableBody } from 'Components/Review/ReviewSection/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { ITableDisplay } from '../../..'

import ReviewSectionTableRow from './ReviewSectionTableRow'

interface ReviewSectionTableBodyProps {
  rows: ITableDisplay['rows']
  labels: ITableDisplay['labels']
}
const ReviewSectionTableBody = ({
  rows,
  labels,
}: ReviewSectionTableBodyProps) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })
  return (
    <>
      {isMobile && (
        <>
          {rows?.map((row, i) => (
            <ReviewSectionTableRow key={i} row={row} labels={labels} />
          ))}
        </>
      )}
      {!isMobile && (
        <StyledTableBody>
          {rows?.map((row, i) => (
            <ReviewSectionTableRow key={i} row={row} labels={labels} />
          ))}
        </StyledTableBody>
      )}
    </>
  )
}

export default ReviewSectionTableBody
