import Surface from 'Components/Shared/Surface/Surface'
import theme from 'Shared/Theme/ssTheme'
import styled, { css } from 'styled-components'

export const StyledInput = styled.input`
  font-size: 1.6rem;
  flex-grow: 1;
  padding: 0rem 0.4em;
  &:focus {
    outline: unset;
  }
  border: none;
`

export const StyledSearchBox = styled.div`
  margin-right: 0.5rem;
  position: relative;
`

export const StyledLabelSearchBox = styled.label<{ hasError: boolean }>`
  display: flex;
  width: 100%;

  ${(props) =>
    props.hasError &&
    css`
      & > label {
        color: ${theme.palette.error} !important;
        &:focus {
          color: ${theme.palette.error};
        }
      }
      & > button {
        background-color: ${theme.palette.error};
        &:focus {
          background-color: ${theme.palette.error};
        }
        &:hover {
          background-color: ${theme.palette.error};
        }
      }
    `}
`

export const StyledSurface = styled(Surface)``
StyledSurface.displayName = 'StyledSurface'
StyledInput.displayName = 'StyledInput'
StyledSearchBox.displayName = 'StyledSearchBox'
StyledLabelSearchBox.displayName = 'StyledLabelSearchBox'
