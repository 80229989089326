import React from 'react'
import { StyledCardContent } from 'Components/Review/ReviewSection/style'

import ReviewSectionsTables from './ReviewSectionTables'
import ReviewSectionValues from './ReviewSectionValues'

export interface IValueDisplay {
  name: string
  value: string
  verified?: boolean
  editable?: boolean
  displayValue?: string
  format?: string
  required?: boolean
  id: string
  options?: Array<string>
  validator?: (value: string) => string | undefined
  parse?: string
  maxLength?: number
  inputTypePhone?: boolean
  dataType?: string
}
export interface ITableDisplay {
  name: string
  labels: string[]
  rows: [number[][], number[], number[][]]
}
export interface ReviewSectionContentProps {
  data: {
    valueDisplays: IValueDisplay[]
    tableDisplays: ITableDisplay[]
  }
  isEditing: boolean
}
const ReviewSectionContent = ({
  data: { valueDisplays, tableDisplays },
  isEditing,
}: ReviewSectionContentProps) => (
  <StyledCardContent>
    {valueDisplays && (
      <ReviewSectionValues
        valueDisplays={valueDisplays}
        isEditing={isEditing}
      />
    )}
    {tableDisplays && (
      <ReviewSectionsTables
        tableDisplays={tableDisplays}
        isEditing={isEditing}
      />
    )}
  </StyledCardContent>
)

export default ReviewSectionContent
