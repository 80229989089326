import { StyledATag } from 'Components/LocalResources/LocationCard/style'
import styled, { css } from 'styled-components'

interface StyledLocationInfoProps {
  showMore?: boolean
  alignitems?: string
}
export const StyledLocationInfo = styled.div<StyledLocationInfoProps>`
  display: flex;
  align-items: center;
  /* color: ${(props) => props.theme.text.default}; */
  color: #000;
  fill: ${(props) => props.theme.icon.default};
  margin: 0;
  ${({ showMore }) =>
    showMore &&
    css<StyledLocationInfoProps>`
      width: 100%;
      justify-content: flex-start;
      align-items: ${(props) =>
        props.alignitems ? props.alignitems : 'flex-end'};
      &:last-child {
        width: auto;
      }
    `}
`
export const StyledInfo = styled.div``

export const StyledPhoneATag = styled(StyledATag)`
  text-decoration: none;
`

StyledLocationInfo.displayName = 'StyledLocationInfo'
StyledInfo.displayName = 'StyledInfo'
StyledPhoneATag.displayName = 'StyledPhoneATag'
