import React, { ReactNode } from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { StyledContentContainer, StyledWrapper } from 'Components/Outcome/style'
import Header from 'Components/Shared/SimpleHeaderWithoutNav/SimpleHeaderWithoutNav'
import { ssTheme } from 'Shared/Theme/ssTheme'

type OutcomePageTemplateProps = {
  children: ReactNode
  logo: string | null
  radioContainer?: boolean
}

const OutcomePageTemplate = ({
  children,
  logo,
  radioContainer,
}: OutcomePageTemplateProps) => (
  <MuiThemeProvider theme={ssTheme}>
    <Header logo={logo ?? undefined} />
    <StyledWrapper>
      <StyledContentContainer $radioContainer={radioContainer}>
        {children}
      </StyledContentContainer>
    </StyledWrapper>
  </MuiThemeProvider>
)

export default OutcomePageTemplate
