export const handlePreventUserTypeLetters = (
  evt: React.KeyboardEvent<HTMLInputElement>
) => {
  const charCode = evt.key.charCodeAt(0)
  if (
    (charCode < 48 || charCode > 57) && // Not a number
    evt.key !== 'Backspace' && // Allow backspace
    evt.key !== 'ArrowLeft' && // Allow left arrow
    evt.key !== 'ArrowRight' && // Allow right arrow
    evt.key !== 'Delete' && // Allow delete
    evt.key !== 'Tab' // Allow tab
  ) {
    evt.preventDefault()
    return true
  } else {
    return false
  }
}
