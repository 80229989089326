import React from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import ScreenerBackButton from 'Components/Screener/ScreenerBackButton'
import {
  ReverseCol,
  StyledHeadingMain,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
} from 'Components/Screener/style'
import { StyledMuiTextFieldWrapper } from 'Components/Shared/ReduxForm/style'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { Row } from 'styled-bootstrap-grid'

import { handleHouseholdMemberYesOrNoQuestions } from '../../Actions/screener'

import CallsToAction from './CallsToAction'

const YesNoQuestion = (props) => {
  const { questionText, questionDirectionText } = props
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  return (
    <>
      {questionText && (
        <StyledHeadingMain $isOverWrap={true}>{questionText}</StyledHeadingMain>
      )}
      {questionDirectionText && (
        <StyledParagraph>{questionDirectionText}</StyledParagraph>
      )}

      <StyledMuiTextFieldWrapper
        width='60%'
        marginRight='auto'
        marginLeft='auto'
        mbottom='0'
        justCont='space-between'
      >
        <CallsToAction {...props} margin='0' />
      </StyledMuiTextFieldWrapper>

      {!isMobile && (
        <Row>
          <ScreenerBackButton width='180px' margin='0' />
        </Row>
      )}
      {isMobile && (
        <StyledParentButtonsActionsInMobile>
          <ReverseCol>
            <ScreenerBackButton
              width='100%'
              maxWidth='100%'
              smallerMargin='0'
            />
          </ReverseCol>
        </StyledParentButtonsActionsInMobile>
      )}
    </>
  )
}

const mapDispatchToProps = {
  handleHouseholdMemberYesOrNoQuestions,
}

export default connect(null, mapDispatchToProps)(YesNoQuestion)
