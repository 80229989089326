import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import { isSpacebarOrEnterKey } from 'Shared/helpers'

import {
  StyledDesktopAndTabletTopNavMenuItem,
  StyledDesktopAndTabletTopNavMenuItemLabel,
} from './style'

const TopNavLink = (props) => {
  const {
    text,
    activelink,
    color,
    path,
    onClickHandler,
    attr,
    addClient,
    iconPath,
    disabled,
    role,
  } = props

  return (
    <StyledDesktopAndTabletTopNavMenuItem
      role={role}
      data-testid='desktopAndTabletTopNavMenuItem'
      disabled={disabled}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      padding={props.padding}
      position={props.position}
      flexdirectton={'row'}
      alignitems={'center'}
      {...attr}
      onClick={(e) => {
        if (!onClickHandler) return
        disabled ? e.preventDefault() : onClickHandler(e)
      }}
      onKeyDown={(e) => {
        if (isSpacebarOrEnterKey(e.keyCode)) {
          if (!onClickHandler) return
          disabled ? e.preventDefault() : onClickHandler(e)
        }
      }}
      color={color}
      activelink={activelink ? 'active' : 'inactive'}
      to={path}
      addclient={addClient ? 'true' : undefined}
    >
      <Icon
        position={props.iconPosition}
        left={props.iconLeft}
        fill={'rgba(0,0,0, 0.65)'}
        radius={'80rem'}
        scale={props.iconScale ? props.iconScale : '2rem'}
        display={'inline-flex'}
        padding={'0 0.256rem'}
        margin={'0 0.2rem 0 0'}
        viewBox={'0 0 24 24'}
        cursorOnHover={disabled ? 'default' : 'pointer'}
      >
        {iconPath}
      </Icon>
      <StyledDesktopAndTabletTopNavMenuItemLabel
        disabled={disabled}
        color={color}
        addClient={addClient}
        padding={'0 3rem 0 0'}
        fontWeight={props.fontWeight}
      >
        {text}
      </StyledDesktopAndTabletTopNavMenuItemLabel>
    </StyledDesktopAndTabletTopNavMenuItem>
  )
}

export default TopNavLink
