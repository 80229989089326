import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'

const AnimatingCircleIcon = () => {
  return (
    <Icon
      position={'absolute'}
      right={'-12%'}
      top={'-2%'}
      zIndex='9'
      width={'66%'}
      height={'50%'}
      margin={'0'}
      display='inline-flex'
      viewBox={'0 0 460 172'}
    >
      <g opacity='0.7' className='a'>
        <circle className='b' fill='#9bc17f' cx='2' cy='2' r='2' />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(0 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(24 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(48 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(72 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(96 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(120 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(144 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(168 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(192 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(216 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 24)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 48)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 72)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 96)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 120)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 144)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(240 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(264 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(288 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(312 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(336 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(360 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(384 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(408 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(432 168)'
        />
        <circle
          className='b'
          fill='#9bc17f'
          cx='2'
          cy='2'
          r='2'
          transform='translate(456 168)'
        />
      </g>
    </Icon>
  )
}

export default AnimatingCircleIcon
