export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
export const LOAD_INITIAL_LOCATIONS = 'LOAD_INITIAL_LOCATIONS'
export const UPDATE_LOCATION_DETAIL = 'UPDATE_LOCATION_DETAIL'

export const fetchLocationDetails = (id) => (dispatch, getState) => {
  const { searchResults: locations } = getState().locations

  //ToDo - FIND HELP API - id is a number. It should probably be a string
  const filtered = locations[id]

  if (filtered) {
    dispatch(updateLocationDetail(id, filtered))
  } else {
    // ToDo FIND HELP POC - query API for location by ID (for hitting a bookmarked location / page directly)
    console.log('query find help API for location detail.')
  }
}

export const loadInitialLocations = (initialLocations) => ({
  type: LOAD_INITIAL_LOCATIONS,
  initialLocations,
})

export const updateLocationDetail = (id, locationDetail) => ({
  type: UPDATE_LOCATION_DETAIL,
  locationDetail,
  id,
})

export const getLocationsFailure = (error) => ({
  type: GET_LOCATIONS_FAILURE,
  error,
})
