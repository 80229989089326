import React from 'react'
import { Button } from '@material-ui/core'
import { SaveIcon, SaveInfoIcon } from 'Components/Review/assets'

import { StyledCancelButtonWrapper, StyledEditButtonWrapper } from './style'

const SaveButton = ({ handleSubmit, handleCancel, isClientPortal }) => (
  <>
    {isClientPortal ? (
      <div>
        <StyledCancelButtonWrapper
          className='cancel-button'
          onClick={handleCancel}
        >
          Cancel
        </StyledCancelButtonWrapper>
        <StyledEditButtonWrapper
          data-testid='saveButton'
          startIcon={SaveInfoIcon}
          onClick={handleSubmit}
        >
          Save Information
        </StyledEditButtonWrapper>
      </div>
    ) : (
      <>
        <Button className='cancel-button' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          data-testid='saveButton'
          startIcon={SaveIcon}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </>
    )}
  </>
)

export default SaveButton
