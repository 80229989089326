import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'Store'

export const selectOutcomeId = (state: RootState) => state.outcome.id

export const selectResponses = (state: RootState) => state.outcome.responses

export const selectCheckedOutcomes = (state: RootState) =>
  state.outcome.selectedOutcomes

export const selectCheckedOutcomeIds = createSelector(
  [selectCheckedOutcomes],
  (selectedOutcomes) => selectedOutcomes.map((outcome) => outcome.id)
)

export const selectOutcomeIndex = (state: RootState) =>
  state.outcome.outcomeIndex

export const selectActiveOutcome = createSelector(
  [selectOutcomeIndex, selectCheckedOutcomes],
  (index, outcomes) => outcomes[index]
)

export const selectShouldLoopForward = createSelector(
  [selectOutcomeIndex, selectCheckedOutcomes],
  (outcomeIndex, selectedOutcomes) => outcomeIndex < selectedOutcomes.length - 1
)

export const selectShouldLoopBackwards = createSelector(
  [selectOutcomeIndex, selectCheckedOutcomes],
  (outcomeIndex, selectedOutcomes) =>
    selectedOutcomes.length > 1 && outcomeIndex > 0
)

export const selectActiveResponse = createSelector(
  [selectOutcomeIndex, selectCheckedOutcomes, selectResponses],
  (outcomeIndex, selectedOutcomes, responses) => {
    const activeOutcome = selectedOutcomes[outcomeIndex]
    return responses[activeOutcome.id]
  }
)

export const selectActiveResponseAmount = createSelector(
  [selectActiveResponse],
  (activeResponse) => activeResponse?.outcome_amount
)

export const selectActiveResponseConfirmationType = createSelector(
  [selectActiveResponse],
  (activeResponse) => activeResponse?.confirmation_type
)

export const selectActiveResponsePositiveConfirmationType = createSelector(
  [selectActiveResponse],
  (activeResponse) => activeResponse?.positive_confirmation_type
)

export const selectActiveResponseNegativeConfirmationType = createSelector(
  [selectActiveResponse],
  (activeResponse) => activeResponse?.negative_confirmation_type
)

export const selectEditDetailRoute = createSelector(
  [selectActiveResponse, selectActiveResponseAmount],
  (activeResponse, activeResponseAmount) => {
    if (activeResponseAmount !== undefined) {
      return 'cash-benefit'
    } else {
      return activeResponse?.positive_confirmation_type
        ? 'confirmation'
        : 'denial'
    }
  }
)
