import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import { Followup } from 'Pages/Outcome/OutcomePage'
import { outcome as initialState } from 'Reducers/initialState/outcome'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

const rememberRehydrated = createAction<{ ui: typeof initialState }>(
  REMEMBER_REHYDRATED
)

const outcomeSlice = createSlice({
  name: 'outcome',
  initialState,
  reducers: {
    decrementOutcomeIndex(state) {
      state.outcomeIndex--
    },
    incrementOutcomeIndex(state) {
      state.outcomeIndex++
    },
    resetAll(state) {
      return initialState
    },
    resetOutcomeIndex(state) {
      state.outcomeIndex = 0
    },
    setConfirmationType(
      state,
      action: PayloadAction<{
        id: string
        confirmation_type:
          | 'yes'
          | 'no'
          | 'unknown'
          | 'not_yet'
          | 'prefer_not_to_say'
      }>
    ) {
      state.responses[action.payload.id] = action.payload
    },
    setId(state, action: PayloadAction<string>) {
      state.id = action.payload
    },
    setNegativeConfirmationType(
      state,
      action: PayloadAction<{
        id: string
        negative_confirmation_type: string
      }>
    ) {
      const { id, negative_confirmation_type } = action.payload
      state.responses[id].negative_confirmation_type =
        negative_confirmation_type
    },
    setOutcomeAmount(
      state,
      action: PayloadAction<{
        id: string
        outcome_amount: string
      }>
    ) {
      const { id, outcome_amount } = action.payload
      state.responses[id].outcome_amount = outcome_amount
    },
    setPositiveConfirmationType(
      state,
      action: PayloadAction<{
        id: string
        positive_confirmation_type: string
      }>
    ) {
      const { id, positive_confirmation_type } = action.payload
      state.responses[id].positive_confirmation_type =
        positive_confirmation_type
    },
    setSelectedOutcomes(state, action: PayloadAction<Followup[]>) {
      state.selectedOutcomes = action.payload
    },
    setUnsubscribeToken(state, action: PayloadAction<string>) {
      state.unsubscribeToken = action.payload
    },
    deleteResponse(state, action: PayloadAction<string>) {
      delete state.responses[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rememberRehydrated, (state, action) => {
      return {
        ...state,
        ...action?.payload?.outcome,
        rehydrated: true,
      }
    })
    builder.addCase(REMEMBER_PERSISTED, (state) => {
      state.rehydrated = false
      state.persisted = true
    })
  },
})

export const {
  decrementOutcomeIndex,
  deleteResponse,
  incrementOutcomeIndex,
  resetAll,
  resetOutcomeIndex,
  setSelectedOutcomes,
  setConfirmationType,
  setId,
  setNegativeConfirmationType,
  setOutcomeAmount,
  setPositiveConfirmationType,
  setUnsubscribeToken,
} = outcomeSlice.actions

export default outcomeSlice.reducer
