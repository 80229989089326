import styled from 'styled-components'

type StyledChipProps = {
  actionType: string
}
export const StyledChip = styled.div<StyledChipProps>`
  display: flex;
  color: ${(props) =>
    props.actionType === 'HEADER' ? '#ababab' : props.theme.text.default};
  padding: 0;
  align-items: left;
  font-size: 0.8125rem;
  font-weight: 500;
  align-items: center;
  margin: 1rem 0;
`
StyledChip.displayName = 'StyledChip'
