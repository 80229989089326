import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hideCookiesConsent } from 'Reducers/uiSlice'

import {
  ExternalLink,
  StyledButton,
  StyledCloseIcon,
  StyledFixedDiv,
  StyledFlexDiv,
  StyledTextDiv,
} from './style'

const CookiesConsent = () => {
  const { showCookiesConsent } = useSelector((state) => state.ui)
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(hideCookiesConsent())
  }

  if (!showCookiesConsent) return null

  return (
    <StyledFixedDiv role='region' aria-label='cookie consent banner'>
      <StyledFlexDiv>
        <StyledTextDiv>
          By using our site, you agree to our use of cookies. To read more about
          cookies we use, see our{' '}
          <ExternalLink
            href='https://singlestop.org/privacy-policy/'
            target='_blank'
            rel={'noopener noreferrer'}
          >
            Privacy Policy
          </ExternalLink>{' '}
          and our{' '}
          <ExternalLink
            href='https://singlestop.org/terms-and-conditions/'
            target='_blank'
            rel={'noopener noreferrer'}
          >
            Terms and Conditions
          </ExternalLink>
          .
        </StyledTextDiv>
        <StyledButton aria-label='Close Cookie Consent' onClick={handleOnClick}>
          <StyledCloseIcon />
        </StyledButton>
      </StyledFlexDiv>
    </StyledFixedDiv>
  )
}
export default CookiesConsent
