import React from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonsContainer from 'Components/Outcome/OutcomeCashBenefitForm/ButtonsContainer'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'
import {
  selectActiveOutcome,
  selectActiveResponsePositiveConfirmationType,
} from 'Components/Outcome/selectors'
import { StyledLineBreak } from 'Components/Outcome/style'
import useHandleLoop from 'Components/Outcome/useHandleLoop'
import { FORM_ERROR } from 'final-form'
import { setPositiveConfirmationType } from 'Reducers/outcome'
import { CONFIRMATION_MAPPINGS } from 'Shared/constants'
import { useAppDispatch, useAppSelector } from 'Store/hooks'

import StyledRadioButton from '../ui/StyledRadioButton'

const OutcomeConfirmationForm = ({ logo }: { logo: string | null }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useAppDispatch()
  const activeOutcome = useAppSelector(selectActiveOutcome)

  const handleLoop = useHandleLoop()

  const initialValue = useAppSelector(
    selectActiveResponsePositiveConfirmationType
  )

  const backButtonCallback = () => {
    navigate(`../response`, {
      state: {
        from: location.pathname,
        backButtonClicked: true,
      },
    })
  }

  const onSubmit = (values: { positiveConfirmationType: string }) => {
    const { positiveConfirmationType } = values
    dispatch(
      setPositiveConfirmationType({
        id: activeOutcome.id,
        positive_confirmation_type: positiveConfirmationType,
      })
    )
    if (activeOutcome.cashBenefit) {
      navigate(`../cash-benefit`, {
        state: { from: location.pathname },
      })
    } else {
      dispatch(handleLoop())
    }
  }

  return (
    <OutcomePageTemplate logo={logo} radioContainer>
      <div className='form-container'>
        <h2>
          <StyledLineBreak>
            What type of confirmation did you receive
          </StyledLineBreak>
          for {activeOutcome?.caseName}?
        </h2>

        <Form
          initialValues={
            initialValue ? { positiveConfirmationType: initialValue } : {}
          }
          onSubmit={onSubmit}
          validate={({ positiveConfirmationType }) =>
            positiveConfirmationType
              ? {}
              : { [FORM_ERROR]: 'Please choose an option' }
          }
          render={({ handleSubmit, values, error, touched }) => (
            <form onSubmit={handleSubmit}>
              <ul>
                {CONFIRMATION_MAPPINGS.filter((mapping) =>
                  activeOutcome.outcomeTypes.positiveConfirmationTypes.includes(
                    mapping.value
                  )
                ).map((mapping, index) => {
                  const { label, value } = mapping
                  const isChecked = values?.positiveConfirmationType === value
                  const radioId = `${value}-${index}`
                  return (
                    <li key={radioId}>
                      <StyledRadioButton
                        value={value}
                        label={label}
                        name='positiveConfirmationType'
                        id={radioId}
                        $isChecked={isChecked}
                      />
                    </li>
                  )
                })}
              </ul>
              {error && touched?.positiveConfirmationType && (
                <p className='error-message'>{error}</p>
              )}
              <ButtonsContainer backButtonCallback={backButtonCallback} />
            </form>
          )}
        />
      </div>
    </OutcomePageTemplate>
  )
}

export default OutcomeConfirmationForm
