import { useMutation } from '@apollo/client'

import { AGREE_TO_TERMS } from './gql'

const useAcceptTerms = () => {
  const [agreedToTerms] = useMutation(AGREE_TO_TERMS)

  const handleAcceptTerms = (clientId: string) => {
    return agreedToTerms({
      variables: {
        clientId: clientId,
        agreedToTerms: true,
      },
    })
      .then((response) => response)
      .catch((response) => response)
  }

  return {
    handleAcceptTerms,
  }
}

export default useAcceptTerms
