import theme from 'Shared/Theme/ssTheme'
import styled from 'styled-components'

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: ${(props) => (props.height ? props.height : '100%')};
  overflow-x: ${(props) => (props.scrollx ? props.scrollx : '')};
  overflow-y: ${(props) => (props.scrolly ? props.scrolly : '')};
  text-align: ${(props) => props.textalign};
  align-items: ${(props) => props.alignitems};
  justify-content: ${(props) => props.justifycontent};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : theme.palette.color.white};
  @media only screen and (max-width: 480px) {
    padding-bottom: 6rem;
  }
  @media only screen and (min-width: 1025px) {
    padding: ${(props) => (props.padding ? props.padding : '2.5rem')};
    margin: ${(props) => props.margin};
    box-shadow: ${(props) => props.boxshadow};
    border-radius: ${(props) => props.borderradius};
  }
`
