import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Grid } from '@material-ui/core'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'

import { StyledButton, StyledButtonGrid, StyledButtonGridItem } from './style'

import 'Components/Uielements/styles/globalStyle.css'

/**
 * Review Page Horizontally Scrolling Navigation Buttons
 *
 * @param {Object} {sections, onSectionClick}
 */

const ReviewNavigationButtons = ({ sections, onSectionClick }) => {
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY })

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <StyledButtonGrid item>
      <Grid container wrap='nowrap' id='myDIV'>
        {sections?.map(({ title, icon }, i) => {
          return (
            <StyledButtonGridItem key={i} item>
              <StyledButton
                className={i === tabIndex ? 'active' : ''}
                data-testid={`section-button-${i}`}
                startIcon={isMobile ? '' : icon}
                onClick={() => {
                  setTabIndex(i)
                  onSectionClick(i)
                }}
              >
                {title}
              </StyledButton>
            </StyledButtonGridItem>
          )
        })}
      </Grid>
    </StyledButtonGrid>
  )
}

export default ReviewNavigationButtons
