import { useNavigate } from 'react-router-dom'
import { FORM_ERROR } from 'final-form'
import { ERROR_GENERIC_MESSAGE } from 'Shared/constants'
import { isClientPortalPage } from 'Shared/helpers'

/**
 * Provides an onSubmit callback to final-form that then calls a mutation
 * or PATCH (client or screening...) as needed
 *
 * @returns {function}
 */
const useHandleOnSubmit = (
  submitCallback: (arg0: Record<string, string>) => Promise<void>
) => {
  const navigate = useNavigate()

  return (values: Record<string, string>) =>
    new Promise<void | Record<string, string>>((resolve) =>
      submitCallback(values)
        .then(() => {
          resolve()
          navigate(isClientPortalPage() ? '/account/profile' : '/review')
        })
        .catch(() =>
          resolve({
            [FORM_ERROR]: ERROR_GENERIC_MESSAGE,
          })
        )
    )
}

export default useHandleOnSubmit
