import React from 'react'

import { StyledErrorSpan, StyledParentErrorSpan } from './style'

const Error = ({ error }: { error: string }) => {
  return (
    <StyledParentErrorSpan aria-live='polite' aria-label={`Error: ${error}`}>
      {error ? (
        <StyledErrorSpan role='alert'>{error}</StyledErrorSpan>
      ) : (
        <StyledErrorSpan role='alert' aria-hidden='true'>
          {error}
        </StyledErrorSpan>
      )}
    </StyledParentErrorSpan>
  )
}

export default Error
