import React, { useState } from 'react'
import HelpIcon from '@material-ui/icons/Help'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import {
  BackgroundIcon,
  StyledRecentActivityIcon,
} from 'Components/CaseManagement/ClientSummary/RecentActivities/style'
import {
  StyledDocumentationListItem,
  StyledDocumentationListItemHeading,
  StyledDocumentationListItemHeadingWrapper,
} from 'Pages/Results/style'
import { singleStopGreen } from 'Shared/Theme'

import { GreenCheckbox, LightTooltip, StyledDocumentType } from './style'

const NextStepsDocumentationListItem = ({ document }) => {
  const { type, description } = document
  const [isHover, setIsHover] = useState(false)
  return (
    <StyledDocumentationListItem
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >
      <LightTooltip title={description} placement='top'>
        <StyledDocumentationListItemHeadingWrapper>
          <StyledDocumentationListItemHeading>
            <BackgroundIcon backgroundColor={'#FFE9B1'}>
              <StyledRecentActivityIcon
                IconComponent={InsertDriveFileOutlinedIcon}
              />
            </BackgroundIcon>
            <StyledDocumentType>{type}</StyledDocumentType>
            <HelpIcon
              style={{
                fill: singleStopGreen,
                fontSize: '1.2rem',
                marginLeft: '2rem',
                display: isHover ? 'block' : 'none',
              }}
            />
          </StyledDocumentationListItemHeading>
          <GreenCheckbox
            value='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </StyledDocumentationListItemHeadingWrapper>
      </LightTooltip>
    </StyledDocumentationListItem>
  )
}

export default NextStepsDocumentationListItem
