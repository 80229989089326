import React from 'react'

import EditButton from './EditButton'
import SaveButton from './SaveButton'

interface IActionButton {
  editDisabled: boolean
  isEditing: boolean
  isClientPortal: boolean
  handleSubmit: () => void
  handleCancel: () => void
  handleEditOnClick: () => void
  legacy: boolean
}
const ActionButton = ({
  editDisabled,
  isEditing,
  ...buttonProps
}: IActionButton) => {
  const ButtonComponent = isEditing ? SaveButton : EditButton

  return !editDisabled ? <ButtonComponent {...buttonProps} /> : null
}

export default ActionButton
