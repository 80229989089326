import React, { ReactElement } from 'react'
import { Field } from 'react-final-form'
import CheckboxInput from 'Components/Shared/CheckboxInput/CheckboxInput'

import { StyledFormControl } from './style'

interface ICheckFieldInterface {
  children: ReactElement
  form: {
    mutators: {
      checkGroupServices: (
        args: { services: number[] },
        state: MutatorState
      ) => void
    }
  }
}
const CheckField = ({ children, form }: ICheckFieldInterface) => {
  return (
    <StyledFormControl>
      <Field
        name='services'
        onChange={form.mutators.checkGroupServices}
        component={CheckboxInput}
        type='checkbox'
        value={children.props.id}
      />
      {children}
    </StyledFormControl>
  )
}

export default CheckField
