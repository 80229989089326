import React from 'react'
import {
  StyledHouseholdMembersListItem,
  StyledMuiTextFieldWrapper,
} from 'Components/Shared/ReduxForm/style'
import { BaseFieldProps, Field } from 'redux-form'

import SelectMenuMuiRenderField from './SelectMenuMuiRenderField'

interface StyledHouseholdMemberListItemProps {
  name: string
  label: string
  validate?: Pick<BaseFieldProps, 'validate'>
  children: React.ReactNode
  normalize?: Pick<BaseFieldProps, 'normalize'>
}
const StyledHouseholdMemberListItem = ({
  name,
  label,
  validate,
  children,
  normalize,
}: StyledHouseholdMemberListItemProps) => (
  <StyledMuiTextFieldWrapper>
    <StyledHouseholdMembersListItem>
      <Field
        name={name}
        component={SelectMenuMuiRenderField}
        label={label}
        type='text'
        validate={validate}
        normalize={normalize}
      >
        {children}
      </Field>
    </StyledHouseholdMembersListItem>
  </StyledMuiTextFieldWrapper>
)

export default StyledHouseholdMemberListItem
