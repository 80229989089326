import React from 'react'
import Icon from 'Components/Shared/Icon/Icon'
import { StyledBenefitGuideLink } from 'Pages/Results/style'
import downloadReportFeatureIconPath from 'Shared/Icons/downloadReportFeatureIconPath'
import theme from 'Shared/Theme/ssTheme'

export const BenefitGuideLink = ({
  viewGuideURL,
}: {
  viewGuideURL: string
}) => {
  return (
    <StyledBenefitGuideLink href={viewGuideURL} download target='_blank'>
      <Icon
        fill={theme.palette.primary.default}
        viewBox={'0 96 960 960'}
        scale={'1.25rem'}
        margin={'0 .4rem 0 0'}
        padding={'0'}
        screenReaderLabel={'View Benefit Guide'}
        cursorOnHover={'pointer'}
      >
        {downloadReportFeatureIconPath}
      </Icon>
      <span>View Benefit Guide</span>
    </StyledBenefitGuideLink>
  )
}

export default BenefitGuideLink
