import React, { createContext, useContext } from 'react'
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import ModalTemplate from 'Components/CaseManagement/Modals/Shared/ModalTemplate'
import {
  StyledButtonsContainer,
  StyledGridFullRow,
  StyledParagraph,
  StyledSelectLocationForm,
} from 'Components/CaseManagement/Modals/style'
import Field from 'Components/CustomQuestions/CustomQuestionField/Inputs/FieldAdapter/FieldAdapter'
import ListInput from 'Components/CustomQuestions/CustomQuestionField/Inputs/ListInput/ListInput'
import { Button } from 'Components/Shared/Uielements/styles/buttons.style'

/**
 *
 * @type {React.Context<{orgSlug: string, locationOptions: Array, showModal: boolean, locSlug: string}>}
 */
export const PartnerLocationModalContext = createContext({
  showModal: false,
  locationOptions: [],
  orgSlug: '',
  locSlug: '',
})

const resetContext = (contextValue) => {
  contextValue.locationOptions = []
  contextValue.locaSlug = ''
  contextValue.showModal = false
}

const PartnerLocationModal = ({ context }) => {
  const contextValue = useContext(context)
  const navigate = useNavigate()
  // If the user closed the modal without making a choice, redirect to the org slug landing page
  const handleClose = ({ reason }) => {
    resetContext(contextValue)
    if (reason === 'cancel') {
      navigate(`/${contextValue.orgSlug}`)
      window.location.reload()
    }
  }

  const onSubmit = ({ location: locSlug }) => {
    navigate(`/${contextValue.orgSlug}/${locSlug}`)
    handleClose({ reason: 'submit' })
  }

  const optionsArr = contextValue.locationOptions
  const optionsSorted = optionsArr.sort((a, b) =>
    a.label.localeCompare(b.label)
  )

  return (
    <ModalTemplate
      open
      hasXToClose
      handleCancel={() => handleClose({ reason: 'cancel' })}
      heading={'Please select a location'}
      textAlign={'center'}
    >
      <StyledGridFullRow marginTop='1em'>
        <StyledParagraph textAlign='center' marginBottom='.5em'>
          This organization has multiple locations.
        </StyledParagraph>
        <StyledParagraph textAlign='center' marginBottom='.5em'>
          To better help you, please select a location.
        </StyledParagraph>
      </StyledGridFullRow>
      <StyledGridFullRow>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <StyledSelectLocationForm
              onSubmit={handleSubmit}
              id={'select-partner-location'}
            >
              <Field
                name={'location'}
                options={optionsSorted}
                validate={(value) => (!value ? 'Required' : undefined)}
                inputLabelText={'Select a location'}
                render={(props) => {
                  return (
                    <ListInput
                      {...props}
                      id={'partner-location'}
                      dataTestId={'partner-location-select-menu'}
                    />
                  )
                }}
              />
              <StyledButtonsContainer>
                <Button
                  type={'submit'}
                  mdFontSize='0.8125rem'
                  mdMinWidth='10.25rem'
                  mdMinHeight='3rem'
                  fontWeight='500'
                  primary
                  md
                >
                  Continue
                </Button>
              </StyledButtonsContainer>
            </StyledSelectLocationForm>
          )}
        />
      </StyledGridFullRow>
    </ModalTemplate>
  )
}

export default PartnerLocationModal
