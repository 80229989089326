import { GenericDataValueArray } from 'Components/ClientPortal/MyProfile/ClientPortalDemographicContent'

/**
 * Filters and reduces editable fields into final-form
 * initial values
 *
 * @param {{valueDisplays:Array, tableDisplays:Array}} data
 * @returns {{}}
 */
export const getInitialValues = (data: {
  valueDisplays: GenericDataValueArray[]
  tableDisplays: GenericDataValueArray[]
}): Record<string, unknown> =>
  (data?.valueDisplays ?? data?.tableDisplays)
    ?.filter((value) => value.editable)
    ?.reduce(
      (previousValue, currentValue) => {
        previousValue[currentValue.id] = currentValue.value
        return previousValue
      },
      {} as Record<string, unknown>
    )

/**
 * Utility function to see if anything in the form has been touched. The form's
 * touched property is an object unlike the form inputs who get a boolean for
 * touched.
 *
 * @param {{}} touched An object containing the touched flags for the whole form
 */
export const formTouched = (touched: Record<string, string>) =>
  Object.keys(touched).some((key) => touched[key])
