import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const StyledFigure = styled.figure`
  position: relative;
  margin: 0;
  z-index: 1;
  ${media.smaller`
    width: 100%;
    & > svg{
      width: 100% !important;
      height: auto;
    }
  `}
  ${media.tablet`
    display: flex;
    justify-content: center;
  `}
  ${media.desktop`
    display: block;
  `}
`
export const StyledClipFigure = styled.figure`
  position: relative;
  margin: 0;
  z-index: 1;
  clip-path: circle(0 at 50% 50%);
`
export const StyledImage = styled.img`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : '80vh')};
  ${media.smaller`
    width: 100%;
  `}
  ${media.tablet`
    width: ${(props) => (props.width ? props.width : '600px')};
  `}
`

StyledFigure.displayName = 'StyledFigure'
StyledClipFigure.displayName = 'StyledClipFigure'
StyledImage.displayName = 'StyledImage'
