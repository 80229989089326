import styled from 'styled-components'

type StyledMobileActionsContainerProps = {
  padding?: string
}

export const StyledMobileActionsContainer = styled.div<StyledMobileActionsContainerProps>`
  display: flex;
  justify-content: space-between;
  min-height: 5.6rem;
  align-items: center;
  padding: ${(props) => props.padding};

  .mobile-menu-toggle-container {
    display: flex;
    min-width: 5rem;
  }

  .mobile-user-profile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-width: 5rem;
  }
`
