import React from 'react'
import MediaQuery from 'react-responsive'
import LocationMap from 'Components/LocalResources/LocationsMap/LocationsMap'
import {
  desktopAndTabletMediaQuery,
  MOBILE_MEDIA_QUERY,
} from 'Shared/constants'
import { useAppSelector } from 'Store/hooks'

interface LocationsMapProps {
  showSearchThisAreaButton: boolean
  setSearchThisArea: (searchThisArea: boolean) => void
}
const LocationsMap = ({
  showSearchThisAreaButton,
  setSearchThisArea,
}: LocationsMapProps) => {
  const { showMainMenu } = useAppSelector((state) => state.ui)
  const hideMobileContent = showMainMenu

  const mobileRender = () => {
    if (hideMobileContent) {
      return null
    } else {
      return (
        <LocationMap
          showSearchThisAreaButton={showSearchThisAreaButton}
          setSearchThisArea={setSearchThisArea}
        />
      )
    }
  }

  return (
    <>
      <MediaQuery query={MOBILE_MEDIA_QUERY}>{mobileRender()}</MediaQuery>

      <MediaQuery query={desktopAndTabletMediaQuery}>
        <LocationMap
          showSearchThisAreaButton={showSearchThisAreaButton}
          setSearchThisArea={setSearchThisArea}
        />
      </MediaQuery>
    </>
  )
}

export default LocationsMap
