import { PayloadAction } from '@reduxjs/toolkit'
import {
  UPDATE_NEXT_STEPS,
  UPDATE_REVIEW_SECTION_INDEX,
} from 'Actions/eligibility'
import { REMEMBER_PERSISTED, REMEMBER_REHYDRATED } from 'redux-remember'

import { eligibility as initialState } from './initialState'

export interface EligibilityState {
  reviewSectionIndex: number
  rehydrated: boolean
  persisted: boolean
}
export default (
  state: EligibilityState = initialState,
  action: PayloadAction<Record<string, string>>
) => {
  const { type } = action

  switch (type) {
    case REMEMBER_REHYDRATED:
      return {
        ...state,
        ...action.payload.nextSteps,
        rehydrated: true,
      }

    case REMEMBER_PERSISTED:
      return {
        ...state,
        rehydrated: false,
        persisted: true,
      }

    case UPDATE_REVIEW_SECTION_INDEX: {
      const { reviewSectionIndex } = action
      return {
        ...state,
        reviewSectionIndex,
      }
    }

    case UPDATE_NEXT_STEPS: {
      const { nextSteps } = action
      return {
        ...state,
        ...nextSteps,
      }
    }

    default:
      return state
  }
}
