import { createSelector } from '@reduxjs/toolkit'

import { loginSelector } from './login'
import { preScreenerSelector } from './preScreener'
import {
  additionalIncomeType,
  clientPayFrequency,
  doesUser,
  getClientName,
  getClientSchool,
  hasUser,
  householdMemberName,
  householdMemberNameNonPossessive,
  householdMemberNamePossessive,
  householdMemberPayFrequency,
  isUser,
  screenerSelector,
  yourTheir,
} from './screener'

const emailOrPhone = createSelector(
  [
    (state) => preScreenerSelector(state, 'emailOrPhone'),
    (state) => loginSelector(state, 'emailOrPhone'),
  ],
  (fromPreScreener, fromLogin) => fromPreScreener || fromLogin
)

export {
  additionalIncomeType,
  clientPayFrequency,
  doesUser,
  emailOrPhone,
  getClientName,
  getClientSchool,
  hasUser,
  householdMemberName,
  householdMemberNameNonPossessive,
  householdMemberNamePossessive,
  householdMemberPayFrequency,
  isUser,
  loginSelector,
  preScreenerSelector,
  screenerSelector,
  yourTheir,
}
