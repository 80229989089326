import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import PreScreenerBackButton from 'Components/PreScreener/PreScreenerBackButton'
import {
  ReverseCol,
  StyledForm,
  StyledLink,
  StyledMainHeading,
  StyledParagraph,
  StyledParentButtonsActionsInMobile,
  StyledParentPreScreenerButton,
  StyledScreeningPairSvg,
} from 'Components/Screener/style'
import ColDynamicCtaButton from 'Components/Shared/ReduxForm/ColDynamicCtaButton'
import FormSubmissionError from 'Components/Shared/ReduxForm/FormSubmissionError'
import ScreeningPairSvg from 'Pages/PreScreener/Assets/stopwatch.svg'
import { Field, getFormValues, reduxForm } from 'redux-form'
import { PRE_SCREENER_FORM_NAME } from 'Shared/constants'
import { MOBILE_MEDIA_QUERY } from 'Shared/constants'
import { setFocusOnFirstError } from 'Shared/helpers'
import { Col, Container, Row } from 'styled-bootstrap-grid'

import CheckBoxInput from '../Shared/CheckboxInput/CheckboxInput'

import { StyledTermsAndButtonToContinueWrapper } from './style'

const validate = (values) => {
  const errors = {}
  if (!values.agreeToTerms) {
    errors.agreeToTerms = 'You must check the box above to proceed'
  }
  return errors
}

const PreScreenerSectionIntroduction = (props) => {
  const { handleSubmit, outlined, error } = props

  const focusRef = useRef(null)

  useEffect(() => {
    setFocusToMainHeading()
  }, [])

  const setFocusToMainHeading = () => {
    if (focusRef.current) {
      focusRef.current.focus()
    }
  }

  const isMobile = useMediaQuery({
    query: MOBILE_MEDIA_QUERY,
  })

  return (
    <Container>
      <Row alignItems='center' justifyContent='center'>
        <Col md='10'>
          <StyledForm
            padding={'2rem 0 0'}
            textalign={'center'}
            alignitems={'center'}
            justifycontent={'center'}
            onSubmit={handleSubmit}
          >
            <StyledParagraph
              introduction
              textalign='center'
              marginBottom='0.6875rem'
              lineheight={'1.5'}
            >
              To better assist you, we're going to ask you some quick questions.
              This typically takes
            </StyledParagraph>

            <StyledMainHeading
              id='mainContent'
              tabIndex='-1'
              $textCenter={true}
              ref={focusRef}
            >
              10 to 15 minutes to complete.
            </StyledMainHeading>

            <StyledScreeningPairSvg
              margin='auto auto 4rem'
              alt=''
              src={ScreeningPairSvg}
            />

            <StyledParagraph>
              Don't worry, we'll keep it confidential.
            </StyledParagraph>

            <StyledTermsAndButtonToContinueWrapper>
              <StyledParagraph
                textalign='center'
                fontSize='1rem'
                marginBottom='0'
              >
                By proceeding, you agree to the{' '}
                <StyledLink
                  target='_blank'
                  href='https://singlestop.org/privacy-policy/'
                >
                  Privacy Policy
                </StyledLink>{' '}
                and{' '}
                <StyledLink
                  target='_blank'
                  href='https://singlestop.org/terms-and-conditions/'
                >
                  Terms and Conditions.
                </StyledLink>
              </StyledParagraph>
              <Field
                name='agreeToTerms'
                component={CheckBoxInput}
                label='I have read and agree to the Privacy Policy and Terms and Conditions.'
                type='checkbox'
              />
              <FormSubmissionError error={error} />
              {isMobile ? (
                <StyledParentButtonsActionsInMobile>
                  <ReverseCol>
                    <StyledParentPreScreenerButton width='100%'>
                      <PreScreenerBackButton
                        outlined={outlined}
                        width='100%'
                        margin='1rem 0'
                      >
                        Back
                      </PreScreenerBackButton>
                    </StyledParentPreScreenerButton>
                    <StyledParentPreScreenerButton width='100%'>
                      <ColDynamicCtaButton smallerMargin='0' />
                    </StyledParentPreScreenerButton>
                  </ReverseCol>
                </StyledParentButtonsActionsInMobile>
              ) : (
                <>
                  <ColDynamicCtaButton />
                  <PreScreenerBackButton
                    outlined={outlined}
                    width='100%'
                    margin='1rem 0'
                  />
                </>
              )}
            </StyledTermsAndButtonToContinueWrapper>
          </StyledForm>
        </Col>
      </Row>
    </Container>
  )
}

const SectionIntroduction = reduxForm({
  form: PRE_SCREENER_FORM_NAME,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmitFail: setFocusOnFirstError,
})(PreScreenerSectionIntroduction)

const mapStateToProps = (state) => {
  return {
    fieldValues: getFormValues(PRE_SCREENER_FORM_NAME)(state) ?? {},
  }
}

export default connect(mapStateToProps)(SectionIntroduction)
