import React from 'react'
import OutcomePageTemplate from 'Components/Outcome/OutcomePageTemplate/OutomePageTemplate'

const OutcomeUpToDate = ({ logo }: { logo: string | null }) => {
  return (
    <OutcomePageTemplate logo={logo}>
      <section className='outcome-thank-you'>
        <h1>Your Single Stop records are up to date. Thanks!</h1>
      </section>
    </OutcomePageTemplate>
  )
}

export default OutcomeUpToDate
