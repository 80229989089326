import React from 'react'
import { Link } from 'react-router-dom'
import RateDisplayCards from 'Components/CaseManagement/Reporting/Charts/Shared/RateDisplay/RateDisplayCards'
import { TotalDrawdownIcon } from 'Components/CaseManagement/Shared/Icons'
import LoadingContainer from 'Components/Shared/LoadingContainer/LoadingContainer'

import { StyledFlexContainer, StyledHeading } from '../Results/style'

type Category = {
  category: string
  categoryIconPath: string
  programs: {
    eligible: boolean
  }[]
}

const NextStepsFriendlyText = ({
  benefitsCategories,
  tabIndex,
  totalBenefitAmount,
  loading,
  screeningResults,
}: {
  benefitsCategories: Category[]
  tabIndex: number
  totalBenefitAmount: number
  loading: boolean
  screeningResults?: {
    totalEligibleAmount: string
    cashBenefitEligibleAmount: string
    nonCashBenefitEligibleAmount: string
  }
}) => {
  const checkAllProgramEligibleLength = (categories: Category[]): number => {
    let eligibleProgramCount = 0
    categories.forEach((category: Category) => {
      category.programs.forEach((program) => {
        if (program.eligible) {
          eligibleProgramCount++
        }
      })
    })
    return eligibleProgramCount
  }

  const eligibleProgramCount = checkAllProgramEligibleLength(benefitsCategories)

  if (loading) {
    return (
      <LoadingContainer>
        <></>
      </LoadingContainer>
    )
  }
  const totalEligibleAmount = Number(screeningResults?.totalEligibleAmount)
  const cashBenefitEligibleAmount = Number(
    screeningResults?.cashBenefitEligibleAmount
  )
  const nonCashBenefitEligibleAmount = Number(
    screeningResults?.nonCashBenefitEligibleAmount
  )
  return (
    <>
      {eligibleProgramCount > 0 && (
        <StyledFlexContainer>
          <StyledHeading>
            {tabIndex === 0 && (
              <>
                Based on your responses, here are benefits you're likely
                eligible for. You may{' '}
                <Link to='/review'>review your responses</Link> if you'd like.
              </>
            )}

            {tabIndex === 1 && (
              <>
                Based on your responses, you are unlikely to be eligible for the
                programs listed below. You may{' '}
                <Link to='/review'>review your responses</Link> to make sure
                you've entered everything correctly.
              </>
            )}

            {tabIndex === 2 && (
              <>
                There are a variety of benefits available. See a list below. To
                check your screening information,{' '}
                <Link to='/review'>review your responses</Link> now.
              </>
            )}
          </StyledHeading>
          <RateDisplayCards
            className='eligibilityRateDisplayCards'
            value={Math.floor(totalEligibleAmount)}
            isCurrency={true}
            icon={TotalDrawdownIcon}
            cashBenefits={`${Math.floor(cashBenefitEligibleAmount)}`}
            nonCashBenefits={`${Math.floor(nonCashBenefitEligibleAmount)}`}
          />
        </StyledFlexContainer>
      )}
      {eligibleProgramCount === 0 && (
        <StyledFlexContainer>
          <StyledHeading>
            Based on your current responses, we're unable to find benefits that
            you are likely eligible for.
            <br />
            <br />
            Help is out there! You may
            <Link to='/review'> review your responses</Link> or{' '}
            <Link to='/locations/list'>browse other resources</Link> available
            in your area.
          </StyledHeading>
          <RateDisplayCards
            className='eligibilityRateDisplayCards'
            isCurrency={true}
            icon={TotalDrawdownIcon}
            value={Math.floor(totalEligibleAmount)}
            cashBenefits={`${Math.floor(cashBenefitEligibleAmount)}`}
            nonCashBenefits={`${Math.floor(nonCashBenefitEligibleAmount)}`}
          />
        </StyledFlexContainer>
      )}
    </>
  )
}

export default NextStepsFriendlyText
